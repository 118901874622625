import { Card, Steps, Typography } from 'antd';
import styled from 'styled-components';

export const StyledWizardCard = styled(Card)`
  text-align: center;
  border: none;
  position: relative;
  height: calc(100vh - 256px);

  > .ant-card-body {
    padding: 0;
  }
`;

export const StyledWizardSteps = styled(Steps)`
  height: 100px;
  padding: 0 10px;

  .ant-steps-item-title {
    font-size: ${({ theme }) => theme.fontSize}px;
  }
  .ant-steps-item-description {
    font-size: ${({ theme }) => theme.fontSizeSM}px;
  }
  &.ant-steps-label-vertical .ant-steps-item-content {
    margin-top: 6px;
  }
`;

export const StyledWizardHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1600px) {
    height: 90px;
  }
`;

export const StyledWizardHeaderTitle = styled(Typography.Title)`
  margin-bottom: 5px;
`;

export const StyledWizardHeaderSubTitle = styled(Typography.Text)`
  color: ${({ theme }) => theme.colorIcon};

  @media (min-width: 1600px) {
    display: block;
  }
`;

export const StyledWizardBody = styled.div`
  border-bottom: none;
  position: absolute;
  top: 170px;
  bottom: 72px;
  right: 20px;
  left: 20px;

  @media (min-width: 1600px) {
    top: 200px;
  }
`;

export const StyledWizardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  padding: 20px 0;
`;

export const StyledWizardFooterRightArea = styled.div`
  width: 130px;
  text-align: right;
  display: flex;
  justify-content: end;

  button {
    margin-left: 10px;
  }
`;
