import { Form, FormInstance, Input, Select } from 'antd';
import { isArray } from 'lodash';
import React, { useMemo } from 'react';
import { DescriptionValidator } from '../../../../domain/validation/descriptionValidator';
import { DocumentationLinkValidator } from '../../../../domain/validation/documentationLinkValidator';
import { NameValidator } from '../../../../domain/validation/nameValidator';
import { ReleaseNotesValidator } from '../../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../../domain/validation/versionValidator';
import { Sha256Validator } from '../../../../domain/validation/sha256Validator';
import { useSoftwareAppCategories } from '../../hooks/useSoftwareAppsCategories';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../../constants/texts';
import { MarkdownFormItem } from '../../../shared/components/MarkdownFormItem';
import { useSoftwareAppTargets } from '../../hooks/useSoftwareAppTargets';
import { BlobUploadFormItem } from '../../../shared/components/BlobUploadFormItem';
import { ChecksumHowToFormLabel } from '../../../shared/components/ChecksumHowToFormLabel';
import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../../shared/components/Forms/ICSPortalComponentIdFormItem';

import type { SoftwareCreate, Target, TargetWithDownloadLink, ToolCategory } from '../../../../api/engineering/domain/types';
import styled from 'styled-components';
import { useToolCategories } from '../../../../contexts/Tools/hooks/useToolCategories';
import { useWatch } from 'antd/es/form/Form';
import type { RuleObject } from 'antd/es/form';

const { Option } = Select;

const StyledFieldsListContainer = styled.div`
  .ant-col {
    justify-content: center;
  }

  label {
    margin-top: 0;
  }
`;

interface AddCommonSoftwareAppFormProps {
  initial: SoftwareCreate;
  onFinish: (data: SoftwareCreate) => Promise<void>;
  ok: (ok: () => void) => void;
  reset: (cancel: () => void) => void;
  form?: FormInstance;
}

export const AddCommonSoftwareAppForm: React.FC<AddCommonSoftwareAppFormProps> = (props) => {
  const toolSubcategories = useToolCategories();
  const categories = useSoftwareAppCategories();
  const targets = useSoftwareAppTargets();

  const categoriesWithTools = useMemo(
    () =>
      categories.isLoading || !categories.data
        ? []
        : [
            ...categories.data,
            {
              id: 'Tools',
              name: 'Tools',
              description: 'Tools',
              createdAt: null,
              createdBy: null,
              updatedAt: null,
              updatedBy: null
            }
          ],
    [categories.isLoading, categories.data]
  );

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };
  const [form] = Form.useForm<SoftwareCreate>(props.form);
  const formCategories = useWatch('categories', form);
  const isToolsSelected = formCategories?.some((category) => category.id === 'Tools');
  const shouldShowDinamicFields = (formCategories?.length || 0) > 0 && formCategories.every((category) => !!category.id);
  const targetValidator = {
    required: true,
    message: 'Required',
    validator: (_: RuleObject, value: Target[]) => {
      if (value?.length > 0 || form.getFieldValue(['latestVersion', 'targets']).length > 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Argument missing'));
    }
  };
  const categoryValidator = {
    required: true,
    message: 'Required',
    validator: (_: RuleObject, value: number) => {
      if (value > -1 || form.getFieldValue('categories').length > 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Argument missing'));
    }
  };

  props.ok(form.submit);
  props.reset(form.resetFields);

  const updateTargets = (current: number[], data: TargetWithDownloadLink[]) => {
    const ret = current.map((tId) => {
      const associatedTarget = targets.data?.find((tar) => tar.idTarget === tId);
      if (!isArray(data)) {
        data = [data];
      }
      const link = [...data]?.find((t) => t?.target?.idTarget === associatedTarget?.idTarget)?.downloadLink;
      const retTarget = {
        target: associatedTarget,
        downloadLink: link || ''
      } as TargetWithDownloadLink;
      return retTarget;
    });
    return ret;
  };

  const handleTargetChange = (val: number[]) => {
    const fields = { ...form.getFieldsValue() } as SoftwareCreate;
    if (fields.latestVersion) {
      fields.latestVersion.targets = updateTargets(val, form.getFieldValue(['latestVersion', 'targets']));
    }
    form.setFieldsValue(fields);
  };

  const handleFinish = (value: SoftwareCreate) => {
    const copy = { ...value } as SoftwareCreate;
    const isToolCategory = copy.categories?.[0].id === 'Tools';

    if (!!copy?.categories) {
      const id = copy.categories[0].id;
      const cat = categoriesWithTools?.find((category) => category.id === id);
      const cats = cat ? [cat] : [];
      copy.categories = cats;
    }

    if (isToolCategory) {
      copy.category = copy.category?.map(
        (subcategoryId) => toolSubcategories.data?.find((toolSubCategory) => toolSubCategory.id === (subcategoryId as unknown as number))
      ) as ToolCategory[];
      copy.deprecated = false;
    }

    copy.latestVersion = Object.assign(props.initial.latestVersion, copy.latestVersion);
    props.onFinish(Object.assign(props.initial, copy));
  };

  const dynamicFields = (
    <>
      {isToolsSelected ? (
        <>
          <Form.Item label="Sub-category" name="category" rules={[{ required: true, message: 'Please select at least one category!', type: 'array' }]}>
            <Select
              mode="multiple"
              showSearch
              loading={toolSubcategories.isLoading}
              placeholder="Please select"
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(inputValue, option) => {
                return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
              }}
            >
              {toolSubcategories.data?.map((category) => {
                return (
                  <Option key={category.id} value={category.id || -1} title={category.name}>
                    {category.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Version" name={['latestVersion', 'version']} rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <BlobUploadFormItem
            label="Download Link"
            field={['latestVersion', 'downloadLink']}
            rules={[{ required: true, message: 'Required' }]}
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.latestVersion.impacts$updateDeploymentLink !== currentValues.latestVersion.impacts$updateDeploymentLink;
            }}
          />
          <Form.Item
            label={<ChecksumHowToFormLabel />}
            name={['latestVersion', 'sha256']}
            required={false}
            rules={[Sha256Validator.rule(() => form.getFieldValue(['latestVersion', 'downloadLink']))]}
          >
            <Input />
          </Form.Item>

          <ICSPortalComponentIdFormItem name={['latestVersion', ICS_PORTAL_ID_NAME_KEY]} />
          <MarkdownFormItem
            rules={[ReleaseNotesValidator.rule()]}
            label="Release Notes"
            field={['latestVersion', 'releaseNotes']}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
        </>
      ) : (
        <>
          <Form.Item label="Version" name={['latestVersion', 'version']} rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <Form.Item label="Targets" shouldUpdate={(p, n) => p.latestVersion.targets !== n.latestVersion.targets} rules={[targetValidator]}>
            {() => {
              return (
                <Select
                  mode="multiple"
                  value={
                    form.getFieldValue(['latestVersion', 'targets'])?.length > 0
                      ? form.getFieldValue(['latestVersion', 'targets']).map((t: TargetWithDownloadLink) => t.target.idTarget)
                      : []
                  }
                  placeholder="Please select"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(inputValue, option) => {
                    return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
                  }}
                  onChange={handleTargetChange}
                >
                  {targets.data?.map((target) => {
                    return (
                      <Option key={target.idTarget || -1} value={target.idTarget || -1} title={target.name}>
                        {target.name}
                      </Option>
                    );
                  })}
                </Select>
              );
            }}
          </Form.Item>
          <Form.List name={['latestVersion', 'targets']}>
            {(fields) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <StyledFieldsListContainer key={index}>
                      <BlobUploadFormItem
                        label={`Download Link ${form.getFieldValue(['latestVersion', 'targets', index, 'target', 'name'])}`}
                        field={['latestVersion', 'targets', field.key, 'downloadLink']}
                        relativeFieldInList={[field.key, 'downloadLink']}
                        rules={[{ required: true, message: 'Required' }]}
                        key={form.getFieldValue(['latestVersion', 'targets', index, 'target', 'name'])}
                        shouldUpdate={(prevValues, currentValues) => {
                          return JSON.stringify(prevValues.latestVersion.targets) !== JSON.stringify(currentValues.latestVersion.targets);
                        }}
                      />
                      <Form.Item
                        label={<ChecksumHowToFormLabel target={form.getFieldValue(['latestVersion', 'targets', index, 'target', 'name'])} />}
                        name={[field.key, 'sha256']}
                        required={false}
                        rules={[Sha256Validator.rule(() => form.getFieldValue(['latestVersion', 'targets', index, 'downloadLink']))]}
                      >
                        <Input />
                      </Form.Item>

                      <ICSPortalComponentIdFormItem
                        target={form.getFieldValue(['latestVersion', 'targets', index, 'target', 'name'])}
                        name={[field.key, ICS_PORTAL_ID_NAME_KEY]}
                      />
                    </StyledFieldsListContainer>
                  ))}
                </div>
              );
            }}
          </Form.List>
          <MarkdownFormItem
            rules={[ReleaseNotesValidator.rule()]}
            label="Release Notes"
            field={['latestVersion', 'releaseNotes']}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
        </>
      )}
    </>
  );

  return (
    <Form<SoftwareCreate> form={form} {...layout} preserve name="basic" labelAlign="left" onFinish={handleFinish} initialValues={props.initial}>
      <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
        <Input />
      </Form.Item>
      <Form.Item label="Description" name={['description']} rules={[DescriptionValidator.rule()]}>
        <Input />
      </Form.Item>
      <MarkdownFormItem
        optional
        rules={[DocumentationLinkValidator.rule()]}
        label="Documentation"
        field={['documentationLink']}
        shouldUpdate={(prevValues, currentValues) => prevValues.documentationLink !== currentValues.documentationLink}
      />
      <Form.Item label="Category" name={['categories', 0, 'id']} rules={[categoryValidator]}>
        <Select
          loading={categories.isLoading}
          showSearch
          placeholder="Please select"
          getPopupContainer={(trigger) => trigger.parentNode}
          filterOption={(inputValue, option) => {
            return option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase()) ?? false;
          }}
        >
          {categoriesWithTools.map((category) => {
            return (
              <Option key={category.id?.toString()} value={category.id || -1} title={category.name}>
                {category.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      {shouldShowDinamicFields && dynamicFields}
    </Form>
  );
};
