import React, { useEffect, useMemo } from 'react';
import { ProjectCompareSelect } from './ProjectSelect';
import useConfigurationVersions from '../../ProjectSoftware/Configurations/hooks/useConfigurationVersions';
import { SemanticVersion } from '../../../domain';

type ProjectBundleConfigurationVersionSelectProps = {
  projectId: string;
  bundleId: string;
  configurationId: string;
  shouldRemainUnselected?: boolean;
  defaultConfigReleaseId?: string | undefined;
  configReleaseId: number | undefined;
  onChange: (releaseId: number) => void;
};

export const ProjectBundleConfigurationVersionSelect: React.FC<ProjectBundleConfigurationVersionSelectProps> = ({
  projectId,
  bundleId,
  configurationId,
  configReleaseId,
  shouldRemainUnselected,
  onChange
}) => {
  const versions = useConfigurationVersions(projectId, bundleId, configurationId);
  const selectItems = useMemo(() => {
    if (!versions.data) return [];

    return versions.data
      .sort((a, b) => SemanticVersion.fromString(a.version).compareTo(SemanticVersion.fromString(b.version)))
      .map((v) => ({ value: v.idBundleRelease, label: v.version }));
  }, [versions.data]);
  const isVersionExisting = selectItems.some((item) => Number(item.value) === Number(configReleaseId));

  // As soon as the project changes we have to preselect a version,
  // but only for the left side, therefore we have this check.
  useEffect(() => {
    if (!!configReleaseId || shouldRemainUnselected) {
      return;
    }

    onChange(Number(versions.data?.[0]?.idBundleRelease));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions.data]);

  return (
    <ProjectCompareSelect
      showSearch
      // We're passing empty string in order to not show the id in case option does not exist
      value={isVersionExisting ? configReleaseId : ('' as unknown as number)}
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      options={selectItems}
      loading={versions.isFetching}
      onChange={onChange}
    />
  );
};
