import { useState } from 'react';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { usePermissions } from '../../../session/hooks/usePermissions';
import { SemanticVersion } from '../../../../domain/versioning/semanticVersion';
import type { SoftwareAppCreate, SoftwareCreate, ToolCreate } from '../../../../api/engineering/domain/types';
import { ShiftedDrawer } from '../../../shared/components/ShiftedDrawer';
import { cloneDeep } from 'lodash';
import { useCreateCommonSoftwareApp } from './hooks/useCreateCommonSoftwareApp';
import { AddCommonSoftwareAppForm } from './AddCommonSoftwareAppForm';
import { useCreateTool } from '../../../../contexts/Tools/components/AddTool/hooks/useCreateTool';

const initialSwApp: SoftwareAppCreate = {
  name: '',
  categories: [],
  description: '',
  installationOptions: '',
  latestVersion: {
    version: SemanticVersion.initial().toString(),
    targets: [],
    releaseNotes: ''
  },
  documentationLink: ''
};

export const AddCommonSoftwareApp = () => {
  const permissions = usePermissions();
  const createSoftwareApp = useCreateCommonSoftwareApp();
  const createEngineeringTool = useCreateTool();

  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  let okHandler: () => any;
  let resetHandler: () => any;

  const handleFinish: (software: SoftwareCreate) => Promise<void> = async (software) => {
    return new Promise((resolve, reject) => {
      const isTool = software.categories?.[0].id === 'Tools';

      if (isTool) {
        createEngineeringTool
          .mutateAsync([software as ToolCreate])
          .then(() => {
            setModalVisible(false);
            resolve();
          })
          .catch(() => {
            reject();
          });
      } else {
        createSoftwareApp
          .mutateAsync([software as SoftwareAppCreate])
          .then(() => {
            setModalVisible(false);
            resolve();
          })
          .catch(() => {
            reject();
          });
      }
    });
  };

  return (
    <>
      {permissions.engineeringSvc$addCommonSoftwareApp && (
        <Button
          icon={<PlusOutlined />}
          loading={createSoftwareApp.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          New software
        </Button>
      )}

      <ShiftedDrawer
        title="New software"
        okText="Create"
        open={modalVisible}
        loading={createSoftwareApp.isLoading}
        destroyOnClose
        isFormDrawer
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setModalVisible(false);
        }}
        afterOpenChange={(open) => {
          if (!open) form.resetFields();
        }}
      >
        <AddCommonSoftwareAppForm
          initial={cloneDeep(initialSwApp)}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={handleFinish}
        />
      </ShiftedDrawer>
    </>
  );
};
