import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export function SelectionDiffersTag() {
  return (
    <Tag icon={<ExclamationCircleOutlined />} color="error">
      Version missing
    </Tag>
  );
}

export default SelectionDiffersTag;
