import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

/**
 * Mutation that executes an array of promise providers sequentially and tracks the progress.
 *
 * @template T - The type of the resolved value of the promises.
 * @param {(() => Promise<T>)[]} promiseProviders - An array of functions that return promises.
 * @returns {{ mutation: MutationFunction<{ result: T | null; error: Error | null }[]>, progress: number }} 
 * An object containing the mutation function and the progress of the execution.
 *
 * @example
 * const { mutation, progress } = usePromiseMutation([
 *   () => fetch('/api/data1').then(res => res.json()),
 *   () => fetch('/api/data2').then(res => res.json())
 * ]);
 *
 * useEffect(() => {
 *   mutation.mutate();
 * }, []);
 */
export const usePromiseMutation = <T>(promiseProviders: (() => Promise<T>)[]) => {
  const [progress, setProgress] = useState(0);

  const mutation = useMutation(async () => {
    const results = Array<{ result: T | null; error: Error | null }>(promiseProviders.length);
    setProgress(0);
    for (const key in promiseProviders) {
      try {
        results[key] = { result: await promiseProviders[key](), error: null };
      } catch (error) {
        results[key] = { result: null, error: error as Error };
      } finally {
        setProgress((+key + 1) / promiseProviders.length);
      }
    }
    return results;
  });

  return { mutation, progress };
};
