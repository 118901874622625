import { useQuery } from '@tanstack/react-query';
import { Bundle } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useBundles(projectId: string) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId });

  const parsedProjectId = parseInt(projectId, 10);

  const permissionsList = permissions.engineeringSvc$getProjectBundles;
  const specificPermissions = !permissionsList ? permissions.engineeringSvc$getProjectBundle$specific() : [];

  const enabled = parsedProjectId > 0 && (permissionsList || specificPermissions.length > 0);

  return useQuery<Bundle[], [string, string]>(
    ['bundles', projectId],
    (): Promise<Bundle[]> => {
      if (permissionsList) return backend.queryBundles(projectId);
      return Promise.all(specificPermissions.map((s) => backend.queryBundle(s.projectId, s.idBundle)));
    },
    {
      enabled
    }
  );
}

export default useBundles;
