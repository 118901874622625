import { EditOutlined, FileMarkdownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tabs, Form, Tooltip, Typography, Skeleton } from 'antd';
import { Rule } from 'antd/lib/form';

import { MarkdownRenderer } from './MarkdownRenderer';
import { DescriptionTextAreaInput } from './DescriptionTextAreaInput';
import { MARKDOWN_ENABLED_TEXT } from '../../../constants/texts';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { Redirects } from '@pacts/utils-redirects';
import styled from 'styled-components';
import { ReactNode } from 'react';

interface MarkdownFormItemProps {
  label?: string | ReactNode;
  field: string[];
  labelAlign?: 'left' | 'right';
  rules?: Rule[];
  optional?: boolean;
  descriptionMinRows?: number;
  descriptionMaxRows?: number;
  placeholder?: string;
  style?: any;
  shouldUpdate: (prevValuse: any, currentValues: any) => boolean;
  isLoading?: boolean;
  isLoadingText?: string;
  readonly?: boolean;
  noInfo?: boolean;
  className?: string; // Optional because styled-components injects it
  fullHeight?: boolean;
}

const StyledLoadingText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colorTextDisabled};
`;

const StyledSkeleton = styled(Skeleton)`
  margin-top: 10px;
`;

export const MarkdownFormItem = (props: MarkdownFormItemProps) => {
  const {
    label,
    field,
    labelAlign,
    rules,
    optional,
    descriptionMinRows = 6,
    descriptionMaxRows = 15,
    shouldUpdate,
    placeholder,
    style,
    isLoading,
    isLoadingText,
    readonly,
    noInfo,
    className,
    fullHeight
  } = props;

  const permissions = usePermissions();

  const HowTo = () => (
    <span>
      {label}{' '}
      {permissions.webui$showHelp && !noInfo ? (
        <Tooltip title="How To: Release Notes with Markdown">
          <Typography.Link href={Redirects.WIKI_PACTS_RELEASE_NOTES} target="_blank">
            {<QuestionCircleOutlined />}
          </Typography.Link>
        </Tooltip>
      ) : null}
    </span>
  );

  return (
    <Form.Item
      label={<HowTo />}
      labelAlign={labelAlign}
      required={!optional}
      shouldUpdate={shouldUpdate}
      style={!className ? { marginTop: 16, ...(style ?? {}) } : {}}
      className={className}
    >
      {({ getFieldValue }) => {
        return isLoading ? (
          <>
            <StyledSkeleton paragraph={{ rows: 7 }} active />
            <StyledLoadingText>{isLoadingText}</StyledLoadingText>
          </>
        ) : (
          <Tabs defaultActiveKey="e" style={{ marginTop: -6 }}>
            <Tabs.TabPane
              tab={
                <span>
                  <EditOutlined /> Edit
                </span>
              }
              key="e"
            >
              <Form.Item noStyle name={field} rules={rules}>
                <DescriptionTextAreaInput
                  disabled={readonly}
                  autoSize={fullHeight ? false : { minRows: descriptionMinRows, maxRows: descriptionMaxRows }}
                  placeholder={`${placeholder ? `${placeholder}\n` : ''}${MARKDOWN_ENABLED_TEXT}`}
                  styles={{ background: 'transparent', height: fullHeight ? 'calc(100vh - 400px)' : 'auto', resize: 'none' }}
                />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  <FileMarkdownOutlined /> Preview
                </span>
              }
              key="p"
            >
              <MarkdownRenderer>{getFieldValue(field)}</MarkdownRenderer>
            </Tabs.TabPane>
          </Tabs>
        );
      }}
    </Form.Item>
  );
};
