import { useState, useEffect, useMemo } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { MarkdownFormItem } from '../../../../../shared/components/MarkdownFormItem';
import { DescriptionValidator } from '../../../../../../domain/validation/descriptionValidator';
import { NameValidator } from '../../../../../../domain/validation/nameValidator';
import { DocumentationLinkValidator } from '../../../../../../domain/validation/documentationLinkValidator';
import { Tool, ToolUpdate } from '../../../../../../api/engineering/domain/types';
import { usePermissions } from '../../../../../session/hooks/usePermissions';
import { ShiftedDrawer } from '../../../../../shared/components/ShiftedDrawer';
import { useUpdateTool } from './hooks/useUpdateTool';
import { useToolCategories } from '../../../../../Tools/hooks/useToolCategories';
import { useToolVersions } from '../../SoftwareItemVersions/components/SoftwareItemVersionsList/hooks/useToolVersions';

const { Option } = Select;

export interface EditToolProps {
  tool: ToolUpdate;
}

export const EditTool = (props: EditToolProps) => {
  const permissions = usePermissions({ toolId: props?.tool?.id?.toString() });
  const [modalVisible, setModalVisible] = useState(false);
  const categories = useToolCategories();
  const updateEngineeringTool = useUpdateTool();
  const versions = useToolVersions(props.tool.id?.toString() || '');

  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  const setCategories = useMemo(() => {
    return () => {
      form.setFieldsValue({
        category: props.tool.category?.map((c) => c.id)
      });
    };
  }, [form, props.tool]);

  useEffect(() => {
    if (updateEngineeringTool.isSuccess) {
      setModalVisible(false);
      form.resetFields();
      setCategories();
    }
  }, [form, setCategories, setModalVisible, updateEngineeringTool.isSuccess]);

  useEffect(() => {
    setCategories();
  }, [props.tool, setCategories]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  if (!permissions.engineeringSvc$updateTool) return null;

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
    setCategories();
  };

  return (
    <>
      <Button
        icon={<EditOutlined />}
        loading={updateEngineeringTool.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        Edit
      </Button>

      <ShiftedDrawer
        title="Edit software"
        destroyOnClose
        loading={updateEngineeringTool.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={onSubmit}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          name="basic"
          labelAlign="left"
          initialValues={{ ...props.tool }}
          form={form}
          onFinish={(value) => {
            const copy = Object.assign(props.tool, { ...value }) as Tool;
            const cats = categories.data?.filter((c) => (copy.category as any as number[]).find((cat) => cat === c.id));
            copy.category = cats || [];
            if (versions && versions.data) {
              const id = copy.latestVersion!.idToolVersion;
              const vf = versions.data.find((v) => v.idToolVersion === id);
              copy.latestVersion = vf!;
            }
            updateEngineeringTool.mutate([copy]);
          }}
        >
          <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            label="Description"
            field={['description']}
            rules={[DescriptionValidator.rule()]}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          <MarkdownFormItem
            optional
            rules={[DocumentationLinkValidator.rule()]}
            label="Documentation"
            field={['documentationLink']}
            shouldUpdate={(prevValues, currentValues) => prevValues.documentationLink !== currentValues.documentationLink}
          />
          <Form.Item label="Latest Version" name={['latestVersion', 'idToolVersion']} rules={[{ required: true, message: 'Required' }]}>
            <Select
              loading={versions.isLoading}
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              filterOption={(inputValue, option) => {
                return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
              }}
            >
              {versions.data?.map((version) => {
                return (
                  <Select.Option key={version.idToolVersion || -1} value={version.idToolVersion || -1} title={version.version}>
                    {version.version}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Category"
            rules={[
              {
                required: true,
                message: 'Required',
                validator: (rule, value) => {
                  if (value > -1 || form.getFieldValue('categories').length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Argument missing'));
                }
              }
            ]}
          >
            <Select
              value={'Tools'}
              loading={categories.isLoading}
              showSearch
              placeholder="Please select"
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled
              filterOption={(inputValue, option) => {
                return option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase()) ?? false;
              }}
            >
              <Option key={'Tools'} value={'Tools'} title={'Tools'}>
                Tools
              </Option>
            </Select>
          </Form.Item>
          <Form.Item label="Sub-categories" name="category" rules={[{ required: true, message: 'Please select at least one category!', type: 'array' }]}>
            <Select
              mode="multiple"
              loading={categories.isLoading}
              placeholder="Please select"
              filterOption={(inputValue, option) => {
                return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
              }}
            >
              {categories.data?.map((category) => {
                return (
                  <Option key={category.id} value={category.id || -1} title={category.name}>
                    {category.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </ShiftedDrawer>
    </>
  );
};
