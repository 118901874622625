import { Navigate, useLocation } from 'react-router-dom';

/**
 * A wrapper component for the `Navigate` component that includes the current location in the navigation state.
 *
 * @component
 * @param {React.ComponentProps<typeof Navigate>} props - The props for the `Navigate` component.
 * @returns {JSX.Element} The `Navigate` component with the current location included in the state.
 *
 * @example
 * ```tsx
 * <NavigateWithState to="/some-path" state={{ someKey: someValue }} />
 * ```
 */
export const NavigateWithState: React.FunctionComponent<React.ComponentProps<typeof Navigate>> = (props) => {
  const location = useLocation();
  return <Navigate {...props} state={{ from: location, ...props.state }} />;
};
