import { Flex, Typography, Row, Col } from 'antd';
import { useInAppNavigate } from '../../../contexts/navigation/hooks';
import { Redirects } from '@pacts/utils-redirects';
import { EmptyIcon } from '../../shared/components/EmptyIcon';

interface NoEnvironmentsMessageProps {
  projectId: number;
  hideCreateHelp?: boolean;
  referrer?: string;
}

export const NoEnvironmentsMessage = ({ projectId, hideCreateHelp, referrer }: NoEnvironmentsMessageProps) => {
  const navigate = useInAppNavigate();

  return (
    <>
      <br />
      <Flex justify="center">
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          {!hideCreateHelp ? (
            <Typography.Paragraph>
              You can create environments for this project on{' '}
              <Typography.Link
                onClick={() => {
                  navigate(`/projects/${projectId}/deployments/environments`);
                }}
              >
                Environments
              </Typography.Link>
              .
            </Typography.Paragraph>
          ) : null}
        </Typography.Paragraph>
      </Flex>
      <br />
      <Row justify="center">
        <Typography.Text>There are no environments in this project.</Typography.Text>
      </Row>
      <EmptyIcon />
      {referrer === 'deployments' && (
        <>
          <Row justify="center">
            <Col>
              <Typography.Text>
                <Typography.Text strong>Before creating deployments</Typography.Text> you need to:
              </Typography.Text>
            </Col>
          </Row>
          <br />
          <Row justify="center">
            <Col span={8} offset={3}>
              <Typography.Text>1. Create an Environment</Typography.Text>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={8} offset={3}>
              <Typography.Text>
                2. Inside the Environment, create <Typography.Text strong>ORCs.</Typography.Text>
              </Typography.Text>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={8} offset={3}>
              <Typography.Text>
                3. Inside the Environment, create <Typography.Text strong>Devices.</Typography.Text>
              </Typography.Text>
            </Col>
          </Row>
          <br />
          <Row justify="center">
            <Typography.Text>OTCs and Devices can be copied from other environments and projects through the export and import process.</Typography.Text>
          </Row>
          <br />
          <Typography.Paragraph style={{ textAlign: 'center' }}>
            If you want to learn how to create an{' '}
            <Typography.Link target="blank" href={Redirects.WIKI_PACTS_ENVIRONMENTS}>
              environment
            </Typography.Link>{' '}
            or{' '}
            <Typography.Link target="blank" href={Redirects.WIKI_PACTS_DEPLOYMENT_PLAN}>
              deployment
            </Typography.Link>
            , please check our wiki pages.
          </Typography.Paragraph>
        </>
      )}
      {referrer !== 'deployments' && (
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          If you want to learn how to create an environment, please check our wiki page{' '}
          <Typography.Link target="blank" href={Redirects.WIKI_PACTS_ENVIRONMENTS}>
            here
          </Typography.Link>{' '}
          .
        </Typography.Paragraph>
      )}
    </>
  );
};
