import { Typography } from 'antd';
import { useState } from 'react';
import { SoftwareItemSelection } from '../../../../domain';
import { usePermissions } from '../../../session/hooks/usePermissions';
import { AddCommonSoftwareApp } from '../AddSoftwareApp/AddCommonSoftwareApp';
import { CommonSoftwareAppsList } from './CommonSoftwareAppsList';
import { PageHeader } from '@ant-design/pro-layout';
import styled from 'styled-components';
import { FlexCardLayout } from '../../../layout/components/FlexCardLayout';
import { ElementIds } from '../../../../constants/elementIds';

const StyledPageHeader = styled(PageHeader)`
  && {
    background-color: ${({ theme }) => theme.colorBgLayout};
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 16px;
  }

  .ant-page-header-heading {
    align-items: end;
  }
`;

const Subtitle = styled(Typography.Text)`
  font-weight: 500;
`;

export const CommonAppsView = () => {
  const [selected, setSelected] = useState([] as SoftwareItemSelection[]);
  const [initialSelection] = useState([] as SoftwareItemSelection[]);
  const permissions = usePermissions();

  return (
    <FlexCardLayout
      header={
        <div id={ElementIds.PAGE_CONTENT_HEADER}>
          <StyledPageHeader
            title={
              <>
                <Typography.Title level={4}>Software Hub</Typography.Title>
                <Subtitle>Centralized platform for accessing project-independent software such as tools, firmware, libraries, and templates.</Subtitle>
              </>
            }
            extra={[permissions.engineeringSvc$addCommonSoftwareApp && <AddCommonSoftwareApp key="add" />]}
          />
        </div>
      }
    >
      <CommonSoftwareAppsList selected={selected} onSelect={setSelected} initiallySelected={initialSelection} />
    </FlexCardLayout>
  );
};
