import { useInfiniteQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ArtifactResponse, usePactsFormationBackend } from '../../../api';
import { useEffect } from 'react';
import type { Dayjs } from 'dayjs';

export const useArtifacts = (startTimestamp: Dayjs, endTimestamp: Dayjs) => {
  const permissions = usePermissions();
  const { backend } = usePactsFormationBackend();
  const startTimestampMilliseconds = startTimestamp.valueOf();
  const endTimestampMilliseconds = endTimestamp.valueOf();

  return useInfiniteQuery<ArtifactResponse>(
    ['artifacts', startTimestampMilliseconds, endTimestampMilliseconds],
    ({ pageParam = null }) => backend.getAllSortedArtifactVersions(pageParam, startTimestampMilliseconds, endTimestampMilliseconds),
    {
      enabled: permissions.pactsFormationSvc$unrestrictedAdministration,
      getNextPageParam: (p) => {
        return p.nextPaginationToken;
      }
    }
  );
};

export const useAllArtifacts = (startTimestamp: Dayjs, endTimestamp: Dayjs) => {
  const artifacts = useArtifacts(startTimestamp, endTimestamp);
  useEffect(() => {
    if (artifacts.hasNextPage && !artifacts.isFetchingNextPage) {
      artifacts.fetchNextPage();
    }
  }, [artifacts]);

  const sourceData = artifacts.data?.pages.map((p) => p.versions).flat();
  return {
    ...artifacts,
    data: sourceData
  };
};
