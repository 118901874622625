import { Select } from 'antd';
import styled from 'styled-components';

export const ProjectCompareSelect = styled(Select)`
  width: 150px;

  && .ant-select-selector {
    background: ${({ theme }) => theme.colorBgBase};
  }
` satisfies typeof Select;
