import { Descriptions, Space, Typography } from 'antd';
import { usePermissions } from '../../../../session/hooks/usePermissions';
import { formatDateTime } from '../../../../shared/components/formatDate';
import { useDeleteTool } from './hooks/useDeleteTool';
import { EditTool } from './EditSoftwareItem/EditTool';
import { MarkdownRenderer } from '../../../../shared/components/MarkdownRenderer';
import { ConfirmationButton } from '../../../../shared/components/ConfirmationButton';
import { ShiftedDrawer } from '../../../../shared/components/ShiftedDrawer';

import type { Tool } from '../../../../../api/engineering/domain/types';

interface ToolDetailsProps {
  tool: Tool | null;
  onClose: () => void;
  open: boolean;
}

export const ToolDetails = (props: ToolDetailsProps) => {
  const { tool, onClose, open } = props;
  const permissions = usePermissions();
  const deleteEngineeringTool = useDeleteTool();

  return (
    <ShiftedDrawer
      onClose={onClose}
      open={open && !!tool}
      title={`Details: ${tool?.name}`}
      extra={
        <>
          {!!tool && (
            <Space>
              <EditTool key="update" tool={tool} />

              {permissions.engineeringSvc$deleteTool && (
                <ConfirmationButton
                  title="Deleting software"
                  description="This action cannot be undone."
                  paragraphDescription={
                    <Typography.Paragraph>
                      Are you sure you want to delete <Typography.Text strong>{tool.name}</Typography.Text> software?
                    </Typography.Paragraph>
                  }
                  danger
                  buttonType="regular"
                  regularButtonType="default"
                  key="delete"
                  onOk={() => {
                    deleteEngineeringTool.mutateAsync([tool]).then(() => {
                      onClose();
                    });
                  }}
                >
                  Delete
                </ConfirmationButton>
              )}
            </Space>
          )}
        </>
      }
    >
      {!!tool && (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Type">Tool</Descriptions.Item>

          <Descriptions.Item label="Description">
            <MarkdownRenderer centered>{tool.description}</MarkdownRenderer>
          </Descriptions.Item>

          <Descriptions.Item label="Documentation">
            <MarkdownRenderer centered>{tool.documentationLink}</MarkdownRenderer>
          </Descriptions.Item>

          <Descriptions.Item label={tool.category?.length === 1 ? 'Category' : 'Categories'}>
            {tool.category?.map((cat) => cat.name).join('; ')}
          </Descriptions.Item>

          <Descriptions.Item label="Created">
            {tool.createdAt ? formatDateTime(new Date(tool.createdAt)) : '-'}
            {tool.createdBy ? ` by ${tool.createdBy}` : ''}
          </Descriptions.Item>

          <Descriptions.Item label="Updated">
            {tool.updatedAt ? formatDateTime(new Date(tool.updatedAt)) : '-'}
            {tool.updatedBy ? ` by ${tool.updatedBy}` : ''}
          </Descriptions.Item>

          {permissions.all$unrestrictedAdministration && <Descriptions.Item label="Id (Admin)">{tool.id}</Descriptions.Item>}
        </Descriptions>
      )}
    </ShiftedDrawer>
  );
};
