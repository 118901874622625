import { CheckCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export const SimilarVersionsTag: React.FunctionComponent = () => {
  return (
    <Tag icon={<CheckCircleOutlined />} color="success">
      Similar version
    </Tag>
  );
};

export default SimilarVersionsTag;
