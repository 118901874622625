import { BundleConfiguration } from '../../../api';

export const initialProjectConfiguration: BundleConfiguration = {
  idBundleConfiguration: 0,
  name: '',
  description: '',
  latestBundleReleaseId: -1
};

export const TABLE_PAGINATION_WRAPPER_HEIGHT = 125;
