import { AxiosError } from 'axios';
import { ApiError } from './apiError';
import { invokeUiNotification } from '../../contexts/shared/hooks/userNotificationProvider';

// TODO: Error 403 -> logout

const isElectron = typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0;

// TODO: check which error handler is actually used
const skipMessage = (err: AxiosError): boolean => {
  const responseCode = err.response?.status || -1;
  // TODO: refactor error messages
  if (isElectron) {
    return true;
  }
  // skip ui message of 404 errors until we are sure all race conditions are solved
  if (responseCode === 404) {
    return true;
  }
  return false;
};

const extractMessage = (error: AxiosError): string => {
  try {
    const resData = error.response?.data as { message: string; details: { field: string; info: string; value: string }[] | undefined } | undefined | null;
    const resMessage = resData?.message ?? error.message;
    const resDetails: { path: string; message: string; value: string }[] =
      resData?.details?.map((d) => {
        return { message: d.info, path: d.field, value: d.value };
      }) || [];
    const err = new ApiError(resMessage, error.response?.status || 999, resDetails);
    return err.readableMessage;
  } catch (parseError) {
    return error.message;
  }
};

const errorMessageMapping = (err: AxiosError): string => {
  let message = extractMessage(err);
  if (message === '') {
    message = 'Unexpected Error';
  }
  return message;
};

export const presentAxiosError = (err: AxiosError) => {
  console.warn('API Request Error', err, JSON.stringify(err));
  if (skipMessage(err)) return;
  invokeUiNotification({
    type: 'error',
    message: 'Something went wrong',
    key: `${err.name}: ${err.message}`,
    description: errorMessageMapping(err)
  });
};

const suppressError = (err: Error): boolean => {
  const message = err.message.toLowerCase();

  // Ignore token refresh exception
  if (message.includes('token refresh failed')) return true;

  // Ignore session expired
  if (message.includes('session expired')) return true;

  // Ignore timeout exceptions, do not report back to the user
  if (/^timeout of \d+ms exceeded/.test(message)) return true;
  return false;
};

export const presentApiError = (err: ApiError) => {
  console.warn('API Request Error', err, JSON.stringify(err));

  if (suppressError(err)) return;

  const mappedDetails = err.reasons.map((r) => `${r.path}: ${r.message} (${r.value})`).join(', ');
  const message = mappedDetails || err.readableMessage || err.name;

  if (isElectron) {
    return;
  }

  invokeUiNotification({
    type: 'error',
    message: 'Something went wrong',
    key: `${err.name}: ${message}`,
    description: message
  });
};

export const presentAppError = (err: Error) => {
  console.warn('Request Error', err);
  invokeUiNotification({
    type: 'error',
    message: 'Something went wrong',
    key: `${err.name}: ${err.message}`,
    description: `${err.name}: ${err.message}`
  });
};
