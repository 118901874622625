import { uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { Skeleton, Space, TableColumnsType, Typography } from 'antd';
import { Comparator } from '../../../domain';
import Table from '../../../contexts/shared/components/Table/Table';
import { ResourceError } from '../../../api';
import { useAllMemberships } from '../hooks/useMemberships';
import MembershipDetails from './MembershipDetails';
import styled from 'styled-components';
import { valueFromObjectPath } from '../../../contexts/reports';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';
import { useTableFilter } from '../../../contexts/shared/components/TableFilter';
import { StyledModal } from '../../../contexts/shared/components/StyledModal';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';
import type { Dayjs } from 'dayjs';

export interface DataType {
  key: React.Key;
  status: string;
  creationTimestamp: Date;
  lastUpdatedTimestamp: Date;
}

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

interface AllMembershipResourcesProps {
  pickedRange: [Dayjs, Dayjs];
}

export const AllMembershipResources: React.FC<AllMembershipResourcesProps> = ({ pickedRange }) => {
  const [startTimestamp, endTimestamp] = pickedRange;
  const membershipResources = useAllMemberships(startTimestamp, endTimestamp);
  const isLoading = useDebounce(membershipResources.isLoading || membershipResources.isFetchingNextPage, 10, true);
  const creationDateSorter = (artifactA: DataType, artifactB: DataType) => artifactA?.creationTimestamp.getTime() - artifactB?.creationTimestamp.getTime();
  const updationDateSorter = (artifactA: DataType, artifactB: DataType) =>
    artifactA?.lastUpdatedTimestamp.getTime() - artifactB?.lastUpdatedTimestamp.getTime();
  const statuses: string[] = uniq(membershipResources.data?.map((membership) => membership?.status) || []);
  const [errorModal, setErrorModal] = useState<null | ResourceError>(null);
  const columns: TableColumnsType<DataType> = [
    {
      title: 'PF ID',
      dataIndex: 'id',
      key: 'id',
      ...useTableSearch({
        searchParamId: 'id',
        searchValueProvider: valueFromObjectPath(['id'])
      })
    },
    {
      title: 'User GID',
      dataIndex: 'userGid',
      key: 'userGid',
      ...useTableSearch({
        searchParamId: 'userGid',
        searchValueProvider: valueFromObjectPath(['userGid'])
      })
    },
    {
      title: 'User Email',
      dataIndex: 'userEmail',
      key: 'userEmail',
      ...useTableSearch({
        searchParamId: 'userEmail',
        searchValueProvider: valueFromObjectPath(['userEmail'])
      })
    },
    {
      title: 'Project ID',
      dataIndex: 'projectId',
      key: 'projectId',
      ...useTableSearch({
        searchParamId: 'projectId',
        searchValueProvider: valueFromObjectPath(['projectId'])
      })
    },
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
      ...useTableSearch({
        searchParamId: 'projectName',
        searchValueProvider: valueFromObjectPath(['projectName'])
      })
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...useTableFilter({
        searchParamId: 'status',
        values: statuses.map((status: string) => ({ text: status, value: status })),
        onFilter(data, filterVal) {
          const value = valueFromObjectPath<string>(['status'])(data);
          return value.includes(filterVal);
        },
        key: statuses.join('+')
      })
    },
    {
      title: 'Created',
      dataIndex: 'creationTimestamp',
      key: 'creationTimestamp',
      sorter: {
        compare: creationDateSorter
      },
      render: (_, membership) => formatDateTime(membership.creationTimestamp)
    },
    {
      title: 'Updated',
      dataIndex: 'lastUpdatedTimestamp',
      key: 'lastUpdatedTimestamp',
      sorter: {
        compare: updationDateSorter
      },
      render: (_, membership) => formatDateTime(membership.lastUpdatedTimestamp),
      defaultSortOrder: 'descend'
    }
  ];
  const data: DataType[] = useMemo(
    () =>
      membershipResources.data
        ?.sort((a, b) => Comparator.lexicographicalComparison(a.id, b.id))
        .map((membership) => {
          return {
            key: membership.id,
            id: membership.id,
            userGid: membership.userGid,
            status: membership.status,
            projectName: membership.projectName,
            userEmail: membership.userEmail,
            userId: membership.userId,
            projectId: membership.projectId,
            creationTimestamp: new Date(membership.creationTimestamp),
            lastUpdatedTimestamp: new Date(membership.lastUpdatedTimestamp)
          };
        }) || [],
    [membershipResources.data]
  );

  return (
    <FullWidthSpace direction="vertical">
      <StyledModal title={errorModal?.summary} open={errorModal != null} destroyOnClose onCancel={() => setErrorModal(null)} onOk={() => setErrorModal(null)}>
        <Typography.Paragraph>Error Code: {errorModal?.errorCode}</Typography.Paragraph>
        <Typography.Paragraph>Internal Error code: {errorModal?.technicalDetails?.internalErrorCode}</Typography.Paragraph>
        <Typography.Paragraph>Summary: {errorModal?.technicalDetails?.description}</Typography.Paragraph>
      </StyledModal>
      <Skeleton loading={isLoading} active>
        <Table
          scroll={{
            x: 'max-content'
          }}
          columns={columns}
          dataSource={data}
          expandable={{
            rowExpandable: () => true,
            expandedRowRender: (project: DataType) => <MembershipDetails onSetErrorModal={setErrorModal} project={project} />
          }}
        />
      </Skeleton>
    </FullWidthSpace>
  );
};
