export class ApiError extends Error {
  constructor(
    msg: string,
    public readonly statusCode: number,
    public readonly reasons: { path: string; message: string; value: string }[] = []
  ) {
    super(msg);
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  get readableMessage(): string {
    let errorFromCode = '';
    if (this.statusCode === 403) {
      errorFromCode = 'Insufficient permissions to perform this action';
    }
    if (this.statusCode === 404) {
      errorFromCode = 'Resource was not found';
    }
    if (this.statusCode === 405) {
      errorFromCode = 'Not allowed, resource referenced or out of date. Try refreshing the page.';
    }
    if (this.statusCode === 409) {
      errorFromCode = 'Not allowed, conflicting resource exists or out of date. Try refreshing the page.';
    }
    if (this.statusCode === 500) {
      errorFromCode = 'Internal server error';
    }

    let returnError = errorFromCode;

    if (this.reasons.length > 0) {
      returnError += ` - Reasons: ${this.reasons.map((reason) => `${reason.path}: ${reason.message}`).join(', ')}`;
    }

    returnError += ` (Status Code: ${this.statusCode})`;

    return returnError;
  }
}
