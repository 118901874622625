import { useState, useEffect } from 'react';
import { ElementIds } from '../../../constants/elementIds';

/**
 * Custom hook to get the height of a DOM element by its ID.
 *
 * @param {string} elementId - The ID of the DOM element whose height is to be measured.
 * @returns {number} - The height of the specified DOM element in pixels.
 *
 * @example
 * const height = useElementHeight('myElementId');
 * console.log(height); // Outputs the height of the element with ID 'myElementId'
 *
 * @remarks
 * This hook sets up an event listener on the window resize event to update the height
 * whenever the window is resized. It cleans up the event listener when the component
 * using this hook is unmounted or when the elementId changes.
 */
const useElementHeight = (elementId: keyof typeof ElementIds): number => {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const updateHeight = () => {
      const element = document.getElementById(ElementIds[elementId]);
      if (!!element) {
        setHeight(element.clientHeight);
      }
    };

    updateHeight();

    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [elementId]);

  return height;
};

export default useElementHeight;
