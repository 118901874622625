import { Button, Select, Tooltip } from 'antd';
import { WizardProps } from './types';
import { PageLoading, usePermissions } from '@pacts-plugin-api';
import {
  StyledWizardBody,
  StyledWizardCard,
  StyledWizardFooter,
  StyledWizardFooterRightArea,
  StyledWizardHeader,
  StyledWizardHeaderSubTitle,
  StyledWizardHeaderTitle,
  StyledWizardSteps
} from './WizardCard.styled';
import { PacTSPermissions } from '@pacts/permissions-lib';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

/**
 * Wizard component
 *
 * Example:
 *
 * Current step and step change is handled outside of the Wizard component to be able to control the validation or other
 * navigation restriction rules
 *
 * const [currentWizardStep, setCurrentWizardStep] = useState<number>(0); // We keep current step on state
 *
 * const handleWizardStepChange = (newWizardStep: number) => { // We update current step on step change callback
 *     const currentWizardStepForm = wizardSteps[currentWizardStep].content?.props?.form;
 *     setCurrentWizardStep(newWizardStep);
 * };
 *
 * const wizardSteps: WizardStep[] = [ // We define an object of steps
 *     {
 *       title: 'Step 1',
 *       description: 'Description for step 1',
 *       content: <MyComponentForStep1 />,
 *       loading: isLoadingSomeData
 *     },
 *     {
 *       title: 'Step 2',
 *       description: 'Description for step 2',
 *       content: <MyComponentForStep2 />
 *     },
 * ];
 *
 * <Wizard
 *         steps={wizardSteps}
 *         currentStep={currentWizardStep}
 *         onStepChange={handleWizardStepChange}
 *         onCancel={() => setCancelModalVisible(true)} // Callback for cancel button
 *
 *         cancelText="Discard" // Optional. Default value = Cancel
 *         title="My Wizard" // Optional
 *         subtitle="This is a subtitle" // Optional
 *
 *         // Optional. If following props are provided, the Next button on final step will be replaced
 *         // Alternatively an additional action can be added for final step action and Next will remain in place and disabled
 *         onFinalStepButton={submitForm} // Optional
 *         finalStepButtonText="Submit" // Optional
 *         finalStepButtonLoading={isLoadingSubmitForm} // Optional
 *         finalStepButtonType="primary" // Optional
 *         finalStepButtonGhost={true} // Optional
 *
 *         // Optional. Actions can be added on any steps in the center or right location
 *         additionalActions={[
 *           { // Button
 *             text: 'Add something', // For button type only
 *             onClick: handleAddSomethingClick, // For button type only
 *             buttonType: 'primary', // Antd supported button types. For button type only
 *             ghost: true, // Optional. Displays a ghost button. For button type only
 *             location: 'right', // Can be right or center
 *             steps: [1], // Optional. Array of steps where we want the action to be displayed.
 *                      If no steps are provided, the action will be displayed on all steps.
 *                      If empty array, action will not be displayed at all (useful for conditional rendering)
 *             loading: isLoadingSomething, // Optional
 *             disabled: isAddSomethingDisabled, // Optional
 *             tooltip: `Tooltip description for my button` // Optional
 *             permission: 'pAisForceSvc$updateFlexibleConductorCalculation' // Permission for the action
 *           },
 *           { // Select
 *             type: 'select',
 *             value: 'en', // For select type only
 *             options: [ // For select type only
 *               { value: 'en', label: 'EN' },
 *               { value: 'de', label: 'DE' }
 *             ],
 *             onChange: (language) => setLanguage(language), // For select type only
 *             (rest of the props are the same as for button)
 *           }
 *         ]}
 * />
 *
 * (for a full example check /p-apps/ais-force/src/pages/RigidCylindricalConductor/RigidCylindricalConductor.tsx)
 *
 * @param title
 * @param subtitle
 * @param steps
 * @param currentStep
 * @param onStepChange
 * @param onCancel
 * @param cancelText
 * @param finalStepButton
 * @param finalStepButtonText
 * @param finalStepButtonLoading
 * @param finalStepButtonType
 * @param finalStepButtonGhost
 * @param additionalActions
 * @constructor
 */

const StyledActionButton = styled(Button)`
  margin: 0 10px;
`;

const Wizard = ({
  title,
  subtitle,
  steps,
  currentStep,
  onStepChange,
  onCancel,
  cancelText,
  onFinalStepButton,
  finalStepButtonText,
  finalStepButtonLoading,
  finalStepButtonType,
  finalStepButtonGhost,
  additionalActions
}: WizardProps) => {
  const { projectId } = useParams() as { projectId: string };
  const permissions: PacTSPermissions = usePermissions({ projectId });

  const nextStep = () => {
    const newCurrentStep = currentStep + 1;

    onStepChange(newCurrentStep);
  };

  const prevStep = () => {
    onStepChange(currentStep - 1);
  };

  const jumpToStep = (stepNumber: number) => {
    const jumpToNextAndEnabled: boolean = !steps[currentStep].nextDisabled;

    if (jumpToNextAndEnabled) {
      onStepChange(stepNumber);
    }
  };

  const isNotLastStep = currentStep !== steps.length - 1;
  const isLastStep = currentStep === steps.length - 1;
  const isNextStepDisabled = steps[currentStep].nextDisabled || (isLastStep && !onFinalStepButton);

  return (
    <StyledWizardCard>
      <StyledWizardHeader>
        <StyledWizardHeaderTitle level={5}>
          {title}
          <StyledWizardHeaderSubTitle>{subtitle}</StyledWizardHeaderSubTitle>
        </StyledWizardHeaderTitle>
      </StyledWizardHeader>
      <StyledWizardSteps
        current={currentStep}
        progressDot
        items={steps.map((step) => ({ title: step.title, description: step.description }))}
        onChange={jumpToStep}
      />
      <StyledWizardBody>{steps[currentStep].loading ? <PageLoading /> : steps[currentStep].content}</StyledWizardBody>
      <StyledWizardFooter>
        <div>
          <Button onClick={onCancel} danger={true}>
            {cancelText || 'Cancel'}
          </Button>
        </div>
        <div>
          {additionalActions
            ?.filter(
              (action) =>
                action.location === 'center' &&
                (!action.steps || action.steps.includes(currentStep)) &&
                (action.permission ? permissions[action.permission] : true)
            )
            .map((action) =>
              action.type === 'select' ? (
                <Select onChange={action.onChange} value={action.value} loading={action.loading} options={action.options} />
              ) : (
                <StyledActionButton
                  type={action.buttonType}
                  ghost={!!action.ghost}
                  className="save"
                  onClick={action.onClick}
                  disabled={action.disabled}
                  loading={action.loading}
                >
                  {action.text}
                </StyledActionButton>
              )
            )}
        </div>
        <StyledWizardFooterRightArea>
          {additionalActions
            ?.filter((action) => action.location === 'right' && (!action.steps || action.steps.includes(currentStep)))
            .map((action) => (
              <Tooltip title={action.tooltip}>
                {action.type === 'select' ? (
                  <Select onChange={action.onChange} value={action.value} loading={action.loading} options={action.options} />
                ) : (
                  <StyledActionButton
                    type={action.buttonType}
                    ghost={!!action.ghost}
                    className="save"
                    onClick={action.onClick}
                    disabled={action.disabled}
                    loading={action.loading}
                  >
                    {action.text}
                  </StyledActionButton>
                )}
              </Tooltip>
            ))}
          <StyledActionButton onClick={prevStep} disabled={currentStep === 0}>
            Previous
          </StyledActionButton>
          {(isNotLastStep || (isLastStep && !onFinalStepButton)) && (
            <Button type="primary" onClick={nextStep} disabled={isNextStepDisabled}>
              Next
            </Button>
          )}

          {isLastStep && onFinalStepButton && (
            <Button onClick={onFinalStepButton} type={finalStepButtonType} loading={finalStepButtonLoading} ghost={finalStepButtonGhost}>
              {finalStepButtonText}
            </Button>
          )}
        </StyledWizardFooterRightArea>
      </StyledWizardFooter>
    </StyledWizardCard>
  );
};

export default Wizard;
