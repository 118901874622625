import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
import { useUserInfoRefreshQueryHook } from '../hooks/useUserInfoRefreshQueryHook';
import { LoginLayout } from './LoginLayout';
import { useStyles } from '../../theme/useStyles';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';
import { useSession } from '..';
import { useContext } from 'react';
import { PacTSContext } from '../../../state/store';
import { NavigateWithState } from '../../navigation/components/NavigateWithState';

export const Unauthorized = () => {
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  const isUnauthorized = session.userInfo?.status !== 'active';

  // If a user logged in to pacts for the first time with specific deeplink
  // use that from location state and redirect
  const location = useLocation();
  const redirectPath = `${location.state?.from?.pathname ?? ''}${location.state?.from?.search ?? ''}${location.state?.from?.hash ?? ''}` || '/';

  const unauthorizedDebounced = useDebounce(isUnauthorized, 100);
  const token = useStyles();
  useUserInfoRefreshQueryHook();

  return (
    <>
      {!unauthorizedDebounced && <NavigateWithState to={redirectPath ?? '/'} replace />}
      {unauthorizedDebounced && (
        <LoginLayout>
          <div>
            <div style={{ height: '3vh' }} />
            <Row align="middle" justify="center">
              <Col>
                <Typography.Title style={{ textAlign: 'center' }} level={4}>
                  <CheckCircleFilled style={{ color: token.colorSuccess }} /> Your access request was sent successfully!
                </Typography.Title>
              </Col>
            </Row>
            <div style={{ height: '1.5vh' }} />
            <Row align="middle" justify="center">
              <Col>
                <Typography.Title style={{ maxWidth: 380, textAlign: 'center' }} level={5}>
                  You will be notified via email once access is granted.
                </Typography.Title>
              </Col>
            </Row>
          </div>
        </LoginLayout>
      )}
    </>
  );
};
