/* eslint-disable */
import { ThemePropsProvider } from '../theme';
import { theme } from 'antd';
import { AliasToken, MapToken, OverrideToken, SeedToken } from 'antd/es/theme/interface';
import { primitivesDark, opacityToHex } from './common';

export const colorSecondaryDark = primitivesDark.teal[6];
export const colorTextReversedDark = primitivesDark.grey.solidReversed;
export const colorSecondaryBgContainerDark = primitivesDark.teal[3];

const backgroundColor = primitivesDark.grey[10];
const backgroundElevated = primitivesDark.neutral[3];
export const pageHeaderDarkBg = backgroundElevated; 

export const dark: Partial<AliasToken> = {
  fontSize: 15,
  wireframe: false,
  borderRadius: 5,

  colorPrimary: primitivesDark.purple[6],
  colorPrimaryText: primitivesDark.purple[5],

  colorText: primitivesDark.grey.solid,
  colorTextBase: primitivesDark.grey.solid,
  colorTextDescription: primitivesDark.grey.solid + opacityToHex(.75),
  colorTextSecondary: primitivesDark.grey.solid + opacityToHex(.75),
  colorTextTertiary: primitivesDark.grey.solid + opacityToHex(.65),
  colorTextPlaceholder: primitivesDark.grey.solid + opacityToHex(.45),
  colorTextDisabled: primitivesDark.grey.solid + opacityToHex(.55),
  colorTextLabel: primitivesDark.grey.solid,

  colorInfo: primitivesDark.teal[6],
  colorLink: primitivesDark.SEpurple[300],
  colorLinkActive: primitivesDark.SEpurple[600],
  colorLinkHover: primitivesDark.SEpurple[600],

  colorError: primitivesDark.red[6],
  colorErrorHover: primitivesDark.red[7],
  colorErrorText: primitivesDark.red[5],
  colorErrorTextHover: primitivesDark.red[5],

  colorSuccess: primitivesDark.green[6],
  colorSuccessText: primitivesDark.green[9],
  colorSuccessTextHover: primitivesDark.green[7],
  colorSuccessBg: primitivesDark.green[1] + opacityToHex(.45), // TO BE CHECKED

  colorWarning: primitivesDark.orange[7],
  colorWarningText: primitivesDark.orange[7],
  colorWarningTextHover: primitivesDark.orange[5],
  colorWarningBg: primitivesDark.orange[1] + opacityToHex(.45), //TO BE CHECKED

  colorBorder: primitivesDark.neutral[9], // 55% opacity
  colorBorderBg: primitivesDark.neutral[9], // 55% opacity
  colorBorderSecondary: primitivesDark.neutral[8],

  colorBgLayout: primitivesDark.neutral[2],

  colorBgBase: primitivesDark.neutral[1],
  colorBgElevated: primitivesDark.neutral[3],
  colorBgContainer: primitivesDark.neutral[7],
};

export const darkTheme = (): ThemePropsProvider => {
  const algorithm = (token: SeedToken): MapToken => {
    const res = theme.darkAlgorithm(token);
    const custom = { ...res, ...dark };
    return custom;
  };
  const tokens: (token: SeedToken) => OverrideToken = (token: SeedToken) => {
    return {
      Alert: {
        colorWarning: primitivesDark.orange[8],
        colorWarningBg: primitivesDark.orange[5] + opacityToHex(.4),
        colorWarningBorder: primitivesDark.orange[6] + ' !important'
      },
      Badge: {
        colorBgContainer: primitivesDark.grey.solid
      },
      Button: {
        colorText: primitivesDark.grey.solid,
        colorTextDisabled: primitivesDark.grey.solid + opacityToHex(.55),
        colorBgContainer: 'transparent', // button background color,
        colorBgContainerDisabled: primitivesDark.neutral[9],
        defaultHoverBorderColor: primitivesDark.purple[4],
        defaultHoverColor: primitivesDark.purple[4],
        colorErrorOutline: 'transparent',
        controlTmpOutline: 'rgba(0,0,0, 0.1)', // shadow color
        primaryShadow: 'none',
        colorBorder: primitivesDark.neutral[11]
      },
      Card:{
        colorBorderSecondary: primitivesDark.neutral[11],
        colorBgContainer: primitivesDark.neutral[3],
        boxShadowCard: `0 1px 2px -2px ${primitivesDark.neutral[1]}, 0 3px 6px 0 ${primitivesDark.neutral[1]}, 0 5px 12px 4px ${primitivesDark.neutral[1]}`
      },
      Checkbox: {
        colorBgContainer: 'transparent',
        colorPrimary: primitivesDark.teal[5],
        colorPrimaryHover: primitivesDark.teal[7],
        colorWhite: primitivesDark.grey.solidReversed,
        colorBorder: primitivesDark.grey[10]
      },
      DatePicker: {
        colorBgContainer: 'transparent',
        colorBgContainerDisabled: primitivesDark.neutral[7],
        colorTextDisabled: primitivesDark.grey.solid + opacityToHex(.7),
        colorPrimary: primitivesDark.teal[5],
        hoverBorderColor: primitivesDark.teal[5],
        activeShadow: 'none',
        cellActiveWithRangeBg: primitivesDark.teal[1],
      },
      Dropdown: {
        colorError: primitivesDark.red[5],
        colorPrimary: primitivesDark.teal[4],
        controlItemBgHover: primitivesDark.teal[4] + opacityToHex(.5),
        activeShadow: 'none'
      },
      FloatButton: {
        colorPrimary: primitivesDark.teal[5],
        colorPrimaryHover: primitivesDark.teal[6]
      },
      Input: {
        colorBgContainerDisabled: primitivesDark.neutral[7],
        colorTextDisabled: primitivesDark.grey.solid + opacityToHex(.7),
        colorPrimary: primitivesDark.teal[3],
        colorPrimaryActive: primitivesDark.teal[4],
        colorPrimaryHover: primitivesDark.teal[4],
        colorBgContainer: 'transparent',
        activeShadow: 'none'
      },
      InputNumber: {
        colorBgContainerDisabled: primitivesDark.neutral[7],
        colorTextDisabled: primitivesDark.grey.solid + opacityToHex(.7),
        colorBgContainer: 'transparent',
        colorPrimary: primitivesDark.teal[3],
        hoverBorderColor: primitivesDark.teal[4],
        activeBorderColor: primitivesDark.teal[4],
        handleHoverColor: primitivesDark.teal[4],
        activeBg: primitivesDark.neutral[2]
      },
      Layout: {
        headerBg: dark.colorBgContainer,
        siderBg: primitivesDark.neutral[3],
        triggerBg: primitivesDark.neutral[3],
        triggerColor: dark.colorText
      },
      Menu: {
        itemBg: 'transparent',
        itemColor: primitivesDark.grey.solid,
        itemSelectedBg: primitivesDark.teal[3],
        itemSelectedColor: primitivesDark.grey.solid,
        itemHoverBg: primitivesDark.teal[3] + opacityToHex(.73),
        horizontalItemSelectedBg: primitivesDark.teal[3],
        horizontalItemSelectedColor: primitivesDark.grey.solid,
        horizontalItemHoverBg: primitivesDark.teal[3] + opacityToHex(.73),
        activeBarBorderWidth: 0,
        activeBarHeight: 0,
        itemDisabledColor: primitivesDark.grey[7]
      },
      Pagination: {
        colorPrimary: primitivesDark.teal[5],
        colorPrimaryHover: primitivesDark.teal[5]
      },
      Popconfirm: {
        colorWarning: primitivesDark.red[6]
      },
      Radio: {
        colorPrimary: primitivesDark.teal[4],
        colorPrimaryActive: primitivesDark.teal[3],
        colorPrimaryHover: primitivesDark.teal[4],
        colorPrimaryBorder: primitivesDark.teal[4]
      },
      Segmented: {
        colorBgLayout: primitivesDark.neutral[7],
        itemSelectedBg: primitivesDark.teal[4],
        itemSelectedColor: primitivesDark.grey.solid,
        itemHoverColor: primitivesDark.grey.solid,
        itemHoverBg: primitivesDark.teal[4] + opacityToHex(.55),
        itemActiveBg: primitivesDark.teal[3] + opacityToHex(.55)
      },
      Select: {
        colorBgContainerDisabled: primitivesDark.neutral[7],
        colorTextDisabled: primitivesDark.grey.solid + opacityToHex(.7),
        colorBgContainer: 'transparent',
        selectorBg: 'transparent',
        colorBorder: primitivesDark.neutral[11],
        controlOutline: primitivesDark.teal[4],
        controlOutlineWidth: 1,
        colorPrimary: primitivesDark.teal[3],
        colorPrimaryHover: primitivesDark.teal[4],
        optionSelectedBg: primitivesDark.teal[3],
        optionSelectedColor: primitivesDark.grey.solid,
        colorIconHover: primitivesDark.teal[4],
        controlItemBgHover: primitivesDark.teal[4] + opacityToHex(.55),
        activeShadow: 'none'
      },
      Skeleton: {
        colorFillContent: primitivesDark.neutral[5]
      },
      Steps: {
        colorPrimary: primitivesDark.teal[4] // for the borders & and counters
      },
      Switch: {
        colorPrimary: primitivesDark.teal[4],
        colorPrimaryHover: primitivesDark.teal[5],
        colorPrimaryBorder: 'transparent'
      },
      Table: {
        colorPrimary: primitivesDark.teal[6], // this is for sorting arrow, when one is selected
        colorFillAlter: primitivesDark.neutral[10], // this is for sorted/selected column (rows & header)
        headerSortActiveBg: primitivesDark.neutral[10], // same as above
        headerSortHoverBg: primitivesDark.neutral[10] + ' !important',
        headerBg: primitivesDark.neutral[8],
        rowSelectedBg: 'transparent',
        rowHoverBg: primitivesDark.teal[1], // primitivesDark.teal[4] + opacityToHex(.1),
        rowSelectedHoverBg: primitivesDark.teal[1], // primitivesDark.teal[4] + opacityToHex(.1),
        bodySortBg: primitivesDark.neutral[6] + ' !important',
        controlItemBgActive: 'transparent', // disable highlight row
        controlItemBgHover: primitivesDark.teal[4] + opacityToHex(.1),
        controlItemBgActiveHover: primitivesDark.teal[4] + opacityToHex(.1),
        borderColor: primitivesDark.neutral[9] + opacityToHex(.5)
      },
      Tabs: {
        itemActiveColor: primitivesDark.teal[5],
        colorPrimaryBorder: primitivesDark.teal[5],
        itemHoverColor: primitivesDark.teal[7],
        colorBgContainer: primitivesDark.teal[3],
        itemSelectedColor: primitivesDark.grey.solid,
        inkBarColor: primitivesDark.teal[5],
        fontSize: 15,
        colorBorderSecondary: primitivesDark.neutral[11]
      },
      Tag: {
        colorInfo: primitivesDark.violet[8],
        colorInfoBg: primitivesDark.violet[1],
        colorInfoBorder: primitivesDark.violet[5] + opacityToHex(.45),
        colorWarning: primitivesDark.orange[9],
        colorSuccess: primitivesDark.green[7]
      },
      Tooltip: {
        colorBgSpotlight: primitivesDark.neutral[5]
      },
      Tree: {
        colorPrimary: primitivesDark.teal[4],
        colorPrimaryHover: primitivesDark.teal[6],
        colorPrimaryBorder: primitivesDark.teal[4]
      },
      TreeSelect: {
        colorPrimaryHover: primitivesDark.teal[5]
      },
      Typography: {
        linkHoverDecoration: 'underline',
        colorError: primitivesDark.red[5],
        colorWarning: primitivesDark.orange[9]
      }
    };
  };

  return { algorithm, tokens, colors: dark };
};
