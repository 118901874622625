import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ScopedSoftwareApp } from '../../../types';
import { SoftwareApp } from '../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../../../session/hooks/usePermissions';

export function useProjectSoftwareApps(projectId: string) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId });

  const hasValidBundleId = parseInt(projectId, 10) > -1;

  const permissionsList = permissions.engineeringSvc$getProjectSoftwareApps;

  const specificPermissions = !permissionsList ? permissions.engineeringSvc$getProjectSoftwareApp$specific() : [];

  const enabled = hasValidBundleId && (permissionsList || specificPermissions.length > 0);

  return useQuery<SoftwareApp[], [string, string]>(
    ['softwareApps', projectId],
    (): Promise<SoftwareApp[]> => {
      if (permissionsList) return backend.queryProjectApps(projectId);
      return Promise.all(specificPermissions.map((specificPermission) => backend.queryProjectApp(projectId, specificPermission.softwareAppId)));
    },
    {
      enabled
    }
  );
}

export function useGetProjectSoftwareAppsScoped(projectId: string) {
  const softwareApps = useProjectSoftwareApps(projectId);

  const data = useMemo(() => {
    let scopedApps: ScopedSoftwareApp[] = [];
    if (softwareApps.data) {
      scopedApps = [...softwareApps.data].map((a) => {
        const scopedapp: ScopedSoftwareApp = {
          ...a,
          scope: 'project'
        };
        return scopedapp;
      });
    }
    return scopedApps;
  }, [softwareApps.data]);
  return {
    ...softwareApps,
    data
  };
}
