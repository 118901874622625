import { Modal } from 'antd';
import { StyledModal } from '../../../contexts/shared/components/StyledModal';
import styled from 'styled-components';

type ScrollDialogStyles = { minHeight?: string };
type ScrollDialogProps = React.ComponentProps<typeof Modal & ScrollDialogStyles>;

const StyledScrollDialog = styled(StyledModal)<ScrollDialogStyles>`
  .ant-modal-body {
    max-height: calc(100vh - 380px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}
  }
`;

export const ScrollDialog = (props: ScrollDialogProps & ScrollDialogStyles) => {
  return <StyledScrollDialog width={1024} maskClosable={false} {...props} />;
};
