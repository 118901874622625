import { useQuery, useQueryClient } from '@tanstack/react-query';
import { BundleConfiguration, BundleReleaseLite } from '../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';

type BundleConfigurationVersionData = {
  projectId: string;
  bundleId: string;
  bundleConfigName: string;
  bundleConfigId: string;
  bundleConfigReleaseVersion: string;
  bundleConfigReleaseId: string;
  bundleReleases: BundleReleaseLite[];
  bundleConfigurations: BundleConfiguration[];
};

// data is used from cache (existing queries) if it is not older than 5 minutes
const staleTime = 5 * 60 * 1000;

export const useConfigurationVersionDropdownData = (projectId: string, bundleId: string, bundleConfigId: string, bundleConfigVersionId: string) => {
  const queryClient = useQueryClient();
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const parsedBundleId = parseInt(bundleId, 10);
  let parsedConfigId = parseInt(bundleConfigId, 10);
  let parsedConfigReleaseId = parseInt(bundleConfigVersionId, 10);

  // calculate permissions based on project id, bundle id and configuration id
  // a user might have permissions to only query specific bundles/configurations, hence the $specific permissions have to be evaluated
  const permissions = usePermissions({ projectId, idBundle: bundleId, idConfiguration: bundleConfigId });

  const specificConfigurationsPermission = permissions.engineeringSvc$getProjectBundleConfiguration$specific();
  const hasSpecificConfigurationsPermission = specificConfigurationsPermission.length > 0;
  const hasGenericConfigurationsPermission = permissions.engineeringSvc$getProjectBundleConfigurations;

  const specificReleasesPermission = permissions.engineeringSvc$getProjectBundleConfigurationRelease$specific();
  const hasSpecificReleasesPermission = specificReleasesPermission.length > 0;
  const hasGenericReleasePermission = permissions.engineeringSvc$getProjectBundleConfigurationReleases;

  // if the user has no permissions to query any configurations or releases, the query should not be enabled
  const hasPermission =
    (hasSpecificConfigurationsPermission || hasGenericConfigurationsPermission) && (hasSpecificReleasesPermission || hasGenericReleasePermission);

  const key = ['bundleConfigVersionData', projectId, bundleId];
  const enabled = parsedProjectId > 0 && parsedBundleId > 0 && hasPermission;

  if (parsedConfigId > 0) {
    key.push(bundleConfigId);
    if (parsedConfigReleaseId > 0) key.push(bundleConfigVersionId);
  }

  return useQuery<BundleConfigurationVersionData, [string, string, string, string, string]>(
    key,
    async (): Promise<BundleConfigurationVersionData> => {
      // fall back to state if data is available and not stale
      const configsState = queryClient.getQueryState<BundleConfiguration[]>(['bundleConfigurations', projectId, bundleId]);
      let configs = configsState?.data;
      if ((configsState?.dataUpdatedAt || 0) < Date.now() - staleTime) {
        configs = undefined;
      }
      if (!configs) {
        configs = await (hasSpecificConfigurationsPermission
          ? Promise.all(specificConfigurationsPermission.map((s) => backend.queryBundleConfiguration(s.projectId, s.idBundle, s.idConfiguration)))
          : backend.queryBundleConfigurations(projectId, bundleId));

        // populate cache if data was queried
        queryClient.setQueryData(['bundleConfigurations', projectId, bundleId], configs);
      }
      if (configs.length < 1) {
        return {
          projectId,
          bundleId,
          bundleConfigId: '',
          bundleConfigReleaseId: '',
          bundleConfigName: '',
          bundleConfigReleaseVersion: '',
          bundleConfigurations: [],
          bundleReleases: []
        };
      }

      let existingConfig = configs.find((config) => config.idBundleConfiguration === parsedConfigId);
      if (!existingConfig) {
        parsedConfigId = configs.sort((configA, configB) => (configA.idBundleConfiguration || 0) - (configB.idBundleConfiguration || 0))[0]
          .idBundleConfiguration!;
      }
      existingConfig = configs.find((config) => config.idBundleConfiguration === parsedConfigId)!;

      // fall back to state if data is available and not stale
      const configVersionsState = queryClient.getQueryState<BundleReleaseLite[]>([
        'bundleConfigurationVersions',
        projectId,
        bundleId,
        parsedConfigId.toString()
      ]);
      let versions = configVersionsState?.data;

      // Tries to prevent possible cache errors
      const versionsArrayEmpty = versions && versions.length < 1;

      const versionsDataStale = (configVersionsState?.dataUpdatedAt || 0) < Date.now() - staleTime;

      // Invalidate versions list
      if (versionsDataStale || versionsArrayEmpty) {
        versions = undefined;
      }
      if (!versions) {
        versions = await (hasSpecificReleasesPermission
          ? Promise.all(
              specificReleasesPermission.map((release) =>
                backend.queryBundleConfigurationVersion(release.projectId, release.idBundle, release.idConfiguration, release.idRelease)
              )
            )
          : backend.queryBundleConfigurationVersions(projectId, bundleId, parsedConfigId.toString()));

        // populate cache if data was queried
        queryClient.setQueryData(['bundleConfigurationVersions', projectId, bundleId, parsedConfigId.toString()], versions);
      }

      if (versions.length < 1) {
        const res: BundleConfigurationVersionData = {
          projectId,
          bundleId,
          bundleConfigName: existingConfig.name,
          bundleConfigId: existingConfig.idBundleConfiguration!.toString(),
          bundleConfigReleaseId: '',
          bundleConfigReleaseVersion: '',
          bundleConfigurations: configs,
          bundleReleases: []
        };
        return res;
      }

      let existingRelease = versions.find((release) => release.idBundleRelease === parsedConfigReleaseId);

      const hasLatestReleasesId = existingConfig?.latestBundleReleaseId !== undefined;
      if (!existingRelease && hasLatestReleasesId) {
        existingRelease = versions.find((version) => version.idBundleRelease === existingConfig?.latestBundleReleaseId);
      }
      if (!existingRelease) {
        parsedConfigReleaseId = versions.sort((releaseA, releaseB) => (releaseB.idBundleRelease || 0) - (releaseA.idBundleRelease || 0))[0].idBundleRelease!;
        existingRelease = versions.find((release) => release.idBundleRelease === parsedConfigReleaseId)!;
      }

      const res: BundleConfigurationVersionData = {
        projectId,
        bundleId,
        bundleConfigId: existingConfig.idBundleConfiguration!.toString(),
        bundleConfigName: existingConfig.name,
        bundleConfigReleaseId: existingRelease.idBundleRelease!.toString(),
        bundleConfigReleaseVersion: existingRelease.version!,
        bundleConfigurations: configs,
        bundleReleases: versions
      };
      return res;
    },
    {
      enabled
    }
  );
};
