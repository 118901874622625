import React, { useEffect, useMemo, useState } from 'react';
import { Otc } from '../../../api/deployment/domain';
import { Button, Card, Col, Flex, List, Progress, Row, Space, Switch, Typography } from 'antd';
import CreateDeviceListEntry from './CreateDeviceListEntry';
import { usePromiseMutation } from '../../shared/hooks/usePromiseMutation';
import styled from 'styled-components';
import { type ApiError } from '../../../api/shared/apiError';
import { FullWidthSpace } from '../../../contexts/shared/base/Components.styled';
import { HideContainer } from '../../../contexts/shared/components/HideContainer';

export interface DeviceCsvPopoverListProps {
  projectId: number;
  forms: { rds: string; description: string; otcsObjects: Otc[]; notFoundOtcs: string[] }[];
  onIsLoading: (isLoading: boolean) => void;
}

const ListWrapper = styled(Flex)`
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const FlexWrapper = styled.div`
  max-height: calc(100vh - 380px);
  display: flex;
  flex-flow: column;
`;

const CommandWrapper = styled.div`
  flex: 0 1 auto;
`;

const ResultListWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 16px;
  flex: 1 1 auto;
`;

/**
 * DeviceCsvPopoverList component is responsible for rendering a list of device forms and providing functionality to create all devices at once.
 *
 * @component
 * @param {DeviceCsvPopoverListProps} props - The properties passed to the component.
 * @param {Array} props.forms - An array of device form data.
 * @param {string} props.projectId - The ID of the project to which the devices belong.
 * @param {Function} props.onIsLoading - Callback function to notify the parent component about the loading state.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <DeviceCsvPopoverList
 *   forms={deviceForms}
 *   projectId="project-id"
 *   onIsLoading={handleLoadingState}
 * />
 */
const DeviceCsvPopoverList: React.FC<DeviceCsvPopoverListProps> = (props) => {
  const { forms, projectId, onIsLoading } = props;

  const [showSuccessfullyCreated, setShowSuccessfullyCreated] = useState(false);

  const submitMethods = useMemo(() => Array<() => Promise<void>>(forms.length), [forms]);

  const { mutation: executeMutation, progress: executionProgress } = usePromiseMutation(submitMethods);

  const resultWithoutErrors = executeMutation.data?.every((result) => result.error === null);
  const resultAllErrors = executeMutation.data?.every((result) => result.error !== null);
  const resultWithPartialErrors = !resultAllErrors && executeMutation.data?.some((result) => result.error !== null);

  const errors = executeMutation.data?.map((result, index) => ({ result, index })).filter((result) => result.result.error !== null);
  const successes = executeMutation.data?.map((result, index) => ({ result, index })).filter((result) => result.result.error === null);

  let resultStatus: React.ComponentProps<typeof Progress>['status'] = 'success';
  if (resultAllErrors) {
    resultStatus = 'exception';
  } else if (resultWithPartialErrors) {
    resultStatus = 'normal';
  } else if (executeMutation.isLoading) {
    resultStatus = 'active';
  }

  useEffect(() => {
    onIsLoading(executeMutation.isLoading);
  }, [executeMutation.isLoading, onIsLoading]);

  // memoize components for performance reasons
  // don't rerender on progress update
  const inputComponents = useMemo(
    () =>
      forms.map((device, index) => {
        const isSuccessful = successes?.some((error) => error.index === index);
        // keep hidden entries in dom to maintain their form state
        const hidden = !showSuccessfullyCreated && isSuccessful;
        return (
          <HideContainer key={`dev-form-${index}`} hidden={hidden}>
            <Card>
              <Typography.Paragraph strong>{`Device ${index + 1}`}</Typography.Paragraph>
              <CreateDeviceListEntry
                projectId={projectId}
                createTrigger={(submit) => {
                  submitMethods[index] = submit;
                }}
                initial={{
                  actionLog: [],
                  description: device.description,
                  id: '',
                  mvccId: 0,
                  otc: device.otcsObjects,
                  rds: device.rds,
                  createdAt: '',
                  createdBy: 0,
                  createdByName: '',
                  updatedAt: '',
                  updatedBy: 0,
                  updatedByName: ''
                }}
              />
            </Card>
          </HideContainer>
        );
      }),
    [submitMethods, projectId, forms, successes, showSuccessfullyCreated]
  );

  return (
    <Row wrap={false} gutter={[16, 16]}>
      <Col flex="auto">
        <FullWidthSpace direction="vertical">
          <Space>
            <Switch checked={showSuccessfullyCreated} onChange={setShowSuccessfullyCreated} /> Show Successfully Created Devices
          </Space>
          <ListWrapper vertical gap="middle">
            {...inputComponents}
          </ListWrapper>
        </FullWidthSpace>
      </Col>
      <Col flex="640px">
        <Card>
          <FlexWrapper>
            <CommandWrapper>
              <Typography.Paragraph strong>Create all Devices</Typography.Paragraph>
              <Typography.Paragraph>{`This operation will attempt to create ${forms.length} device(s).`}</Typography.Paragraph>
              <Typography.Paragraph>
                In case individual devices cannot be created (e.g., if they are already existing) the error will be captured but the process will continue.
                After the process is finished, please verify the results.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Before using the create all operation, please check the devices in the list on the left side for correctness. This operation is not
                transactional.
              </Typography.Paragraph>
              <Row justify="end" align="middle" gutter={[16, 16]}>
                <Col flex="auto">
                  {!executeMutation.isIdle && (
                    <Progress percentPosition={{ align: 'start', type: 'outer' }} percent={Math.round(executionProgress * 100)} status={resultStatus} />
                  )}
                </Col>
                <Col>
                  <Button disabled={props.forms.length < 1} loading={executeMutation.isLoading} onClick={() => executeMutation.mutate()}>
                    Create all Devices
                  </Button>
                </Col>
              </Row>
            </CommandWrapper>
            <ResultListWrapper>
              {resultWithoutErrors && <Typography.Paragraph type="success">Creation successful for all devices</Typography.Paragraph>}
              {errors && errors?.length > 0 && (
                <List
                  dataSource={errors}
                  size="small"
                  renderItem={(error) => (
                    <List.Item key={`error-index-${error.index}`}>
                      <Typography.Text type="danger">{`Device ${error.index + 1}: ${
                        (error.result.error as ApiError)?.readableMessage ?? 'Check device error in list'
                      }`}</Typography.Text>
                    </List.Item>
                  )}
                />
              )}
            </ResultListWrapper>
          </FlexWrapper>
        </Card>
      </Col>
    </Row>
  );
};

export default DeviceCsvPopoverList;
