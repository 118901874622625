import { CheckCircleOutlined, ExclamationCircleOutlined, ImportOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row, Space, Tooltip, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { useOtcs } from '../hooks/useOtcs';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';
import { Otc } from '../../../api';
import { useParams } from 'react-router-dom';
import DeviceCsvPopoverList from './DeviceCsvPopoverList';
import { StyledModal } from '../../../contexts/shared/components/StyledModal';
import styled from 'styled-components';

const TextEndCol = styled(Col)`
  text-align: end;
`;

export const DeviceCsvPopover = (props: { projectId: number }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const params = useParams();

  const envId = params?.envId || '';
  const otcs = useOtcs(props.projectId, envId);

  const [input, setInput] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resetPopover = () => {
    setPopoverVisible(false);
    setInput('');
  };

  const onModalClose = () => {
    setPopoverVisible(false);
    setModalVisible(false);
    setInput('');
  };

  const handleOpenChange = (newOpen: boolean) => {
    setPopoverVisible(newOpen);

    // if the popover is closed and the modal is not visible we reset the input
    if (!newOpen && !modalVisible) {
      setInput('');
    }
  };

  const [inputInvalid, setInputInvalid] = useState(true);

  // parse csv input
  const deviceData = useMemo(() => {
    // if the input is empty we show the invalid message even if the parser won't throw an error
    setInputInvalid(input.length === 0);

    try {
      const lines = input
        .split('\n')
        .map((v) => v.trim())
        .filter((v) => v);
      const notFoundOtcs: string[] = [];
      return lines.map((line) => {
        const splitted = line.split(',');
        if (splitted.length < 2) {
          throw new Error(`Invalid device ${line}`);
        }
        if (splitted.length === 2) {
          splitted.push('');
        }
        const otcIds = splitted[0].split(';');
        if (otcIds.length < 1) {
          throw new Error(`Invalid otcs ${line}`);
        }

        const otcsObjects = otcIds
          .map((o) => o.trim())
          .map((otc) => {
            const foundOtc = otcs.data?.find((o) => o.name === otc);
            if (!foundOtc) {
              notFoundOtcs.push(otc);
            }
            return foundOtc;
          })
          .filter((o) => o) as Otc[];

        return {
          rds: splitted[1].trim(),
          description: splitted[2].trim(),
          otcsObjects,
          notFoundOtcs
        };
      });
    } catch (error) {
      setInputInvalid(true);
      return [];
    }
  }, [input, otcs.data]);

  return (
    <>
      <Popover
        content={
          <>
            <DescriptionTextAreaInput value={input} onChange={(ev) => setInput(ev.target.value)} />
            <Row align="middle">
              <Col>
                <Button
                  block
                  type="link"
                  onClick={() => {
                    resetPopover();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="link"
                  block
                  disabled={deviceData.length < 1}
                  onClick={() => {
                    setModalVisible(true);
                    setPopoverVisible(false);
                  }}
                >
                  OK
                </Button>
              </Col>
              <TextEndCol flex="auto">
                {inputInvalid && (
                  <Tooltip title="Please input a valid CSV">
                    <Typography.Text type="warning">
                      <ExclamationCircleOutlined />
                    </Typography.Text>
                  </Tooltip>
                )}
                {!inputInvalid && (
                  <Tooltip title="The entered CSV is valid">
                    <Typography.Text type="success">
                      <CheckCircleOutlined />
                    </Typography.Text>
                  </Tooltip>
                )}
              </TextEndCol>
            </Row>
          </>
        }
        title={
          <Space direction="vertical">
            <Typography.Text>Import Devices from CSV</Typography.Text>
            <Typography.Text>We recommend copying Devices and OTCs from the same Environment.</Typography.Text>
            <span>
              Example: <Typography.Text code>XF320; XF321; XF330,#UL74-YC01-XF10-UH01,Communication</Typography.Text>
            </span>
          </Space>
        }
        trigger="click"
        open={popoverVisible}
        onOpenChange={handleOpenChange}
      >
        <Button disabled={!otcs.isSuccess} onClick={() => setPopoverVisible(true)} icon={<ImportOutlined />}>
          Import CSV
        </Button>
      </Popover>
      <StyledModal
        title="Create Devices"
        open={modalVisible}
        width={'80%'}
        closable={!isLoading}
        onCancel={onModalClose}
        destroyOnClose
        footer={[
          <Button disabled={isLoading} onClick={onModalClose}>
            Close
          </Button>
        ]}
      >
        <DeviceCsvPopoverList forms={deviceData} projectId={props.projectId} onIsLoading={setIsLoading} />
      </StyledModal>
    </>
  );
};

export default DeviceCsvPopover;
