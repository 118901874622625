import { Skeleton, TableColumnProps, Typography } from 'antd';

import { usePermissions } from '../../../../../../session/hooks/usePermissions';

import { EditProjectSoftwareAppVersion } from '../EditSoftwareItemVersion/EditProjectSoftwareAppVersion';
import { SoftwareAppOpenInToolManagerButton } from '../../../SoftwareItemOpenInToolManagerButton/SoftwareAppOpenInToolManagerButton';
import { SemanticVersion } from '../../../../../../../domain/versioning/semanticVersion';
import Table from '../../../../../../shared/components/Table/Table';
import { ExpandableMenu } from '../../../../../../shared/components/ExpandableMenu';
import { ComponentVersionListEntry } from '../../../../../../shared/components/ComponentVersionListEntry';
import { DownloadContextMenuButton } from '../../../../../../shared/components/Download/components/DownloadContextMenuButton';
import { SoftwareAppVersionTitle } from './SoftwareAppVersionTitle';

import type { Project, SoftwareApp, SoftwareAppVersion } from '../../../../../../../api/engineering/domain/types';
import { useCallback, useMemo } from 'react';
import { useDeleteProjectSoftwareAppVersion } from './hooks/useDeleteProjectSoftwareAppVersion';
import { useProjectSoftwareAppVersions } from './hooks/useProjectSoftwareAppVersions';
import { scopeToProject } from '../../../../types';
import { UnstuckSyncingProjectSoftwareAppVersion } from '../../../../../../SoftwareApps/components/UnstuckSyncingProjectSoftwareAppVersion';
import { formatDateTime } from '../../../../../../shared/components/formatDate';
import { useTableSearch } from '../../../../../../shared/components/TableSearch';
import { ConfirmationButton } from '../../../../../../shared/components/ConfirmationButton';

export type SoftwareAppVersionsListProps = {
  project: Project;
  softwareApp: SoftwareApp;
  bundleVersion?: SoftwareAppVersion;
};

export const ProjectSoftwareAppVersionsList: React.FC<SoftwareAppVersionsListProps> = (props) => {
  const permissions = usePermissions({ projectId: props.project.idProject.toString(), softwareAppId: props.softwareApp.idSoftwareApp.toString() });
  const versionSearch = useTableSearch({ searchValueProvider: 'version', searchParamId: 'version' });

  const releaseNotesSearch = useTableSearch({
    searchValueProvider: (v: SoftwareAppVersion) => {
      return [
        v.version,
        v.releaseNotes,
        formatDateTime(new Date(v.createdAt || 0)),
        v.createdBy,
        formatDateTime(new Date(v.updatedAt || 0)),
        v.updatedBy,
        v.state === 'consistent' ? undefined : 'syncing'
      ]
        .filter(Boolean)
        .join(' ');
    },
    searchParamId: 'releaseNotes'
  });

  const versions = useProjectSoftwareAppVersions(props.project.idProject!.toString(), props.softwareApp.idSoftwareApp?.toString() || '');
  const deleteSoftwareAppVersion = useDeleteProjectSoftwareAppVersion();

  const latestVersion = props.softwareApp?.latestVersion?.idSoftwareAppVersion;
  const versionSorter = useCallback(
    (a: SoftwareAppVersion, b: SoftwareAppVersion) => {
      if (a.idSoftwareAppVersion === latestVersion) return -1;
      if (b.idSoftwareAppVersion === latestVersion) return 1;
      return SemanticVersion.sorter(a.version, b.version);
    },
    [latestVersion]
  );

  const data = useMemo(() => {
    return (versions.data ?? []).sort(versionSorter);
  }, [versions.data, versionSorter]);

  const columns: TableColumnProps<SoftwareAppVersion>[] = [
    {
      title: 'Version',
      key: 'version',
      ...versionSearch,
      width: 160,
      render: (record: SoftwareAppVersion) => {
        return <SoftwareAppVersionTitle project={props.project} app={props.softwareApp} version={record} bundleVersion={props.bundleVersion} />;
      }
    },
    {
      title: 'Release Notes',
      key: 'releaseNotes',
      ...releaseNotesSearch,
      showSorterTooltip: { title: 'Sort by creation date' },
      sorter: {
        compare: (a: SoftwareAppVersion, b: SoftwareAppVersion): number => {
          return new Date(a.createdAt ?? 0).getTime() - new Date(b.createdAt ?? 0).getTime();
        }
      },
      render: (record: SoftwareAppVersion) => <ComponentVersionListEntry record={record} showAudit={permissions.webui$showComponentDetails} />
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: SoftwareAppVersion) => {
        return (
          // this is a hack to hide the version context menu button
          // in case someone cannot download it, it is very unlikely that other options are possible
          permissions.engineeringSvc$getProjectSoftwareAppVersionTargetDownload && (
            <ExpandableMenu
              textType
              id={`project-app-version-actions-${props.project.idProject}-${props.softwareApp.idSoftwareApp}-${record.idSoftwareAppVersion}`}
            >
              <DownloadContextMenuButton artifact={props.softwareApp} version={record} projectId={props.project.idProject} />
              <SoftwareAppOpenInToolManagerButton selection={{ softwareItem: scopeToProject(props.softwareApp), version: record }} project={props.project} />
              <EditProjectSoftwareAppVersion projectId={props.project.idProject!.toString()} parentApp={props.softwareApp} softwareAppVersion={record} />
              <UnstuckSyncingProjectSoftwareAppVersion projectId={props.project.idProject!.toString()} app={props.softwareApp} version={record} />
              {versions.data && versions.data.length > 1 && permissions.engineeringSvc$deleteProjectSoftwareAppVersion && (
                <ConfirmationButton
                  caption="Delete"
                  title="Deleting version"
                  description="This action cannot be undone."
                  paragraphDescription={
                    <Typography.Paragraph>
                      Are you sure you want to delete version <Typography.Text strong>{record.version}</Typography.Text>?
                    </Typography.Paragraph>
                  }
                  danger
                  disabled={record.idSoftwareAppVersion === props.softwareApp.latestVersion?.idSoftwareAppVersion}
                  onOk={() => {
                    deleteSoftwareAppVersion.mutate([
                      props.project.idProject!.toString(),
                      props.softwareApp.idSoftwareApp?.toString() || '',
                      record.idSoftwareAppVersion?.toString() || ''
                    ]);
                  }}
                />
              )}
            </ExpandableMenu>
          )
        );
      },
      width: 100
    }
  ];

  return (
    <Skeleton loading={versions.isLoading}>
      <Table
        tableLayout="fixed"
        columns={columns}
        rowKey={(record: SoftwareAppVersion) => record.idSoftwareAppVersion?.toString() || ''}
        dataSource={data}
        sticky={{
          offsetHeader: -25 // Sticky position the header below the drawer header.
        }}
      />
    </Skeleton>
  );
};
