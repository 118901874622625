import { useParams } from 'react-router-dom';
import { notificationMatchers } from '../domain/extractor';
import { useNotification } from '../hooks/useNotifications';
import { PageLoading } from '../../../contexts/shared/components/PageLoading';
import { NavigateWithState } from '../../navigation/components/NavigateWithState';

export const NotificationLink = () => {
  const { notificationId } = useParams<{ notificationId: string }>();
  const notification = useNotification(notificationId || '');
  if (notificationId === '') {
    console.warn('invalid notification id');
    return <NavigateWithState to="/404" replace />;
  }
  if (notification.isLoading || notification.isFetching) {
    return <PageLoading />;
  }
  if (!notification.data || notification.isError) {
    console.warn('notification could not be retrieved', notification);
    return <NavigateWithState to="/404" replace />;
  }

  const match = notificationMatchers.find((n) => n.regex.test(notification.data.topic || ''));
  if (match) {
    const link = match?.extractor(notification.data).link;
    return <NavigateWithState to={link.link} replace />;
  }

  // if no match found navigate to home
  // with open notifications panel
  return <NavigateWithState to="/?showNotifications=true" replace />;
};
