import { useMutation } from '@tanstack/react-query';
import { useEngineeringBackend } from '../../../../../../api';
import { ReleaseNotes } from '../../../../../../api/engineering/domain/types';

export function useEnhanceSoftwareItemVersionReleaseNotes() {
  const { backend } = useEngineeringBackend();

  return useMutation<ReleaseNotes, any, string>(async (releaseNotes) => {
    const enhancedReleaseNotes = await backend.enhanceSoftwareItemVersionReleaseNotes(releaseNotes);

    return enhancedReleaseNotes;
  });
}

export default useEnhanceSoftwareItemVersionReleaseNotes;
