import { Card, Flex, Layout } from 'antd';
import { ReactElement } from 'react';
import styled from 'styled-components';
import useElementHeight from '../hooks/useElementHeight';

/*
--------------------------
| PAGE HEADER            |
--------------------------
| COMPONENT HEADER       |
| |--------------------| |
| | FLEX CARD          | |
| |                    | |
| |                    | |
| |                    | |
| |--------------------| |
|------------------------|
*/

// the styled flex container fills up the whole height without header
const FlexContainer = styled(Flex)<{ headerHeight: number }>`
  height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
`;

// the styled card fills up the remaining space between
// component header (not page header!) and bottom
const FlexCard = styled(Card)`
  flex: 1;
  overflow-y: scroll;
  // align with margins of page headers
  // top 0, left, right 10, bottom 10
  margin: 0 10px 10px !important;
`;

export const FlexCardLayout = (props: { header: ReactElement; children: ReactElement }) => {
  const { header, children } = props;

  const headerHeight = useElementHeight('APP_HEADER');

  return (
    <Layout>
      <FlexContainer vertical headerHeight={headerHeight}>
        {header}
        <FlexCard>{children}</FlexCard>
      </FlexContainer>
    </Layout>
  );
};
