import { Row, Col, Space, Divider, Typography, Tag, Tooltip, Skeleton, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ProjectActions } from './ProjectActions';
import { useProjectMembers } from '../hooks';
import { useCurrentUser } from '../../session';
import { useTheme, THEMES } from '../../theme/ThemeProvider';
import ProjectContentWrapper from './ProjectContentWrapper';
import { ProjectAccessRequests } from './ProjectAccessRequests';
import { ProjectSectionTitle } from './ProjectSectionTitle';
import { GrafanaDashboardButton } from './GrafanaDashboardButton';
import { ProjectDescription } from './ProjectDescription';

import type { Project } from '../../../api';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useLatestProjectBundleConfigurationRelease } from '../hooks/useLatestProjectBundleConfigurationRelease';
import { useLatestDeploymentPlanOfTheProject } from '../hooks/useLatestDeploymentPlanOfTheProject';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { useProjectSoftwareApps } from '../../../contexts/ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useProjectSoftwareApps';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';

const { Text } = Typography;

const ReleaseManagerText = styled(Typography.Text)`
  .ant-typography-copy {
    color: ${({ theme }) => theme.colorText};
  }
`;

const StatusContainer = styled.div<{ isLightTheme: boolean }>`
  margin: 12px -24px 24px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  background-color: ${({ theme, isLightTheme }) => (isLightTheme ? theme.primitivesLight.neutral[2] : theme.primitivesDark.neutral[10])};
  border-top: 2px solid ${({ theme, isLightTheme }) => (isLightTheme ? theme.primitivesLight.neutral[6] : theme.primitivesDark.neutral[11])};
  border-bottom: 2px solid ${({ theme, isLightTheme }) => (isLightTheme ? theme.primitivesLight.neutral[6] : theme.primitivesDark.neutral[11])};
`;

const StyledDivider = styled(Divider)<{ isLightTheme: boolean }>`
  &.ant-divider-vertical {
    height: 1.5em;
    border-inline-start: 1px solid ${({ theme, isLightTheme }) => (isLightTheme ? theme.primitivesLight.neutral[6] : theme.primitivesDark.neutral[11])};
  }
`;

const StyledTag = styled(Tag)`
  margin: 0;
`;

const CaptionContainer = styled.div`
  max-width: 100%;
  margin-bottom: 30px;
`;

const KpiSkeleton = styled(Skeleton.Input)`
  &&& {
    width: 100%;
    display: block;
  }
  margin-bottom: 20px;
`;

const CardSkeleton = styled(Skeleton.Node)`
  &&& {
    width: 200px;
    height: 119px;
  }
  margin-right: 15px;
`;

const KpiCard = styled(Card)`
  margin-right: 15px;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const KpiCardsContainer = styled(Row)`
  margin: 15px 0;
`;

const KpiTitle = styled(Typography.Title)`
  &&& {
    text-align: center;
    margin: 0;
  }
`;

export const ProjectOverview = (props: { selectedProject: Project }) => {
  const parsedProjectId = props.selectedProject.idProject.toString();
  const members = useProjectMembers(parsedProjectId);
  const projectSoftwareApps = useProjectSoftwareApps(parsedProjectId);
  const permissions = usePermissions({
    projectId: String(parsedProjectId)
  });
  const latestBundleRelease = useLatestProjectBundleConfigurationRelease(props.selectedProject.idProject);
  const latestDeploymentPlan = useLatestDeploymentPlanOfTheProject(props.selectedProject.idProject);
  const shouldShowLatestConfigRelease = permissions.engineeringSvc$getLatestProjectBundleConfigurationRelease;
  const shouldShowLatestDeploymentPlan = permissions.deploymentSvc$getLatestDeploymentPlanOfTheProject;
  const shouldShowProjectKpis = shouldShowLatestConfigRelease || shouldShowLatestDeploymentPlan;
  const [theme] = useTheme();

  const isLightTheme = theme === THEMES.LIGHT;

  const releaseManagers = useMemo(
    () => (members.data || []).filter((member) => member.roles.some((role) => role.name.toLowerCase().includes('release manager'))),
    [members.data]
  );

  const user = useCurrentUser();
  const myself = members.data?.find((m) => m.member.idMember === user.id);
  const breadcrumbItems = [{ title: props.selectedProject.name }];

  return (
    <ProjectContentWrapper breadcrumbItems={breadcrumbItems}>
      <Row>
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row gutter={[8, 8]}>
              <Col flex="auto">
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  {props.selectedProject.name}
                </Typography.Title>
              </Col>
              <Col flex="32px">
                <ProjectActions project={props.selectedProject} />
              </Col>
            </Row>
            {permissions.webui$showComponentDetails && (
              <StatusContainer isLightTheme={isLightTheme}>
                <Space split={<StyledDivider isLightTheme={isLightTheme} type="vertical" />} wrap>
                  {props.selectedProject.isArchived ? <StyledTag>Archived</StyledTag> : null}
                  <Typography.Text type="secondary">{`Status: ${props.selectedProject.status}`}</Typography.Text>
                  <Typography.Text type="secondary">{`Phase: ${props.selectedProject.phase.name}`}</Typography.Text>
                  <Typography.Text type="secondary">{`Solution Type: ${props.selectedProject.projectSolutionType.name}`}</Typography.Text>
                  {props.selectedProject.referenceProjectRelease && (
                    // eslint-disable-next-line max-len
                    <Typography.Text type="secondary">{`RPL: ${props.selectedProject.referenceProjectRelease.projectName} / ${props.selectedProject.referenceProjectRelease.releaseVersion}`}</Typography.Text>
                  )}
                </Space>
              </StatusContainer>
            )}
          </Space>
        </Col>
      </Row>

      <ProjectAccessRequests project={props.selectedProject} />

      <Row justify="space-between" wrap={false}>
        <Col>
          <CaptionContainer>
            <ProjectSectionTitle level={5}>Description</ProjectSectionTitle>
            <ProjectDescription project={props.selectedProject} />
          </CaptionContainer>
          {shouldShowProjectKpis && (
            <CaptionContainer>
              <ProjectSectionTitle level={5}>Latest updates</ProjectSectionTitle>
              {shouldShowLatestConfigRelease && (
                <Typography.Paragraph>
                  {latestBundleRelease.isLoading && <KpiSkeleton active />}
                  {latestBundleRelease.isSuccess && !latestBundleRelease.data && <Text>Latest release: -</Text>}
                  {latestBundleRelease.data && (
                    <span>
                      <Text>Latest release:</Text> <Text strong>{`${latestBundleRelease.data.config.name} / ${latestBundleRelease.data.release.version}`}</Text>
                      <Text>
                        {latestBundleRelease.data.release.createdAt ? ` - ${formatDateTime(new Date(latestBundleRelease.data.release.createdAt))}` : ''}
                      </Text>
                    </span>
                  )}
                </Typography.Paragraph>
              )}
              {shouldShowLatestDeploymentPlan && (
                <Typography.Paragraph>
                  {latestDeploymentPlan.isLoading && <KpiSkeleton active />}
                  {latestDeploymentPlan.isSuccess && !latestDeploymentPlan.data && <Text>Latest deployment: -</Text>}
                  {latestDeploymentPlan.data && (
                    <span>
                      <Text>Latest deployment:</Text> <Text strong>{latestDeploymentPlan.data.name}</Text>
                      <Text>{latestDeploymentPlan.data.createdAt ? ` - ${formatDateTime(new Date(latestDeploymentPlan.data.createdAt))}` : ''}</Text>
                    </span>
                  )}
                </Typography.Paragraph>
              )}
            </CaptionContainer>
          )}
          {permissions.webui$showDashboards && (
            <CaptionContainer>
              <ProjectSectionTitle level={5}>Analytics</ProjectSectionTitle>
              <GrafanaDashboardButton target={props.selectedProject} />
              <KpiCardsContainer>
                {permissions.engineeringSvc$getProjectSoftwareApps && (
                  <>
                    {projectSoftwareApps.isLoading ? (
                      <CardSkeleton active />
                    ) : (
                      <KpiCard bordered={false}>
                        <Typography.Text>Project specific software</Typography.Text>
                        <KpiTitle>{projectSoftwareApps.data?.length || 0}</KpiTitle>
                      </KpiCard>
                    )}
                  </>
                )}
                {permissions.engineeringSvc$getProjectMembers && (
                  <>
                    {members.isLoading ? (
                      <CardSkeleton active />
                    ) : (
                      <KpiCard bordered={false}>
                        <Typography.Text>Project members</Typography.Text>
                        <KpiTitle>{members.data?.length || 0}</KpiTitle>
                      </KpiCard>
                    )}
                  </>
                )}
              </KpiCardsContainer>
            </CaptionContainer>
          )}
        </Col>
        <Col>
          {!!myself?.roles && (
            <CaptionContainer>
              <ProjectSectionTitle level={5} smallWidth>
                My project role
              </ProjectSectionTitle>
              {myself?.roles.map((r) => (
                <li style={{ marginTop: 16 }}>
                  <Typography.Text>
                    {r.name}
                    <Tooltip title={r.description} trigger={['click', 'hover']}>
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </Typography.Text>
                </li>
              ))}
            </CaptionContainer>
          )}
          {permissions.engineeringSvc$getProjectMembers && (
            <CaptionContainer>
              <ProjectSectionTitle level={5} smallWidth>
                Release managers
              </ProjectSectionTitle>
              {releaseManagers.map((manager) => (
                <li style={{ marginTop: 16 }}>
                  <ReleaseManagerText copyable={{ text: manager.member.email ?? '' }}>{manager.member.name}</ReleaseManagerText>
                </li>
              ))}
            </CaptionContainer>
          )}
        </Col>
      </Row>
    </ProjectContentWrapper>
  );
};
