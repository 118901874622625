export const LINKS = {
  LINK_PACTS_WIKI: 'https://wiki.siemens-energy.com/x/bT1dHQ',
  LINK_PACTS_WIKI_CREATE_DEPLOYMENT: 'https://wiki.siemens-energy.com/x/qD1dHQ',
  LINK_PACTS_WIKI_CREATE_ENVIRONMENT: 'https://wiki.siemens-energy.com/x/AXcqHg',
  LINK_PACTS_WIKI_HOW_TO_RELEASE_NOTES: 'https://wiki.siemens-energy.com/x/SD5dHQ',
  LINK_PACTS_WIKI_TOKEN_GENERATION: 'https://wiki.siemens-energy.com/x/nj1dHQ',
  LINK_PACTS_WIKI_CHECKSUM: 'https://wiki.siemens-energy.com/x/hz1dHQ',
  LINK_PACTS_WIKI_HOW_TO_CREATE_SOFTWARE_RELEASES: 'https://wiki.siemens-energy.com/x/xT1dHQ',
  LINK_PACTS_WIKI_HOW_TO_CREATE_PROJECT_RELEASE: 'https://wiki.siemens-energy.com/x/vj1dHQ',
  LINK_PACTS_WIKI_RELEASE_TOOLKIT: 'https://wiki.siemens-energy.com/x/exLwHg',

  LINK_IMPACTS_WIKI: 'https://wiki.siemens-energy.com/x/laYUF',

  LINK_SERVICE_DESK: 'https://jira.siemens-energy.com/secure/CreateIssue.jspa?pid=20587',

  LINK_TEAMS:
    'https://teams.microsoft.com/l/channel/19%3A390087794e8245279a72bac624751276%40thread.tacv2/User%20Support?groupId=3ef81507-6c93-41a2-8b05-34ee11965e23&tenantId=254ba93e-1f6f-48f3-90e6-e2766664b477',

  API_USER_SATISFACTION_PROD: 'https://kpi.pacts.cloud/grafana/d/ERwrGlq7z/pacts-user-satisfaction?orgId:11',
  API_USER_SATISFACTION_DEV: 'https://kpi.dev.pacts.cloud/grafana/d/ERwrGlq7z/pacts-user-satisfaction?from=now-1y&to=now&orgId:18',

  LINK_PUBLIC_SIGNING_KEY: '/pacts-report-signing-ecdsa-sha512.pem',

  LINK_ICS_PORTAL: 'https://ics-portal.mosaic.siemens-energy.cloud/component-library',

  LINK_SE_IMPRINT: 'https://www.siemens-energy.com/global/en/general/corporate-information.html',
  LINK_SE_PRIVACY_NOTICE: 'https://www.siemens-energy.com/global/en/general/privacy-notice.html',
  LINK_SE_TERMS_OF_USE: 'https://www.siemens-energy.com/global/en/general/terms-of-use.html'
};
