import { Badge, Button, Col, Flex, Row, Tooltip } from 'antd';
import { InfoCircleOutlined, OpenAIOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useStyles } from '../../../../theme';

interface EnhanceReleaseNotesButtonProps {
  text: string;
  isEnhanceConfigurationVersion: boolean;
  onClick: () => void;
  disabled?: boolean;
  showBeta?: boolean;
}

const StyledRow = styled(Row)`
  margin-top: -10px;
`;

const StyledInfoIcon = styled(InfoCircleOutlined)`
  margin-right: 10px;
  color: ${({ theme }) => theme.colorInfo};
  cursor: pointer;
`;

const EnhanceReleaseNotesButton = ({ text, isEnhanceConfigurationVersion, onClick, disabled, showBeta = false }: EnhanceReleaseNotesButtonProps) => {
  const token = useStyles();

  return (
    <StyledRow justify="end">
      <Col>
        <Flex>
          <Tooltip
            title={
              isEnhanceConfigurationVersion
                ? 'You can automatically summarize all the selected software versions’ release notes in a structured manner. It will include also your written text, if any.'
                : 'You can automatically enhance the release notes by improving the text format, improving the wording, fixing typos.'
            }
            placement="top"
          >
            <StyledInfoIcon />
          </Tooltip>
          <Badge count={showBeta ? 'Beta' : undefined} color={token.orange6}>
            <Button type="primary" ghost icon={<OpenAIOutlined />} onClick={onClick} disabled={disabled}>
              {text}
            </Button>
          </Badge>
        </Flex>
      </Col>
    </StyledRow>
  );
};

export default EnhanceReleaseNotesButton;
