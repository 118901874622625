import { Skeleton } from 'antd';
import { SoftwareItemSelection } from '../../../../domain/softwareAppsSelections';
import { SoftwareTableProvider } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/components/SoftwareTable/SoftwareTableProvider';
import { useCommonSoftwareAppsScoped } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useCommonSoftwareApps';
import { useTools } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useTools';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import type { ScopedSoftwareApp } from '../../../ProjectSoftware/ConfigurationDetails/types';
import { Tool } from '../../../../api';

export const CommonSoftwareAppsList = (props: {
  selected: SoftwareItemSelection[];
  initiallySelected: SoftwareItemSelection[];
  showBundleItemsOnly?: boolean;
  onDirty?: (dirty: boolean) => any;
  onSelect: (selected: SoftwareItemSelection[]) => any;
}) => {
  const commonSoftwareApps = useCommonSoftwareAppsScoped();
  const tools = useTools();

  // Include initially selected items as well, since a user might
  // not have access to common apps/tools but shall see the bundle content
  const softwareItems: (ScopedSoftwareApp | Tool)[] = useMemo(() => {
    const ret: (ScopedSoftwareApp | Tool)[] = [];

    if (commonSoftwareApps.data && commonSoftwareApps.data.length > 0) {
      ret.push(...commonSoftwareApps.data);
    }
    if (tools.data && tools.data.length > 0) {
      ret.push(...tools.data);
    }

    ret.push(
      ...props.initiallySelected.map(
        (softwareItemSelection) => ({ ...softwareItemSelection.softwareItem, latestVersion: softwareItemSelection.version }) as ScopedSoftwareApp | Tool
      )
    );
    // Remove duplicates
    return uniqBy(ret, (softwareItem) =>
      (softwareItem as ScopedSoftwareApp).idSoftwareApp
        ? `${(softwareItem as ScopedSoftwareApp).idSoftwareApp}-${(softwareItem as ScopedSoftwareApp).scope}`
        : (softwareItem as Tool).id
    );
  }, [commonSoftwareApps.data, tools.data, props.initiallySelected]);

  const isLoading = commonSoftwareApps.isLoading;
  const isSuccess = commonSoftwareApps.isSuccess;

  return (
    <>
      {isLoading && <Skeleton active />}
      {isSuccess && <SoftwareTableProvider fullHeight softwareItems={softwareItems} {...props} hideCheckboxes />}
    </>
  );
};
