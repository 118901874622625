import { useContext, useEffect, useState } from 'react';
import { Button, Col, Result, Row, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
import { presentSuccess } from '../../../api/shared/successPresenter';
import { useCodeRedeem } from '../hooks/useCodeRedeem';
import { PacTSContext } from '../../../state/store';
import { TokenActions } from '../../../state/actions';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import 'setimmediate';
import { LoginLayout } from './LoginLayout';
import { EccInformation } from './EccInformation';
import { useSession } from '../hooks/useSession';
import { useAuthBackend } from '../../../api/auth/hooks/useAuthBackend';
import styled from 'styled-components';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';
import { NavigateWithState } from '../../navigation/components/NavigateWithState';

const CenteredTitle = styled(Typography.Title)`
  text-align: center;
`;

const Spacer = styled.div<{ height: string }>`
  height: ${({ height }) => height};
`;

const LoginButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
`;

const EccRow = styled(Row)`
  margin-top: 20vh;
`;

export const Login = () => {
  const [state, dispatch] = useContext(PacTSContext);
  const session = useSession(state);
  const location = useLocation();
  const redeem = useCodeRedeem();
  const [redeeming, setRedeeming] = useState(false);
  const { backend } = useAuthBackend();
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [codeParam, setCodeParam] = useSearchParameter('code');
  const [redirectSearchParam] = useSearchParameter('redirect');

  const redirectPath = location.state?.from?.pathname + location.state?.from?.search + location.state?.from?.hash;
  const encodedRedirectParam = redirectPath ? `&redirect=${encodeURIComponent(redirectPath)}` : '';

  const decodedRedirect = redirectSearchParam ? decodeURI(redirectSearchParam) : '/';
  const [redirect, setRedirect] = useState(false);
  const loggedIn = session.state === 'loggedIn';

  // Is in loading state when
  // - redirect loading -> redirect to idp
  // - redeeming -> exchanging code for token
  // - fetching -> loading user information
  // - code param is available -> redeeming did not start yet
  // - logged in -> even if logged in, we make sure loading is visible because redirect is imminent
  const loading = redirectLoading || redeeming || session.fetching || codeParam || loggedIn;

  useEffect(() => {
    if (codeParam && codeParam.length > 0) {
      setRedeeming(true);
      setCodeParam('');
      // if token identified add it to the state
      redeem(codeParam)
        .then((resp) => {
          dispatch({
            type: TokenActions.SET_TOKEN,
            payload: {
              token: resp.accessToken,
              refreshToken: resp.refreshToken
            }
          });
        })
        .catch(() => {
          setRedeeming(false);
        });
    }
  }, [codeParam, setCodeParam, redeem, setRedeeming, dispatch]);

  // If logged in, redirect away from this page
  useEffect(() => {
    if (loggedIn && !redirect) {
      // Redirect delayed in order to have the whole app synchronized the "loggedIn"
      const to = setTimeout(() => {
        presentSuccess('Successfully logged in');
        setRedirect(true);
        setRedeeming(false);
      }, 200);
      return () => {
        clearTimeout(to);
      };
    }
    return () => {
      /* */
    };
  }, [loggedIn, decodedRedirect, setRedirect, redirect]);

  if (redirect) {
    return <NavigateWithState to={decodedRedirect} replace />;
  }

  return (
    <>
      <LoginLayout>
        {loading && <Result icon={<AnimatedLoadingIcon style={{ fontSize: 32 }} />} title="It takes just a moment" subTitle="We are logging you in..." />}
        {!loading && (
          <div>
            <Row align="middle" justify="center">
              <Col>
                <CenteredTitle level={4}>Platform Authoring and Control for Transmission Solutions</CenteredTitle>
                <Spacer height="8vh" />
                <CenteredTitle level={5}>Login with Siemens Energy credentials.</CenteredTitle>
              </Col>
            </Row>
            <Spacer height="1vh" />
            <Row align="middle" justify="center">
              <Col>
                <LoginButton
                  type="primary"
                  size="large"
                  loading={redirectLoading}
                  onClick={() => {
                    setRedirectLoading(true);
                    setImmediate(() => {
                      const loginUrl = backend.getLoginUri({ client: 'pacts', provider: 'azuread', redirect: encodedRedirectParam });
                      window.location.href = loginUrl;
                    });
                  }}
                >
                  Login
                </LoginButton>
              </Col>
            </Row>
            <EccRow justify="center">
              <Col>
                <EccInformation />
              </Col>
            </EccRow>
          </div>
        )}
      </LoginLayout>
    </>
  );
};
