import { Space, TableColumnProps, TableProps, Tooltip } from 'antd';
import { SoftwareItemSelection } from '../../../../../../../domain/softwareAppsSelections';
import { Comparator } from '../../../../../../../domain/extensions/comparison';
import { useTableSearch } from '../../../../../../shared/components/TableSearch';
import { ScopedSoftwareApp } from '../../../../types';
import { TableVersionHelp } from '../../../../../../shared/components/TableVersionHelp';
import { Project, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../../../api/engineering/domain/types';
import { useScrollInto } from '../../../../../../navigation/hooks/useScrollInto';
import React, { useRef, useState } from 'react';
import { softwareItemHash } from '../../../../utils/softwareItemHash';
import Table from '../../../../../../shared/components/Table/Table';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import Segmented from '../../../../../../shared/components/Segmented/Segmented';
import * as Styled from './SoftwareTable.styled';
import { useSearchParameter } from '../../../../../../navigation/hooks/useSearchParameter';
import styled from 'styled-components';
import { ProjectSoftwareAppActionsMenu } from '../../../SoftwareItemActionsMenu/ProjectSoftwareAppActionsMenu';
import { CommonSoftwareAppActionsMenu } from '../../../SoftwareItemActionsMenu/CommonSoftwareAppActionsMenu';
import { ProjectSoftwareAppVersionDropdown } from '../../../SoftwareItemVersionSelection/ProjectSoftwareAppVersionDropdown';
import { CommonSoftwareAppVersionDropdown } from '../../../SoftwareItemVersionSelection/CommonSoftwareAppVersionDropdown';
import { SoftwareAppDetails } from '../../../SoftwareItemDetails/SoftwareAppDetails';
import { SoftwareAppVersions } from '../../../SoftwareItemVersions/SoftwareAppVersions';
import { ToolVersionDropdown } from '../../../SoftwareItemVersionSelection/ToolVersionDropdown';
import { Text } from '../../../../../../shared/base/Components.styled';
import { ToolActionsMenu } from '../../../SoftwareItemActionsMenu/ToolActionsMenu';
import { ToolVersions } from '../../../SoftwareItemVersions/ToolVersions';
import { ToolDetails } from '../../../SoftwareItemDetails/ToolDetails';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';
import useElementHeight from '../../../../../../../contexts/layout/hooks/useElementHeight';
import { TABLE_PAGINATION_WRAPPER_HEIGHT } from '../../../../../../../contexts/ProjectSoftware/Configurations/constants';

export type SelectionMap = {
  [key: string]: SoftwareItemSelection;
};

export const getSelectionMapKey = (softwareItem: ScopedSoftwareApp | Tool) => {
  return (softwareItem as ScopedSoftwareApp).idSoftwareApp
    ? `${(softwareItem as ScopedSoftwareApp).idSoftwareApp.toString()}-${(softwareItem as ScopedSoftwareApp).scope}`
    : (softwareItem as Tool).id.toString();
};

type TableContent = ScopedSoftwareApp | Tool;

interface StyledTableProps<T> extends TableProps<T> {
  heightOffset: number | null;
}

export const StyledTable = styled(Table).withConfig({
  shouldForwardProp: (prop) => prop !== 'heightOffset'
})<StyledTableProps<TableContent>>`
  .ant-table {
    ${({ heightOffset }) => (!!heightOffset ? `min-height: calc(100vh - ${heightOffset}px);` : '')}
    background: transparent;
  }
`;

interface SoftwareTableProps {
  tabOptions: SegmentedLabeledOption[];
  activeTabKey: string;
  onActiveTabChange: ((value: SegmentedValue) => void) | undefined;
  project?: Project;
  updateSelectedSoftwareAppVersion: (sa: ScopedSoftwareApp | Tool, sv: SoftwareAppVersion | ToolVersion) => void;
  selectionMap: SelectionMap;
  bundleSelectionMap: SelectionMap;
  softwareItems: (ScopedSoftwareApp | Tool)[];
  hideCheckboxes?: boolean;
  rowSelection: () => {
    selectedRowKeys: string[];
    onChange: (selectedRowKeys: any, selectedRows: (ScopedSoftwareApp | Tool)[]) => void;
    getCheckboxProps: (record: any) => {
      name: any;
    };
  };
  readonly?: boolean;
  fullHeight?: boolean;
}

export const SoftwareTable: React.FC<SoftwareTableProps> = React.memo((props) => {
  const nameSearch = useTableSearch({ searchValueProvider: 'name', searchParamId: `name` });
  const [tablePage, setTablePage] = useSearchParameter(`p_${props.activeTabKey}`, '1');
  const tablePageNumber = parseInt(tablePage || '1');
  const [pageSize, setPageSize] = useSearchParameter(`ps`, '10');
  const [openDrawerVersionIdParam, setOpenDrawerVersionIdParam] = useSearchParameter('open_versions');
  const [openDrawerDetailsIdParam, setOpenDrawerDetailsIdParam] = useSearchParameter('open_details');
  const detailsDrawerApp =
    props.softwareItems?.find((softwareItem) => openDrawerDetailsIdParam && softwareItemHash(softwareItem) === openDrawerDetailsIdParam) || null;
  const versionsDrawerApp = props.softwareItems?.find((softwareItem) => softwareItemHash(softwareItem) === openDrawerVersionIdParam) || null;
  const pageSizeNumber = parseInt(pageSize || '10');
  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    softwareAppId: (versionsDrawerApp as ScopedSoftwareApp)?.idSoftwareApp
      ? (versionsDrawerApp as ScopedSoftwareApp)?.idSoftwareApp?.toString()
      : (versionsDrawerApp as Tool)?.id?.toString()
  });

  const [tableRef, setTableRef] = useState<any>();

  const scrollHash = location.hash?.replace('#', '.');
  useScrollInto(scrollHash, tableRef, tableRef);

  const segmented = useRef<HTMLDivElement>(null);
  const headerHeight = useElementHeight('APP_HEADER');
  const pageContentHeaderHeight = useElementHeight('PAGE_CONTENT_HEADER');
  const tableHeightOffset = props.fullHeight
    ? headerHeight + pageContentHeaderHeight + (segmented.current?.clientHeight || 0) + TABLE_PAGINATION_WRAPPER_HEIGHT
    : null;

  const handleOpenVersionDrawer = (softwareItem: ScopedSoftwareApp | Tool) => {
    setOpenDrawerVersionIdParam(softwareItemHash(softwareItem));
  };

  const handleCloseVersionDrawer = () => {
    setOpenDrawerVersionIdParam('');
  };

  const handleOpenDetailsDrawer = (softwareItem: ScopedSoftwareApp | Tool) => {
    setOpenDrawerDetailsIdParam(softwareItemHash(softwareItem));
  };

  const handleCloseDetailsDrawer = () => {
    setOpenDrawerDetailsIdParam('');
  };

  const columns: TableColumnProps<TableContent>[] = [
    {
      title: <Text strong>Software</Text>,
      key: 'name',
      fixed: 'left',
      ...nameSearch,
      sorter: (a: TableContent, b: TableContent) => Comparator.lexicographicalComparison(a.name, b.name),
      render: (app: TableContent) => (
        <Tooltip placement="bottomLeft" mouseEnterDelay={1} title={app.description}>
          {app.name}
        </Tooltip>
      )
    },
    {
      title: <TableVersionHelp />,
      key: 'version',
      render: (softwareItem: TableContent) => {
        const selectedVersion = props.selectionMap[getSelectionMapKey(softwareItem)]?.version;
        const bundleVersion = props.bundleSelectionMap[getSelectionMapKey(softwareItem)]?.version;

        return (
          <>
            {(softwareItem as ScopedSoftwareApp).scope === 'project' && props.project && (
              <ProjectSoftwareAppVersionDropdown
                project={props.project}
                softwareAppId={(softwareItem as ScopedSoftwareApp).idSoftwareApp!.toString()}
                selectedVersion={selectedVersion as SoftwareAppVersion}
                initiallySelectedVersion={bundleVersion as SoftwareAppVersion}
                onSelected={(version) => {
                  props.updateSelectedSoftwareAppVersion(softwareItem, version);
                }}
                readonly={props.readonly}
              />
            )}
            {(softwareItem as ScopedSoftwareApp).scope === 'common' && (
              <CommonSoftwareAppVersionDropdown
                softwareAppId={(softwareItem as ScopedSoftwareApp).idSoftwareApp!.toString()}
                initiallySelectedVersion={bundleVersion as SoftwareAppVersion}
                selectedVersion={selectedVersion as SoftwareAppVersion}
                onSelected={(version) => {
                  props.updateSelectedSoftwareAppVersion(softwareItem, version);
                }}
                readonly={props.readonly}
              />
            )}
            {(softwareItem as Tool).id !== undefined && (
              <ToolVersionDropdown
                engineeringToolId={(softwareItem as Tool).id!.toString()}
                selectedVersion={selectedVersion as ToolVersion}
                initiallySelectedVersion={bundleVersion as ToolVersion}
                onSelected={(version) => {
                  props.updateSelectedSoftwareAppVersion(softwareItem, version);
                }}
                readonly={props.readonly}
              />
            )}
          </>
        );
      },
      width: '40%'
    },
    {
      title: <Text strong>Actions</Text>,
      key: 'actions',
      fixed: 'right',
      align: 'center',
      render: (softwareItem: TableContent) => {
        let currentSelection: SoftwareItemSelection | undefined;

        if (Object.keys(props.selectionMap).includes(getSelectionMapKey(softwareItem))) {
          const s = props.selectionMap[getSelectionMapKey(softwareItem)];
          currentSelection = { softwareItem, version: s.version };
        }

        return (
          <Space>
            {(softwareItem as ScopedSoftwareApp).scope === 'project' && props.project && (
              <ProjectSoftwareAppActionsMenu
                onOpenDetailsDrawer={handleOpenDetailsDrawer}
                onOpenVersionDrawer={handleOpenVersionDrawer}
                currentSelection={currentSelection}
                swa={softwareItem as ScopedSoftwareApp}
                project={props.project}
              />
            )}
            {(softwareItem as ScopedSoftwareApp).scope === 'common' && (
              <CommonSoftwareAppActionsMenu
                onOpenDetailsDrawer={handleOpenDetailsDrawer}
                onOpenVersionDrawer={handleOpenVersionDrawer}
                currentSelection={currentSelection}
                swa={softwareItem as ScopedSoftwareApp}
                project={props.project}
              />
            )}
            {(softwareItem as Tool).id !== undefined && (
              <ToolActionsMenu
                onOpenDetailsDrawer={handleOpenDetailsDrawer}
                onOpenVersionDrawer={handleOpenVersionDrawer}
                tool={softwareItem as Tool}
                project={props.project}
                selection={currentSelection}
              />
            )}
          </Space>
        );
      },
      width: '10%'
    }
  ];

  const hasSegmented = props.tabOptions.length > 2;

  return (
    <>
      <div ref={setTableRef}>
        {hasSegmented ? (
          <Styled.SegmentedWrapper ref={segmented}>
            <Segmented value={props.activeTabKey} options={props.tabOptions} onChange={props.onActiveTabChange} />
          </Styled.SegmentedWrapper>
        ) : null}
        <StyledTable
          heightOffset={tableHeightOffset}
          rowSelection={
            props.hideCheckboxes || props.readonly
              ? undefined
              : {
                  type: 'checkbox',
                  ...props.rowSelection(),
                  columnWidth: 48
                }
          }
          sticky={{
            offsetHeader: segmented.current?.clientHeight
          }}
          columns={columns}
          scroll={{ x: true }}
          rowKey={(record: TableContent) => getSelectionMapKey(record)}
          rowClassName={softwareItemHash}
          dataSource={props.softwareItems.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))}
          pagination={{
            current: tablePageNumber,
            onChange: (p) => {
              setTablePage(p.toString());
            },
            showSizeChanger: true,
            defaultPageSize: pageSizeNumber,
            pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: (c, s) => {
              setPageSize(s.toString());
            }
          }}
        />
      </div>
      {permissions.webui$showComponentDetails && (detailsDrawerApp as ScopedSoftwareApp)?.idSoftwareApp && (
        <SoftwareAppDetails app={detailsDrawerApp as ScopedSoftwareApp} project={props.project} onClose={handleCloseDetailsDrawer} open={!!detailsDrawerApp} />
      )}
      {permissions.webui$showComponentDetails && (detailsDrawerApp as Tool)?.id && (
        <ToolDetails tool={detailsDrawerApp as Tool} onClose={handleCloseDetailsDrawer} open={!!detailsDrawerApp} />
      )}
      {(versionsDrawerApp as ScopedSoftwareApp)?.idSoftwareApp && (
        <SoftwareAppVersions
          app={versionsDrawerApp as ScopedSoftwareApp}
          project={props.project}
          onClose={handleCloseVersionDrawer}
          open={!!versionsDrawerApp}
          bundleVersion={!!versionsDrawerApp ? (props.bundleSelectionMap[getSelectionMapKey(versionsDrawerApp)]?.version as SoftwareAppVersion) : undefined}
        />
      )}
      {(versionsDrawerApp as Tool)?.id && (
        <ToolVersions
          tool={versionsDrawerApp as Tool}
          onClose={handleCloseVersionDrawer}
          open={!!versionsDrawerApp}
          bundleVersion={!!versionsDrawerApp ? (props.bundleSelectionMap[getSelectionMapKey(versionsDrawerApp)]?.version as ToolVersion) : undefined}
        />
      )}
    </>
  );
});
