import { useEffect, useMemo } from 'react';
import { ProjectCompareSelect } from './ProjectSelect';
import useBundles from '../../bundles/hooks/useBundles';
import useConfigurations from '../../ProjectSoftware/Configurations/hooks/useConfigurations';

type ProjectBundleConfigurationSelectProps = {
  projectId: string;
  configurationId: number | undefined;
  defaultConfigId: number | undefined;
  bundleType?: 'apps' | 'tools';
  onSelectChange: (configurationId: number | undefined, bundleId: string) => void;
};

const BUNDLE_LABEL = 'Project Software';

export const ProjectBundleConfigurationSelect: React.FC<ProjectBundleConfigurationSelectProps> = ({ projectId, configurationId, onSelectChange }) => {
  const bundles = useBundles(projectId);
  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes(BUNDLE_LABEL));
  const bundleId = usedBundle?.idBundle?.toString() || '-1';
  const bundleConfigurations = useConfigurations(projectId, bundleId);
  const selectItems = useMemo(() => {
    if (!bundleConfigurations.data) return [];

    return bundleConfigurations.data.map((config) => ({ value: config.idBundleConfiguration, label: config.name }));
  }, [bundleConfigurations.data]);
  const isOptionExisting = selectItems.some((item) => Number(item.value) === Number(configurationId));

  // As soon as the project changes we have to preselect a configuration
  useEffect(() => {
    const isConfigExisting = selectItems.some((item) => Number(item.value) === Number(configurationId));
    const newDefaultConfigId = selectItems?.[0]?.value;

    if (isConfigExisting || !selectItems.length) {
      return;
    }

    onSelectChange(newDefaultConfigId, bundleId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectItems]);

  return (
    <ProjectCompareSelect
      showSearch
      // We're passing empty string in order to not show the id in case option does not exist
      value={isOptionExisting ? configurationId : ('' as unknown as number)}
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      options={selectItems}
      loading={bundleConfigurations.isLoading}
      onChange={(configId) => onSelectChange(configId, bundleId)}
    />
  );
};
