import { useEffect } from 'react';

interface RedirectProps {
  to: string;
  shouldPreserveSearchParams?: boolean;
}

/**
 * A React component that performs a client-side redirect.
 *
 * This component automatically redirects the user to the specified `to` URL
 * when it is mounted. If `shouldPreserveSearchParams` is set to `true`,
 * the current search parameters will be appended to the destination URL.
 *
 * @example
 * ```tsx
 * <Redirect to="https://example.com" />
 * ```
 *
 * @example
 * ```tsx
 * <Redirect to="/dashboard" shouldPreserveSearchParams={true} />
 * ```
 *
 * @param {RedirectProps} props - The component props.
 * @returns {null} This component does not render anything.
 */
export const Redirect: React.FC<RedirectProps> = ({ to, shouldPreserveSearchParams = false }) => {
  useEffect(() => {
    let url = to;
    if (shouldPreserveSearchParams) {
      const currentParams = window.location.search;
      url += currentParams;
    }
    window.location.replace(url);
  }, [to, shouldPreserveSearchParams]);

  return null;
};
