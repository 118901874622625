import { useLocation } from 'react-router-dom';
import { useRestrictedUseCasePermissions } from '../../contexts/session';

export const useRestrictedUseCaseRedirect = () => {
  const permissions = useRestrictedUseCasePermissions();
  const location = useLocation();

  // if not on home, ignore
  if (location.pathname !== '/') return;

  const hasOnlyReadAppsOrToolsPermissions = !permissions.hasProjectsPermission && permissions.hasSoftwareHubPermissions;

  if (hasOnlyReadAppsOrToolsPermissions) {
    return '/software-hub';
  }
};
