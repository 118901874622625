import { useParams } from 'react-router-dom';
import { PacTSRouteProto } from '../PacTSRoutes';
import { usePermissions } from '../../contexts/session';


/**
 * Hook to determine if a given route is accessible based on the user's permissions.
 *
 * @param {PacTSRouteProto} route - The route object containing information about the route and required permissions.
 * @returns {boolean} - Returns `true` if the route is accessible, otherwise `false`.
 */
export const useRouteAccessible = (route: PacTSRouteProto): boolean => {
  const params = useParams();

  // in case of "create" we need to map it to a valid (bunt arbitrary) envId since "create" is invalid
  let envIdParam = params.envId;
  if (envIdParam === 'create') {
    envIdParam = '28ae91ba-7962-4a94-a747-c56e507a8aae';
  }

  const permissions = usePermissions({
    projectId: params.projectid,
    idConfiguration: params.configurationId,
    // don't map bundle param because "project-software" is used as bundle reference in path which cannot be mapped to config id
    // idBundle: params.bundle,
    sourceId: params.sourceId,
    revisionId: params.revision,
    environmentId: envIdParam
  });

  if (route.public) return true;
  if (route.requiredPermissions.length < 1) return true;

  const allowed = route.requiredPermissions.every((orGroup) => {
    return orGroup.some((p) => {
      if (p.endsWith('$specific')) {
        // cast to proper $specific signature by picking a random specific key
        // set "withWildcards" to true to allow partial parameter restrictions
        return (permissions[p] as (typeof permissions)['engineeringSvc$getTool$specific'])({ withWildcards: true }).length > 0;
      }
      return permissions[p] === true;
    });
  });

  return allowed;
};
