import React from 'react';
import styled from 'styled-components';

interface HideContainerProps {
  hidden?: boolean;
  children?: React.ReactNode;
}

const HiddenWrapper = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : undefined)};
`;

/**
 * Wrapper to control the display status of the children
 * If hidden is set to true, display: hidden will be applied
 * otherwise display: inherit
 * @param {HideContainerProps} props
 * @returns {any}
 */
export const HideContainer = (props: HideContainerProps) => <HiddenWrapper {...props} />;
