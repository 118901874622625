import { OneToOneOutlined, ControlOutlined } from '@ant-design/icons';
import { ExpandableMenuItem } from '../../../../shared/components/ExpandableMenuItem';

type ComparisonViewSwitchProps = {
  state: boolean;
  onChange: (state: boolean) => any;
};

export const SwitchToCompareViewButton = (props: ComparisonViewSwitchProps) => {
  return (
    <ExpandableMenuItem icon={props.state ? <ControlOutlined /> : <OneToOneOutlined />} onClick={() => props.onChange(!props.state)}>
      {props.state ? 'Switch to Control View' : 'Compare releases'}
    </ExpandableMenuItem>
  );
};

export default SwitchToCompareViewButton;
