import { useQuery } from '@tanstack/react-query';
import { BundleReleaseLite } from '../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../api/engineering/hooks/useEngineeringBackend';
import { SemanticVersion } from '../../../../domain';
import { usePermissions } from '../../../session/hooks/usePermissions';

export function useConfigurationVersions(projectId: string, bundleId: string, configurationId: string | undefined = '0') {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId, idBundle: bundleId, idConfiguration: configurationId });

  const parsedProjectId = parseInt(projectId, 10);
  const parsedBundleId = parseInt(bundleId, 10);
  const parsedConfigId = parseInt(configurationId, 10);

  const permissionsList =
    permissions.engineeringSvc$getProjectBundleConfigurationReleases ?? permissions.engineeringSvc$getProjectBundleConfigurationRelease$specific();
  const specificPermissions = !permissionsList ? permissions.engineeringSvc$getProjectBundleConfigurationRelease$specific() : [];

  const enabled =
    parsedBundleId > 0 && parsedConfigId > 0 && parsedProjectId > 0 && Boolean(configurationId) && (permissionsList || specificPermissions.length > 0);

  return useQuery<BundleReleaseLite[], [string, string, string, string]>(
    ['bundleConfigurationVersions', projectId, bundleId, configurationId],
    async (): Promise<BundleReleaseLite[]> => {
      let data: BundleReleaseLite[] = [];

      if (permissionsList) {
        data = await backend.queryBundleConfigurationVersions(projectId, bundleId, configurationId);
      } else {
        data = await Promise.all(
          specificPermissions.map((s) => backend.queryBundleConfigurationVersion(s.projectId, s.idBundle, s.idConfiguration, s.idRelease))
        );
      }

      return data.sort((a, b) => SemanticVersion.sorter(a.version!, b.version!));
    },
    {
      enabled
    }
  );
}

export default useConfigurationVersions;
