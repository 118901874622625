import { useInfiniteQuery } from '@tanstack/react-query';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ProjectsResponse, usePactsFormationBackend } from '../../../api';
import { useEffect } from 'react';
import type { Dayjs } from 'dayjs';

export const useProjectsResources = (startTimestamp: Dayjs, endTimestamp: Dayjs) => {
  const permissions = usePermissions();
  const { backend } = usePactsFormationBackend();
  const startTimestampMilliseconds = startTimestamp.valueOf();
  const endTimestampMilliseconds = endTimestamp.valueOf();

  return useInfiniteQuery<ProjectsResponse>(
    ['projectsResources', startTimestampMilliseconds, endTimestampMilliseconds],
    async ({ pageParam = undefined }) => {
      return backend.getAllSortedProjects(pageParam, startTimestampMilliseconds, endTimestampMilliseconds);
    },
    {
      enabled: permissions.pactsFormationSvc$unrestrictedAdministration,
      getNextPageParam: (p) => {
        return p.nextPaginationToken;
      }
    }
  );
};

export const useAllProjectsResources = (startTimestamp: Dayjs, endTimestamp: Dayjs) => {
  const projects = useProjectsResources(startTimestamp, endTimestamp);

  useEffect(() => {
    if (projects.hasNextPage && !projects.isFetchingNextPage) {
      projects.fetchNextPage();
    }
  }, [projects]);

  const sourceData = projects.data?.pages.map((p) => p.projects).flat();
  return {
    ...projects,
    data: sourceData
  };
};
