import React, { useCallback, useMemo } from 'react';
import { Empty, Skeleton, Space } from 'antd';
import styled from 'styled-components';

import Segmented from '../../shared/components/Segmented/Segmented';
import Table from '../../shared/components/Table/Table';
import { ComparisonResult } from '../../comparison';
import { Comparator } from '../../../domain/extensions/comparison';

import type { TableColumnsType } from 'antd';
import type { BundleRelease, Project, Tool } from '../../../api/engineering/domain/types';
import { SegmentedValue } from 'antd/es/segmented';
import { uniqBy } from 'lodash';
import {
  softwareAppVersionComparisonResultValue,
  SoftwareCompareVersionResult
} from '../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/components/SoftwareCompareVersionResult/SoftwareCompareVersionResult';
import { ScopedSoftwareApp, SoftwareAppScope } from '../../ProjectSoftware/ConfigurationDetails/types';
import useConfigurationVersion from '../../ProjectSoftware/ConfigurationDetails/hooks/useConfigurationVersion';
import useConfiguration from '../../ProjectSoftware/Configurations/components/AddEditConfiguration/hooks/useConfiguration';
import { SegmentedWrapper } from '../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/components/SoftwareTable/SoftwareTable.styled';
import { MetricOnVisible } from '../../../contexts/metrics/components/MetricOnVisible';
import { MetricViewIds } from '../../../contexts/metrics/constants';
import { projectToTags } from '../../../contexts/metrics/utils/mapping';

export const StyledTable = styled(Table)`
  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
    height: calc(100vh - 470px) !important;
  }
` as typeof Table;

const StyledSkeletonWrapper = styled(Space)`
  margin: 5px 0 !important;
`;

const StyledSkeletonNode = styled(Skeleton.Node)`
  width: 160px !important;
  height: 45px !important;
`;

type ProjectSoftwareAppsCompareTableProps = {
  project: Project;
  projectIdA: string;
  projectIdB: string;
  bundleIdA: string;
  bundleIdB: string;
  configIdA: string;
  configIdB: string;
  releaseIdA: string;
  releaseIdB: string;
  activeTabKey: string;
  setActiveTabKey: (tab: string) => void;
};

export const ALL_CATEGORIES_LABEL = 'All Categories';

function getAllApps(bundleRelease: BundleRelease | undefined): (ScopedSoftwareApp | Tool)[] {
  if (!bundleRelease) return [];

  return [
    ...(bundleRelease.softwareApps?.map((app) => ({ ...app, scope: 'common' as SoftwareAppScope })) || []),
    ...(bundleRelease.engineeringTools?.map((app) => ({ ...app })) || []),
    ...(bundleRelease.projectSoftwareApps?.map((app) => ({ ...app, scope: 'project' as SoftwareAppScope })) || [])
  ];
}

function findApp(app: ScopedSoftwareApp | Tool, selection?: (ScopedSoftwareApp | Tool)[]) {
  return selection?.find((swaA) =>
    !!(app as ScopedSoftwareApp).idSoftwareApp
      ? app.name === swaA.name && (app as ScopedSoftwareApp).scope === (swaA as ScopedSoftwareApp).scope
      : app.name === swaA.name
  );
}

function getCategoryName(softwareItem: ScopedSoftwareApp | Tool) {
  return (softwareItem as Tool).category
    ? 'Tools'
    : (softwareItem as ScopedSoftwareApp).categories && (softwareItem as ScopedSoftwareApp).categories.length > 0
      ? (softwareItem as ScopedSoftwareApp).categories[0].name
      : 'unknown';
}

export const ProjectSoftwareAppsCompareTable: React.FC<ProjectSoftwareAppsCompareTableProps> = (props) => {
  const { projectIdA, projectIdB, bundleIdA, bundleIdB, configIdA, configIdB, releaseIdA, releaseIdB, activeTabKey, setActiveTabKey } = props;
  const configA = useConfiguration(projectIdA, bundleIdA, configIdA);
  const configB = useConfiguration(projectIdB, bundleIdB, configIdB);
  const bundleReleaseA = useConfigurationVersion(projectIdA, bundleIdA, configIdA, releaseIdA, true);
  const bundleReleaseB = useConfigurationVersion(projectIdB, bundleIdB, configIdB, releaseIdB, true);

  const versionsNotSelected = !props.releaseIdA || !props.releaseIdB;
  const isFetching = versionsNotSelected
    ? false
    : bundleReleaseA.isFetching ||
      bundleReleaseB.isFetching ||
      configA.isLoading ||
      configB.isLoading ||
      !props.bundleIdA ||
      !props.bundleIdB ||
      !props.configIdA ||
      !props.configIdB ||
      !props.projectIdA ||
      !props.projectIdB;
  const loading = isFetching && !!configIdA && !!configIdB && !versionsNotSelected;

  const appsA = useMemo(() => {
    return getAllApps(bundleReleaseA.data);
  }, [bundleReleaseA.data]);

  const appsB = useMemo(() => {
    return getAllApps(bundleReleaseB.data);
  }, [bundleReleaseB.data]);

  const handleActiveTabChange = (key: SegmentedValue) => {
    const keyStr = key.toString();

    setActiveTabKey(keyStr);
  };

  const statusSorter = useCallback(
    (a: ScopedSoftwareApp | Tool, b: ScopedSoftwareApp | Tool) => {
      const aaApp = findApp(a, appsA);
      const abApp = findApp(a, appsB);
      const aComparisonResult = softwareAppVersionComparisonResultValue(aaApp?.latestVersion, abApp?.latestVersion);

      const baApp = findApp(b, appsA);
      const bbApp = findApp(b, appsB);
      const bComparisonResult = softwareAppVersionComparisonResultValue(baApp?.latestVersion, bbApp?.latestVersion);

      return aComparisonResult - bComparisonResult;
    },
    [appsA, appsB]
  );

  const tabsCategories = useMemo(
    () =>
      uniqBy([...appsA, ...appsB], (app) => app.name).reduce(
        (rv, x) => {
          const category = getCategoryName(x);
          (rv[ALL_CATEGORIES_LABEL] = rv[ALL_CATEGORIES_LABEL] || []).push(x);
          (rv[category] = rv[category] || []).push(x);
          return rv;
        },
        {} as Record<string, any>
      ),
    [appsA, appsB]
  );

  const filteredApps = useMemo(() => {
    const mergedApps = [...appsA, ...appsB];

    appsB.forEach((appB) => {
      if (
        appsA.findIndex((appA) =>
          !!(appA as ScopedSoftwareApp | undefined)?.idSoftwareApp
            ? !(
                (appA as ScopedSoftwareApp | undefined)?.idSoftwareApp === (appB as ScopedSoftwareApp | undefined)?.idSoftwareApp &&
                (appA as ScopedSoftwareApp | undefined)?.scope === (appB as ScopedSoftwareApp | undefined)?.scope &&
                appA.latestVersion?.version === appB.latestVersion?.version
              )
            : !((appA as Tool | undefined)?.id === (appB as Tool | undefined)?.id && !(appB as Tool | undefined)?.id)
        )
      ) {
        mergedApps.push(appB);
      }
    });

    Object.keys(tabsCategories).forEach((key) => {
      tabsCategories[key].sort(statusSorter).reverse();
    });

    const groupedSoftwareApps = {
      ...tabsCategories
    };
    return groupedSoftwareApps;
  }, [appsA, appsB, statusSorter, tabsCategories]);

  const columns: TableColumnsType<ScopedSoftwareApp | Tool> = [
    {
      title: 'Software',
      key: 'name',
      width: '30%',
      sorter: (a: ScopedSoftwareApp | Tool, b: ScopedSoftwareApp | Tool) => Comparator.lexicographicalComparison(a.name, b.name),
      render: (swa: ScopedSoftwareApp) => {
        return swa.name;
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: '20%',
      filters: [
        {
          text: 'Hide Equal Items',
          value: ComparisonResult.EQUAL
        }
      ],
      onFilter: (_, record: ScopedSoftwareApp | Tool) => {
        const aApp = findApp(record, appsB);
        const bApp = findApp(record, appsA);

        // Check if they one record is app and other is tool
        if (
          ((aApp as ScopedSoftwareApp | undefined)?.idSoftwareApp && !(bApp as ScopedSoftwareApp | undefined)?.idSoftwareApp) ||
          ((bApp as ScopedSoftwareApp | undefined)?.idSoftwareApp && !(aApp as ScopedSoftwareApp | undefined)?.idSoftwareApp)
        ) {
          return true;
        }

        if (
          !!(aApp as ScopedSoftwareApp | undefined)?.idSoftwareApp
            ? (aApp as ScopedSoftwareApp | undefined)?.latestVersion?.idSoftwareAppVersion ===
              (bApp as ScopedSoftwareApp | undefined)?.latestVersion?.idSoftwareAppVersion
            : (aApp as Tool | undefined)?.latestVersion?.idToolVersion === (bApp as Tool | undefined)?.latestVersion?.idToolVersion
        ) {
          return false;
        }
        return true;
      },
      sortDirections: ['descend', 'ascend'],
      sorter: statusSorter,
      render: (swa: ScopedSoftwareApp | Tool) => {
        const aApp = findApp(swa, appsB);
        const bApp = findApp(swa, appsA);
        return <SoftwareCompareVersionResult a={aApp?.latestVersion} b={bApp?.latestVersion} />;
      }
    },
    {
      title: `${configA.data?.name || ''}${!!releaseIdA ? ` - ${bundleReleaseA.data?.version || ''}` : ''}`,
      key: 'version-A',
      render: (swa: ScopedSoftwareApp | Tool) => {
        const bApp = findApp(swa, appsA);
        return bApp?.latestVersion?.version || '-';
      },
      width: '25%'
    },
    {
      title: `${configB.data?.name || ''}${!!releaseIdB ? ` - ${bundleReleaseB.data?.version || ''}` : ''}`,
      key: 'version-B',
      render: (swa: ScopedSoftwareApp | Tool) => {
        const aApp = findApp(swa, appsB);
        return aApp?.latestVersion?.version || '-';
      },
      width: '25%'
    }
  ];

  const tabOptions = Object.keys(filteredApps);
  const sortedTabOptions = tabOptions.sort((a, b) => Comparator.lexicographicalComparison(a, b));
  const hasSegmented = tabOptions.length > 2;
  const segmentedHeight = hasSegmented ? 54 : 0;

  return (
    <>
      {loading ? (
        <StyledSkeletonWrapper>
          <StyledSkeletonNode />
          <StyledSkeletonNode />
          <StyledSkeletonNode />
          <StyledSkeletonNode />
        </StyledSkeletonWrapper>
      ) : hasSegmented ? (
        <SegmentedWrapper>
          <Segmented value={activeTabKey || ''} options={sortedTabOptions} onChange={handleActiveTabChange} />
        </SegmentedWrapper>
      ) : null}
      <MetricOnVisible view={MetricViewIds.appsCompareList} payload={props.project ? projectToTags(props.project) : undefined} />
      <StyledTable
        sticky={{
          offsetHeader: segmentedHeight
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={versionsNotSelected ? 'Select the version in order to compare release software.' : 'No data'}
            />
          )
        }}
        loading={loading}
        columns={columns}
        rowKey={(record: ScopedSoftwareApp | Tool) =>
          !!(record as ScopedSoftwareApp).idSoftwareApp
            ? `${(record as ScopedSoftwareApp).idSoftwareApp}-${(record as ScopedSoftwareApp).scope}`
            : (record as Tool).id?.toString() || ''
        }
        dataSource={(configIdB && configIdA && !versionsNotSelected) || isFetching ? filteredApps[activeTabKey || ALL_CATEGORIES_LABEL] ?? [] : []}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 20,
          pageSizeOptions: ['10', '20', '50']
        }}
      />
    </>
  );
};
