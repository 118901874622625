import { CheckOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { StyledButton } from './CopyButton';

interface CopyButtonProps {
  onClick: () => void;
  tooltipText?: string;
  disabled?: boolean;
}

const PasteButton: React.FC<CopyButtonProps> = ({ onClick, disabled = false, tooltipText }) => {
  const [isPasted, setIsPasted] = useState(false);
  const tooltipTitle = tooltipText || 'Paste (associate) all the devices copied from other software. Paste will add, not overwrite.';
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopyText = () => {
    onClick();
    setIsPasted(true);

    // Clear previous timeout if existent
    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsPasted(false);
    }, 750);
  };

  return (
    <Tooltip mouseEnterDelay={1} title={tooltipTitle}>
      <StyledButton isCopied={isPasted} onClick={handleCopyText} disabled={disabled}>
        {isPasted ? <CheckOutlined /> : <SnippetsOutlined />}
      </StyledButton>
    </Tooltip>
  );
};

export default PasteButton;
