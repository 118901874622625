import { useParams } from 'react-router-dom';
import { useFlatifiedReports } from '../hooks/useFlatifiedReports';
import { useReportRevision } from '../hooks/useReportRevision';
import { PageLoading } from '../../shared/components/PageLoading';
import { hashString } from '../shared/util';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import { NavigateWithState } from '../../navigation/components/NavigateWithState';

export const ReportGateway = () => {
  const params = useParams<{ projectId: string; sourceId: string; revision: string; reportId: string }>();
  const [modeArg] = useSearchParameter('m', 'web');
  const projectId = parseInt(params.projectId || '-1', 10);
  const report = useReportRevision(projectId, params.sourceId || '', Number(params.revision ?? '0'));

  const flatifiedReports = useFlatifiedReports(report.data);

  const isLoading = report.isLoading;

  if (isLoading || !flatifiedReports || flatifiedReports.columns.length < 1) {
    return <PageLoading title="Redirecting to report..." />;
  }

  // TODO: use correct id insetead of external
  const document = flatifiedReports.documents.find((doc) => hashString(doc.meta.extra.pamMetadata.meta.path.value) === params.reportId);
  const link = document?.links.find((l) => l.target === 'sharepoint');

  // TODO: remove
  let target = link?.link || '';
  if (!document || !target) {
    console.warn('report not found', params, flatifiedReports, report.data);
    return <NavigateWithState to="/404" replace />;
  }

  if (modeArg === 'web') {
    target += '?web=1';
  }

  window.location.href = target;
  return modeArg === 'web' ? (
    <PageLoading title="Redirecting to report..." />
  ) : (
    <Result title="Download started" subTitle="Please check your downloads folder" icon={<CloudDownloadOutlined style={{ fontSize: 24 }} />} />
  );
};
