import { useState } from 'react';
import { Button, Tooltip, Checkbox, Radio, Space, Row, Col } from 'antd';
import type { RadioChangeEvent } from 'antd';

import { ReportIcon } from '../../shared/components/icons/ReportIcon';
import { useDownloader } from '../../shared/hooks/useDownloader';
import { presentApiError } from '../../../api/shared/errorPresenter';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { DeploymentPlanMeta } from '../../../api/deployment/domain/deployments';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { useProject } from '../../../contexts/projects/hooks/useProject';
import { SignReportExplanation } from '../../../contexts/shared/components/Report/SignReportExplanation';
import { ExcelReportSignWarning } from '../../../contexts/shared/components/Report/ExcelReportSignWarning';
import { b64toBlob } from '../../../contexts/shared/utils/base64ToBlob';
import { ReportResult } from '../../../contexts/shared/components/ReportResult';
import styled from 'styled-components';

// Hack, due to axios lacking streaming, the generate report
// endpoint is called twice. The signature header must be extracted
// from the second call, hence the header key is specified here.
const headerKey = 'x-signature-ecdsa-sha-512';

const StyledReportIcon = styled(ReportIcon)`
  font-size: ${({ theme }) => theme.fontSize}px;
`;

export const DeploymentPlanReportDownloadButton = (props: {
  deploymentPlan: DeploymentPlanMeta;
  size?: 'small' | 'middle' | 'large' | undefined;
  type?: 'button' | 'icon';
  projectId: number;
  envId: string;
}) => {
  const { type: buttonType = 'button' } = props;
  const project = useProject(props.projectId.toString());
  const [download] = useDownloader();
  const { backend } = useDeploymentBackend();

  const [modalVisible, setModalVisible] = useState(false);
  const [waitingForDownload, setWaitingForDownload] = useState(false);
  const [reportSignature, setReportSignature] = useState<string>();
  const [downloaded, setDownloaded] = useState(false);

  const [reportTitle, setReportTitle] = useState(true);
  const onChangeReportTitle = (e: RadioChangeEvent) => {
    setReportTitle(e.target.checked);
  };

  const [otc, setOtc] = useState(true);
  const onChangeOtc = (e: RadioChangeEvent) => {
    setOtc(e.target.checked);
  };
  const [rds, setRds] = useState(true);
  const onChangeRds = (e: RadioChangeEvent) => {
    setRds(e.target.checked);
  };
  const [deviceDescription, setDeviceDescription] = useState(true);
  const onChangeDeviceDescription = (e: RadioChangeEvent) => {
    setDeviceDescription(e.target.checked);
  };
  const [softwareComponent, setSoftwareComponent] = useState(true);
  const onChangeSoftwareComponent = (e: RadioChangeEvent) => {
    setSoftwareComponent(e.target.checked);
  };
  const [softwareVersion, setSoftwareVersion] = useState(true);
  const onChangeSoftwareVersion = (e: RadioChangeEvent) => {
    setSoftwareVersion(e.target.checked);
  };
  const [softwareDescription, setSoftwareDescription] = useState(false);
  const onChangeSoftwareDescription = (e: RadioChangeEvent) => {
    setSoftwareDescription(e.target.checked);
  };
  const [softwareReleaseNotes, setSoftwareReleaseNotes] = useState(false);
  const onChangeSoftwareReleaseNotes = (e: RadioChangeEvent) => {
    setSoftwareReleaseNotes(e.target.checked);
  };
  const [installationStatus, setInstallationStatus] = useState(false);
  const onChangeInstallationStatus = (e: RadioChangeEvent) => {
    setInstallationStatus(e.target.checked);
  };
  const [executor, setExecutor] = useState(false);
  const onChangeExecutor = (e: RadioChangeEvent) => {
    setExecutor(e.target.checked);
  };
  const [installationDate, setInstallationDate] = useState(false);
  const onChangeInstallationDate = (e: RadioChangeEvent) => {
    setInstallationDate(e.target.checked);
  };
  const [installationNotes, setInstallationNotes] = useState(false);
  const onChangeInstallationNotes = (e: RadioChangeEvent) => {
    setInstallationNotes(e.target.checked);
  };
  const [notDeployedSoftware, setNotDeployedSoftware] = useState(false);
  const onChangeNotDeployedSoftware = (e: RadioChangeEvent) => {
    setNotDeployedSoftware(e.target.checked);
  };
  const [softwareChecksum, setSoftwareChecksum] = useState(false);
  const onChangeSoftwareChecksum = (e: RadioChangeEvent) => {
    setSoftwareChecksum(e.target.checked);
  };
  const [signReport, setSignReport] = useState(false);
  const onChangeSignReport = (e: RadioChangeEvent) => {
    setSignReport(e.target.checked);
  };

  type ColumnTypes =
    | 'otc'
    | 'rds'
    | 'deviceDescription'
    | 'softwareComponentName'
    | 'softwareComponentVersion'
    | 'softwareDescription'
    | 'softwareReleaseNotes'
    | 'installationStatus'
    | 'executor'
    | 'installationDate'
    | 'installationNotes'
    | 'softwareChecksum';
  const selectedColumns: Array<ColumnTypes> = [];
  if (otc) {
    selectedColumns.push('otc');
  }
  if (rds) {
    selectedColumns.push('rds');
  }
  if (deviceDescription) {
    selectedColumns.push('deviceDescription');
  }
  if (softwareComponent) {
    selectedColumns.push('softwareComponentName');
  }
  if (softwareVersion) {
    selectedColumns.push('softwareComponentVersion');
  }
  if (softwareDescription) {
    selectedColumns.push('softwareDescription');
  }
  if (softwareReleaseNotes) {
    selectedColumns.push('softwareReleaseNotes');
  }
  if (installationStatus) {
    selectedColumns.push('installationStatus');
  }
  if (executor) {
    selectedColumns.push('executor');
  }
  if (installationDate) {
    selectedColumns.push('installationDate');
  }
  if (installationNotes) {
    selectedColumns.push('installationNotes');
  }
  if (softwareChecksum) {
    selectedColumns.push('softwareChecksum');
  }

  const [groupBy, setGroupBy] = useState(false);
  const onChangeGroupBy = (e: RadioChangeEvent) => {
    setGroupBy(e.target.checked);
    if (e.target.checked === true) {
      setSoftwareComponent(true);
      setSoftwareVersion(true);
      setInstallationStatus(false);
      setExecutor(false);
      setInstallationDate(false);
      setInstallationNotes(false);
    }
  };
  // sort by OTC
  const [sort, setSort] = useState<'ASC' | 'DESC'>('ASC');
  const onChangeSort = (e: RadioChangeEvent) => {
    setSort(e.target.value);
  };
  const [format, setFormat] = useState<'xlsx' | 'pdf'>('xlsx');
  const onChangeFormat = (e: RadioChangeEvent) => {
    setFormat(e.target.value);
  };

  const resetFields = () => {
    setWaitingForDownload(false);
    setDownloaded(false);

    setReportTitle(true);
    setGroupBy(false);
    setOtc(true);
    setRds(true);
    setDeviceDescription(true);
    setSoftwareComponent(true);
    setSoftwareVersion(true);
    setSoftwareDescription(false);
    setSoftwareReleaseNotes(false);
    setInstallationStatus(false);
    setExecutor(false);
    setInstallationDate(false);
    setInstallationNotes(false);
    setSoftwareChecksum(false);
    setSignReport(false);
    setSort('ASC');
    setFormat('xlsx');
  };

  const filename = `${[
    // // eslint-disable-next-line
    `PacTS_DeploymentPlan_${props.deploymentPlan.name.substring(0, 16)}_${project.data?.name || 'unknown'}`
  ]
    .filter((f) => f !== undefined)
    .join('-')}.${format}`;
  const downloadDisabledDueToNoSelection = selectedColumns.length < 1;

  const formatSignatureWarning = format === 'xlsx' && signReport;

  const onDownload = () => {
    setWaitingForDownload(true);
    backend
      .getDeploymentPlanReport(
        props.projectId,
        props.envId,
        props.deploymentPlan.id,
        format,
        reportTitle,
        selectedColumns,
        notDeployedSoftware,
        signReport,
        sort,
        groupBy
      )
      .then((response) => {
        download(response.streamUri, filename, undefined, true, [headerKey], ({ headers }) => {
          const responseHasSignature = !!headers[headerKey];
          if (!responseHasSignature) return Promise.resolve([]);
          const blob = b64toBlob(headers[headerKey]!);
          return Promise.resolve([
            {
              filename: `${filename}_signature.bin`,
              content: blob
            }
          ]);
        })
          .then(({ headers }) => {
            const responseHasSignature = !!headers[headerKey];
            setReportSignature(responseHasSignature ? headers[headerKey] : undefined);
            setDownloaded(true);
          })
          .finally(() => {
            setWaitingForDownload(false);
          });
      })
      .catch((err) => {
        setWaitingForDownload(false);
        presentApiError(err);
      });
  };

  const DownloadButtonJSX =
    buttonType === 'button' ? (
      <Button
        size={props.size}
        icon={<StyledReportIcon />}
        onClick={() => {
          setModalVisible(true);
        }}
        style={{ width: '100px' }}
        type="primary"
        disabled={!props.deploymentPlan || project.isLoading || !project.data}
      >
        Report
      </Button>
    ) : (
      <Button
        onClick={() => {
          setModalVisible(true);
        }}
        type="text"
        disabled={!props.deploymentPlan || project.isLoading || !project.data}
        icon={<StyledReportIcon />}
      />
    );

  const ReportConfiguration = () => (
    <>
      <Space direction="vertical" size="middle">
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={8}>
            <Space direction="vertical" size="middle">
              <b>General information</b>
              <Checkbox onChange={onChangeReportTitle} checked={reportTitle}>
                Cover page
              </Checkbox>
            </Space>
          </Col>
          <Col xs={24} sm={8}>
            <Space direction="vertical" size="middle">
              <b>Additional report information</b>
              <Checkbox onChange={onChangeNotDeployedSoftware} checked={notDeployedSoftware}>
                Not deployed software
              </Checkbox>
            </Space>
          </Col>
        </Row>
        <span style={{ marginBottom: 3 }} />
        <b>Columns to be displayed</b>
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 1 }}>
            <Checkbox onChange={onChangeOtc} checked={otc}>
              OTC
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 4 }}>
            <Checkbox onChange={onChangeRds} checked={rds}>
              RDS
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 7 }}>
            <Checkbox onChange={onChangeDeviceDescription} checked={deviceDescription}>
              Device description
            </Checkbox>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 8, order: 2 }}>
            <Checkbox onChange={onChangeSoftwareComponent} checked={softwareComponent} disabled={groupBy}>
              Software components
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 5 }}>
            <Checkbox onChange={onChangeSoftwareVersion} checked={softwareVersion} disabled={groupBy}>
              Software version
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 8 }}>
            <Checkbox onChange={onChangeSoftwareDescription} checked={softwareDescription}>
              Software description
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 10, offset: 8 }}>
            <Checkbox onChange={onChangeSoftwareReleaseNotes} checked={softwareReleaseNotes}>
              Software release notes
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 14, offset: 8 }}>
            <Checkbox onChange={onChangeSoftwareChecksum} checked={softwareChecksum}>
              Software checksum
            </Checkbox>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 8, order: 3 }}>
            <Checkbox onChange={onChangeInstallationStatus} checked={installationStatus} disabled={groupBy}>
              Installation status
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 6 }}>
            <Checkbox onChange={onChangeExecutor} checked={executor} disabled={groupBy}>
              Executor
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 9 }}>
            <Checkbox onChange={onChangeInstallationDate} checked={installationDate} disabled={groupBy}>
              Installation date
            </Checkbox>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8, order: 11 }}>
            <Checkbox onChange={onChangeInstallationNotes} checked={installationNotes} disabled={groupBy}>
              Installation notes
            </Checkbox>
          </Col>
        </Row>
        <span style={{ marginBottom: 3 }} />
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={8}>
            <Space direction="vertical" size="middle">
              <b>Group by</b>
              <Checkbox onChange={onChangeGroupBy} checked={groupBy}>
                Software component
              </Checkbox>
            </Space>
          </Col>
          <Col xs={24} sm={8}>
            <Space direction="vertical" size="middle">
              <b>Sort main column</b>
              <Radio.Group onChange={onChangeSort} value={sort}>
                <Space direction="vertical">
                  <Radio value="ASC">Ascending</Radio>
                  <Radio value="DESC">Descending</Radio>
                </Space>
              </Radio.Group>
            </Space>
          </Col>
        </Row>
        <b>Format</b>
        <Radio.Group onChange={onChangeFormat} value={format}>
          <Space direction="vertical">
            <Radio value="xlsx">Excel</Radio>
            {formatSignatureWarning ? <ExcelReportSignWarning /> : null}
            <Radio value="pdf">PDF</Radio>
          </Space>
        </Radio.Group>
        <b>Integrity</b>
        <Space direction="vertical" size={0}>
          <Checkbox onChange={onChangeSignReport} checked={signReport}>
            Sign Report
          </Checkbox>
          <SignReportExplanation />
        </Space>
      </Space>
    </>
  );

  return (
    <>
      <Tooltip title="Generate deployment report" trigger={['click', 'hover']}>
        {DownloadButtonJSX}
      </Tooltip>

      <ScrollDialog
        title="Report configuration"
        width={800}
        okText="Download"
        cancelText={downloaded ? 'Close' : 'Cancel'}
        confirmLoading={waitingForDownload}
        open={modalVisible}
        onOk={onDownload}
        okButtonProps={{
          disabled: downloadDisabledDueToNoSelection,
          hidden: downloaded
        }}
        afterClose={() => {
          resetFields();
        }}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        {downloaded ? <ReportResult signature={reportSignature} /> : <ReportConfiguration />}
      </ScrollDialog>
    </>
  );
};
