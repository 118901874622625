import { useQuery } from '@tanstack/react-query';
import { Project } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';
import { Comparator } from '../../../domain';

export function useProjects() {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions();

  const permissionsList = permissions.engineeringSvc$getProjects;
  const specificPermissions = !permissionsList ? permissions.engineeringSvc$getProject$specific() : [];

  const enabled = permissionsList || specificPermissions.length > 0;

  return useQuery<Project[], [string]>(
    ['projects'],
    (): Promise<Project[]> => {
      if (permissionsList)
        return backend.queryProjects().then((projects) => {
          const sortedProjects = projects.sort((proj, nextProj) => Comparator.lexicographicalComparison(proj.name, nextProj.name));
          return sortedProjects;
        });
      return Promise.all(specificPermissions.map((s) => backend.queryProject(s.projectId)));
    },
    {
      enabled
    }
  );
}
