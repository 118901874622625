import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { KeyboardEvent, isValidElement } from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  width: 100%;
`;

type ExpandableMenuProps = {
  id: string;
  icon?: React.ReactNode;
  children: React.ReactNode[];
  caption?: string | React.ReactNode;
  textType?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  showEmpty?: boolean;
  loading?: boolean;
  size?: 'large' | 'middle' | 'small';
};

// keys stop propagation
// https://github.com/ant-design/ant-design/issues/34125
const ARROW_KEYS = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

const HOME_KEY = 'Home';
const END_KEY = 'End';

export const ExpandableMenu = (props: ExpandableMenuProps) => {
  const icon = props.icon ?? <EllipsisOutlined />;
  const availableChildren = props.children.filter((c) => c);

  const hasAvailableChildren = availableChildren.length > 0;
  const disabled = props.disabled || !hasAvailableChildren;

  if (!hasAvailableChildren && !props.showEmpty) {
    return null;
  }

  const items: MenuProps['items'] = props.children.filter(Boolean).map((c, idx) => {
    const childIsValidReactElement = isValidElement(c);
    const childProps = childIsValidReactElement ? c.props : {};
    const childIsDisabled = childIsValidReactElement ? !!childProps.disabled : false;
    const childIsDanger = childIsValidReactElement ? !!childProps.danger : false;

    return {
      key: idx,
      danger: childIsDanger,
      disabled: childIsDisabled,
      label: (
        <StyledSpan
          onKeyDown={(e: KeyboardEvent<HTMLLIElement>): void => {
            const target = e.target as HTMLInputElement | HTMLTextAreaElement | undefined;
            if (ARROW_KEYS.indexOf(e.code) > -1) {
              e.stopPropagation();
            }
            /** Enable home-end key */
            if (e.key === HOME_KEY) {
              e.preventDefault();
              // Start at current position
              const currentPosition = target?.selectionStart ?? 0;
              // Worst-case is start of input
              let targetPosition = 0;
              // From one before current position start searching for the next line break
              for (let i = currentPosition - 1; i >= 0; i--) {
                if (target?.value[i] === '\n') {
                  // Set cursor to after line break
                  targetPosition = i + 1;
                  break;
                }
              }
              target?.setSelectionRange(targetPosition, targetPosition);
            } else if (e.key === END_KEY) {
              e.preventDefault();
              // Start at current curser position
              const currentPosition = target?.selectionStart ?? 0;
              // Worst-case is end of input
              const length = target?.value?.length ?? 0;
              let targetPosition = length;
              // From current position search for the next linebreak
              for (let i = currentPosition; i < length; i++) {
                if (target?.value[i] === '\n') {
                  targetPosition = i;
                  break;
                }
              }
              target?.setSelectionRange(targetPosition, targetPosition);
            }
            /** End enable home-end key */
          }}
        >
          {c}
        </StyledSpan>
      ),
      style: { padding: 0, marginTop: 2, marginBottom: 2 }
    };
  });

  return (
    <Dropdown
      disabled={disabled}
      // overlayStyle={{ margin: 0, padding: 0 }}
      menu={{ items }}
      trigger={['click']}
      placement="bottomRight"
    >
      <Button
        onClick={(e) => e.stopPropagation()}
        id={props.id}
        loading={props.loading}
        disabled={disabled}
        style={props.style}
        type={props.textType ? 'text' : 'default'}
        icon={icon}
        size={props.size}
        tabIndex={0}
      >
        {props.caption}
      </Button>
    </Dropdown>
  );
};
