import { Space, Typography } from 'antd';
import { useCreateDevice } from '../hooks/useCreateDevice';
import { DeviceForm } from './DeviceForm';
import type { ApiError, Device } from '../../../api';
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

/**
 * Component for creating a device list entry.
 *
 * @param props - The properties for the component.
 * @param props.initial - The initial device data.
 * @param props.projectId - The ID of the project.
 * @param props.createTrigger - A function to create a trigger for the device creation process.
 *
 * @returns A React component that renders a form for creating a device and handles the creation process.
 *
 * @example
 * ```tsx
 * <CreateDeviceListEntry
 *   initial={initialDevice}
 *   projectId={123}
 *   createTrigger={(trigger) => { /* trigger logic *\/ }}
 * />
 * ```
 */
export const CreateDeviceListEntry = (props: { initial: Device; projectId: number; createTrigger: (trigger: () => Promise<void>) => void }) => {
  const params = useParams();
  const envId = params?.envId || '';
  const { initial, projectId, createTrigger } = props;
  const createDevice = useCreateDevice(projectId, envId);

  const okHandler = useRef<() => void>(() => {});
  const triggerPromise = useRef<{ resolve: () => void; reject: (error: Error) => void } | undefined>(undefined);

  useEffect(() => {
    // calls the createTrigger callback and sets this to a promise that resolves
    // when ok handler is called and the device is created
    createTrigger(() => {
      return new Promise(async (resolve, reject) => {
        triggerPromise.current = { resolve, reject };
        try {
          await okHandler.current();
        } catch (error) {
          reject();
        }
      });
    });
  }, [createTrigger]);

  return (
    <FullWidthSpace direction="vertical" size={0}>
      <DeviceForm
        envId={envId}
        projectId={projectId}
        initial={{ ...initial }}
        ok={(ok) => {
          okHandler.current = ok;
        }}
        reset={() => {
          /* */
        }}
        onFinish={async (c) => {
          try {
            await createDevice.mutateAsync([c]);
            triggerPromise.current?.resolve();
          } catch (error) {
            triggerPromise.current?.reject(error as Error);
          }
        }}
      />
      {createDevice.isSuccess && <Typography.Text type="success">{`Created`}</Typography.Text>}
      {createDevice.isError && <Typography.Text type="danger">{`${(createDevice.error as ApiError).readableMessage}`}</Typography.Text>}
    </FullWidthSpace>
  );
};

export default CreateDeviceListEntry;
