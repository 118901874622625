import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import styled from 'styled-components';

interface CopyButtonProps {
  onClick: () => void;
  tooltipText?: string;
  disabled?: boolean;
}

export const StyledButton = styled(Button)<{ isCopied: boolean }>`
  padding: 0 8px;

  ${({ isCopied, theme }) =>
    isCopied
      ? `
      border: 1px solid ${theme.colorSuccessActive};

      svg {
        fill: ${theme.colorSuccessActive};
      }

      &&&:hover {
        border: 1px solid ${theme.colorSuccessActive};

        svg {
          fill: ${theme.colorSuccessActive};
        }
      }
  `
      : ''}
`;

const CopyButton: React.FC<CopyButtonProps> = ({ onClick, disabled = false, tooltipText }) => {
  const [isCopied, setIsCopied] = useState(false);
  const tooltipTitle = tooltipText || 'Copy all the devices associated with this software.';
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopyText = () => {
    onClick();
    setIsCopied(true);

    // Clear previous timeout if existent
    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 750);
  };

  return (
    <Tooltip mouseEnterDelay={1} title={tooltipTitle}>
      <StyledButton isCopied={isCopied} onClick={handleCopyText} disabled={disabled}>
        {isCopied ? <CheckOutlined /> : <CopyOutlined />}
      </StyledButton>
    </Tooltip>
  );
};

export default CopyButton;
