import { useMutation } from '@tanstack/react-query';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { ReleaseNotes } from '../../../../../../api/engineering/domain/types';

export function useEnhanceConfigurationVersionReleaseNotes() {
  const { backend } = useEngineeringBackend();

  return useMutation<ReleaseNotes, any, [number, number, number, string, number[], number[], number[]]>(
    async ([bundleId, projectId, configurationId, releaseNotes, commonSoftwareAppVersionIds, projectSoftwareAppVersionIds, toolVersionIds]) => {
      const enhancedReleaseNotes = await backend.enhanceConfigurationVersionReleaseNotes(
        bundleId,
        projectId,
        configurationId,
        releaseNotes,
        commonSoftwareAppVersionIds,
        projectSoftwareAppVersionIds,
        toolVersionIds
      );

      return enhancedReleaseNotes;
    }
  );
}

export default useEnhanceConfigurationVersionReleaseNotes;
