import { useQuery } from '@tanstack/react-query';
import { BundleConfiguration } from '../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../session/hooks/usePermissions';

export function useConfigurations(projectId: string, bundleId: string) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId });

  const parsedProjectId = parseInt(projectId, 10);
  const parsedBundleId = parseInt(bundleId, 10);

  const permissionsList = permissions.engineeringSvc$getProjectBundleConfigurations;
  const specificPermissions = !permissionsList ? permissions.engineeringSvc$getProjectBundleConfiguration$specific() : [];

  const enabled = parsedProjectId > 0 && parsedBundleId > 0 && (permissionsList || specificPermissions.length > 0);

  return useQuery<BundleConfiguration[], [string, string, string]>(
    ['bundleConfigurations', projectId, bundleId],
    (): Promise<BundleConfiguration[]> => {
      if (permissionsList) return backend.queryBundleConfigurations(projectId, bundleId);
      return Promise.all(specificPermissions.map((s) => backend.queryBundleConfiguration(s.projectId, s.idBundle, s.idConfiguration)));
    },
    {
      enabled
    }
  );
}

export default useConfigurations;
