import { useState, useEffect } from 'react';
import { Button, Form, Input, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { SemanticVersion, SoftwareItemSelection } from '../../../../../../domain';
import { usePermissions } from '../../../../../session/hooks/usePermissions';
import { ExpandableMenuItem } from '../../../../../shared/components/ExpandableMenuItem';
import { useCreateConfigurationVersion } from './hooks/useCreateConfigurationVersion';
import { MarkdownFormItem } from '../../../../../shared/components/MarkdownFormItem';
import { ReleaseNotesValidator } from '../../../../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../../../../domain/validation/versionValidator';
import { BundleRelease, BundleReleaseLite, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../../api/engineering/domain/types';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../../../../constants/texts';
import { ShiftedDrawer } from '../../../../../shared/components/ShiftedDrawer';
import useConfigurationVersions from '../../../../Configurations/hooks/useConfigurationVersions';
import styled from 'styled-components';
import { ScopedSoftwareApp } from '../../../types';
import useUpdateConfigurationVersionReleaseNotes from './hooks/useUpdateConfigurationVersionReleaseNotes';
import useUpdateConfigurationVersionNumber from './hooks/useUpdateConfigurationVersionNumber';
import { EnhanceReleaseNotes } from '../../EnhanceReleaseNotes/EnhanceReleaseNotes';

export type AddEditConfigurationVersionProps = {
  projectId: string;
  bundleId: string;
  version?: BundleRelease;
  bundleConfigurationId: string;
  selectedSoftwareItems: SoftwareItemSelection[];
  disabled?: boolean;
  onAddBundleConfigurationVersion?: (bundleConfigurationVersion: BundleReleaseLite) => any;
  isEdit?: boolean;
  isButton?: boolean;
};

const StyledButton = styled(Button)`
  width: 240px;
`;

const initialBcv: BundleReleaseLite = {
  idBundleRelease: 0,
  version: '',
  releaseNotes: '',
  engineeringToolVersionIds: [],
  softwareAppVersionIds: [],
  projectSoftwareAppVersionIds: []
};

export const AddEditConfigurationVersion = ({
  isButton,
  projectId,
  bundleId,
  version,
  bundleConfigurationId,
  selectedSoftwareItems,
  disabled,
  onAddBundleConfigurationVersion,
  isEdit = false
}: AddEditConfigurationVersionProps) => {
  const permissions = usePermissions({ projectId: projectId });
  const [modalVisible, setModalVisible] = useState(false);
  const createBundleConfigurationVersion = useCreateConfigurationVersion();
  const updateBundleConfigurationVersionReleaseNotes = useUpdateConfigurationVersionReleaseNotes();
  const updateBundleConfigurationVersionNumber = useUpdateConfigurationVersionNumber();

  // TODO: Switch to actual latest as soon as fixed in backend
  // const bundleConfiguration = useBundleConfiguration(projectId, bundleId, bundleConfigurationId);
  // const latestBundleConfigurationVersion = useBundleConfigurationVersion(
  //     projectId,
  //     bundleId,
  //     bundleConfigurationId,
  //     bundleConfiguration.data?.latestBundleReleaseId?.toString() || "",
  // );
  const bundleConfigurationVersions = useConfigurationVersions(projectId, bundleId, bundleConfigurationId);
  const [form] = Form.useForm();
  const [softwareItemVersionIds, setSoftwareItemVersionIds] = useState<Record<string, number[]>>();

  const isDisabled =
    (isEdit && !version) || disabled || bundleConfigurationId === '' || bundleId === '' || projectId === '' || !bundleConfigurationVersions.isSuccess;
  const [originalReleaseNotes, setOriginalReleaseNotes] = useState<string>(version?.releaseNotes || '');

  const handleValuesChange = (field: Record<string, string>) => {
    if ('releaseNotes' in field) {
      setOriginalReleaseNotes(field.releaseNotes);
    }
  };

  useEffect(() => {
    const versionIds: Record<string, number[]> = {};
    // Parse selected software apps and split them on the 3 categories needed on payload and also to pass to release notes enhancer
    versionIds.engineeringToolVersionIds = (selectedSoftwareItems ?? [])
      .filter((softwareItemSelection) => (softwareItemSelection.softwareItem as Tool).id !== undefined)
      .map((softwareItemSelection) => (softwareItemSelection.version as ToolVersion).idToolVersion || -1)
      .filter((sai) => sai > -1);
    versionIds.softwareAppVersionIds = (selectedSoftwareItems ?? [])
      .filter((softwareItemSelection) => (softwareItemSelection.softwareItem as ScopedSoftwareApp).scope === 'common')
      .map((softwareItemSelection) => (softwareItemSelection.version as SoftwareAppVersion).idSoftwareAppVersion || -1)
      .filter((sai) => sai > -1);
    versionIds.projectSoftwareAppVersionIds = (selectedSoftwareItems ?? [])
      .filter((softwareItemSelection) => (softwareItemSelection.softwareItem as ScopedSoftwareApp).scope === 'project')
      .map((softwareItemSelection) => (softwareItemSelection.version as SoftwareAppVersion).idSoftwareAppVersion || -1)
      .filter((sai) => sai > -1);

    setSoftwareItemVersionIds(versionIds);
  }, [selectedSoftwareItems]);

  useEffect(() => {
    if (modalVisible && bundleConfigurationVersions.isSuccess) {
      if (bundleConfigurationVersions.data && bundleConfigurationVersions.data.length > 0) {
        const sortedVersions = bundleConfigurationVersions?.data.sort((a, b) =>
          SemanticVersion.fromString(a.version).compareTo(SemanticVersion.fromString(b.version))
        );
        form.setFieldsValue({
          version: isEdit
            ? version?.version
            : SemanticVersion.fromUnsafeString(sortedVersions[0].version || '')
                .increment()
                .toString({ hideSuffix: true }),
          releaseNotes: isEdit ? version?.releaseNotes : ''
        });
      } else {
        form.setFieldsValue({
          version: SemanticVersion.initial().toString(),
          releaseNotes: ''
        });
      }
    }
    // listen to changes in modal visible and data loaded success
    // update the form fields only when the drawer opens and the bundle config version
    // is successfully loaded
    // eslint-disable-next-line
  }, [form, modalVisible, bundleConfigurationVersions.isSuccess, isEdit, version?.releaseNotes, version?.version]);

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (createBundleConfigurationVersion.isSuccess) {
      setModalVisible(false);
    }
  }, [createBundleConfigurationVersion.isSuccess, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  const AddEditConfigurationButton = isButton ? (
    <StyledButton
      type="primary"
      onClick={() => {
        setModalVisible(true);
      }}
      disabled={isDisabled}
    >
      Create first release
    </StyledButton>
  ) : (
    <ExpandableMenuItem
      icon={isEdit ? <EditOutlined /> : <PlusOutlined />}
      loading={
        (!isEdit
          ? createBundleConfigurationVersion.isLoading
          : updateBundleConfigurationVersionReleaseNotes.isLoading || updateBundleConfigurationVersionNumber.isLoading) || bundleConfigurationVersions.isLoading
      }
      disabled={isDisabled}
      onClick={() => {
        setModalVisible(true);
      }}
    >
      {isEdit ? 'Edit' : 'Add'} Version
    </ExpandableMenuItem>
  );

  const addEditBundleConfigVersion = (values: Record<string, any>) => {
    let bundleConfigVersionPayload = Object.assign(initialBcv, values);

    if (isEdit) {
      const { version: versionName, releaseNotes } = bundleConfigVersionPayload;

      if (permissions.engineeringSvc$putProjectBundleConfigurationReleasesVersion) {
        updateBundleConfigurationVersionNumber
          .mutateAsync([projectId, bundleId, bundleConfigurationId, version?.idBundleRelease.toString() as string, { version: versionName }])
          .then((bundleRelease: BundleReleaseLite) => {
            if (onAddBundleConfigurationVersion && bundleRelease) {
              onAddBundleConfigurationVersion(bundleRelease);
            }
          });
      }

      updateBundleConfigurationVersionReleaseNotes
        .mutateAsync([projectId, bundleId, bundleConfigurationId, version?.idBundleRelease.toString() as string, { releaseNotes }])
        .then((bundleRelease: BundleReleaseLite) => {
          if (onAddBundleConfigurationVersion && bundleRelease) {
            onAddBundleConfigurationVersion(bundleRelease);
          }
        });
    } else {
      bundleConfigVersionPayload = { ...bundleConfigVersionPayload, ...softwareItemVersionIds };

      createBundleConfigurationVersion
        .mutateAsync([projectId, bundleId, bundleConfigurationId, bundleConfigVersionPayload])
        .then((bundleRelease: BundleReleaseLite) => {
          if (onAddBundleConfigurationVersion && bundleRelease) {
            onAddBundleConfigurationVersion(bundleRelease);
          }
        });
    }
    setModalVisible(false);
  };

  const replaceReleaseNotes = (enhancedReleaseNotes: string) => {
    setOriginalReleaseNotes(enhancedReleaseNotes);
    form.setFieldValue('releaseNotes', enhancedReleaseNotes);
  };

  return (
    <>
      {permissions.engineeringSvc$addProjectBundleConfigurationRelease && <Tooltip title="Add configuration version">{AddEditConfigurationButton}</Tooltip>}

      <ShiftedDrawer
        title={`${isEdit ? 'Edit' : 'Add'} configuration version`}
        destroyOnClose
        open={modalVisible}
        loading={createBundleConfigurationVersion.isLoading}
        isFormDrawer
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
        }}
        afterOpenChange={(open) => {
          if (!open) form.resetFields();
        }}
      >
        <Form
          {...layout}
          name="basic"
          labelAlign="left"
          form={form}
          initialValues={{ ...initialBcv }}
          onFinish={addEditBundleConfigVersion}
          onValuesChange={handleValuesChange}
        >
          <Form.Item label="Version" name="version" rules={[VersionValidator.rule()]}>
            <Input disabled={isEdit && !permissions.engineeringSvc$putProjectBundleConfigurationReleasesVersion} />
          </Form.Item>
          <MarkdownFormItem
            label="Release Notes"
            field={['releaseNotes']}
            fullHeight={true}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            rules={[ReleaseNotesValidator.rule()]}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          {permissions.engineeringSvc$postEnhanceBundleReleaseNotes && (
            <EnhanceReleaseNotes
              originalReleaseNotes={originalReleaseNotes}
              onOk={replaceReleaseNotes}
              bundleId={bundleId}
              projectId={projectId}
              configId={bundleConfigurationId}
              commonSoftwareAppVersionIds={softwareItemVersionIds?.softwareAppVersionIds}
              projectSoftwareAppVersionIds={softwareItemVersionIds?.projectSoftwareAppVersionIds}
              toolVersionIds={softwareItemVersionIds?.engineeringToolVersionIds}
            />
          )}
        </Form>
      </ShiftedDrawer>
    </>
  );
};
