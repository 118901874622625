import { Form, Input, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/es/form/interface';
import { Redirects } from '@pacts/utils-redirects';
import { usePermissions } from '../../../../contexts/session/hooks/usePermissions';

export const ICS_PORTAL_ID_NAME_KEY = 'icsPortalComponentId';

type Props = {
  /**
   * The target of the software app version.
   * Ignore this for engineering tools.
   **/
  target?: string;

  /** The Form.Item name prop. */
  name: NamePath;
};

/**
 * Only used inside forms to add an extra
 * ICS Portal ID input item.
 */
export function ICSPortalComponentIdFormItem(props: Props) {
  const permissions = usePermissions();
  if (!permissions.webui$showFormInputIcsId) return null;

  const labelText = `ICS Portal ID${props.target ? ` ${props.target}` : ''}`;

  const tooltip = (
    <Tooltip title="ICS Portal">
      <Typography.Link href={Redirects.ICS_PORTAL} target="_blank">
        <QuestionCircleOutlined />
      </Typography.Link>
    </Tooltip>
  );

  return (
    <Form.Item
      label={
        <span>
          {labelText} {tooltip}
        </span>
      }
      name={props.name}
      required={false}
      rules={[
        {
          required: false,
          pattern: new RegExp('^CP[0-9A-Z]{26}$'),
          message: 'Invalid ICS Portal ID, must be 26 characters long and only contain A-Z and 0-9'
        }
      ]}
    >
      <Input />
    </Form.Item>
  );
}
