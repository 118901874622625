import { Skeleton, Space, Typography } from 'antd';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';
import { EditToolVersion } from '../EditSoftwareItemVersion/EditToolVersion';
import { ToolOpenInToolManagerButton } from '../../../SoftwareItemOpenInToolManagerButton/ToolOpenInToolManagerButton';
import { SemanticVersion } from '../../../../../../../domain/versioning/semanticVersion';
import Table from '../../../../../../shared/components/Table/Table';

import { useDeleteToolVersion } from './hooks/useDeleteToolVersion';
import { useToolVersions } from './hooks/useToolVersions';
import { useTool } from '../../../SoftwareItemVersionSelection/hooks/useTool';
import { LatestTag } from '../../../../../../shared/components/LatestTag';
import { ExpandableMenu } from '../../../../../../shared/components/ExpandableMenu';
import { ComponentVersionListEntry } from '../../../../../../shared/components/ComponentVersionListEntry';
import { DownloadContextMenuButton } from '../../../../../../shared/components/Download/components/DownloadContextMenuButton';
import { ToolVersionVulnerabilityButton } from '../../../SoftwareItemVersionVulnerabilityButton/ToolVersionVulnerabilityButton';

import type { Project, Tool, ToolVersion } from '../../../../../../../api/engineering/domain/types';
import { useCallback, useMemo } from 'react';
import { UnstuckSyncingToolVersion } from '../../../../../../Tools/components/ToolsList/UnstuckSyncingToolVersion';
import { formatDateTime } from '../../../../../../shared/components/formatDate';
import { useTableSearch } from '../../../../../../shared/components/TableSearch';
import { ConfirmationButton } from '../../../../../../shared/components/ConfirmationButton';
import { BundleTag } from '../../../../../../shared/components/BundleTag';

export interface ToolVersionsListProps {
  engineeringTool: Tool;
  project?: Project;
  bundleVersion?: ToolVersion;
}

export const ToolVersionsList: React.FC<ToolVersionsListProps> = (props) => {
  const versions = useToolVersions(props.engineeringTool.id.toString());
  const versionSearch = useTableSearch({ searchValueProvider: 'version', searchParamId: 'version' });
  const releaseNotesSearch = useTableSearch({
    searchValueProvider: (v: ToolVersion) => {
      return [
        v.version,
        v.releaseNotes,
        formatDateTime(new Date(v.createdAt || 0)),
        v.createdBy,
        formatDateTime(new Date(v.updatedAt || 0)),
        v.updatedBy,
        v.state === 'consistent' ? undefined : 'syncing'
      ]
        .filter(Boolean)
        .join(' ');
    },
    searchParamId: 'releaseNotes'
  });
  const tool = useTool(props.engineeringTool.id?.toString() || '');
  const deleteEngineeringToolVersion = useDeleteToolVersion();
  const permissions = usePermissions({ toolId: props.engineeringTool.id.toString(), projectId: props.project?.idProject.toString() });

  const latestVersion = props.engineeringTool?.latestVersion?.idToolVersion;
  const versionSorter = useCallback(
    (a: ToolVersion, b: ToolVersion) => {
      if (a.idToolVersion === latestVersion) return -1;
      if (b.idToolVersion === latestVersion) return 1;
      return SemanticVersion.sorter(a.version, b.version);
    },
    [latestVersion]
  );

  const data = useMemo(() => {
    return (versions.data ?? []).sort(versionSorter);
  }, [versions.data, versionSorter]);

  const columns = [
    {
      title: 'Version',
      key: 'version',
      ...versionSearch,
      width: 160,
      render: (record: ToolVersion) => {
        return (
          <>
            <Space wrap={true} size={[8, 4]}>
              {record.idToolVersion === tool.data?.latestVersion?.idToolVersion && <LatestTag />}
              {record.idToolVersion === props.bundleVersion?.idToolVersion && <BundleTag />}
              {record.version}
              <ToolVersionVulnerabilityButton app={props.engineeringTool} version={record} />
            </Space>
          </>
        );
      }
    },
    {
      title: 'Release Notes',
      key: 'releaseNotes',
      ...releaseNotesSearch,
      showSorterTooltip: { title: 'Sort by creation date' },
      sorter: {
        compare: (a: ToolVersion, b: ToolVersion): number => {
          return new Date(a.createdAt ?? 0).getTime() - new Date(b.createdAt ?? 0).getTime();
        }
      },
      render: (record: ToolVersion) => <ComponentVersionListEntry record={record} showAudit={permissions.webui$showComponentDetails} />
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: ToolVersion) => {
        return (
          // this is a hack to hide the version context menu button
          // in case someone cannot download it, it is very unlikely that other options are possible
          permissions.engineeringSvc$getToolVersionDownload && (
            <ExpandableMenu textType id={`tool-version-actions-${props.engineeringTool.id}-${record.idToolVersion}`}>
              <DownloadContextMenuButton artifact={props.engineeringTool} version={record} />
              <ToolOpenInToolManagerButton selection={{ softwareItem: props.engineeringTool, version: record }} project={props.project} />
              <EditToolVersion tool={props.engineeringTool} toolVersion={record} />
              <UnstuckSyncingToolVersion tool={props.engineeringTool} toolVersion={record} />
              {permissions.engineeringSvc$deleteToolVersion && (
                <ConfirmationButton
                  title="Deleting version"
                  description="This action cannot be undone."
                  paragraphDescription={
                    <Typography.Paragraph>
                      Are you sure you want to delete version <Typography.Text strong>{record.version}</Typography.Text>?
                    </Typography.Paragraph>
                  }
                  danger
                  caption="Delete"
                  disabled={record.idToolVersion === tool.data?.latestVersion?.idToolVersion}
                  onOk={() => {
                    deleteEngineeringToolVersion.mutate([props.engineeringTool, record]);
                  }}
                />
              )}
            </ExpandableMenu>
          )
        );
      },
      width: 100
    }
  ];

  return (
    <Skeleton loading={versions.isLoading}>
      <Table
        tableLayout="fixed"
        columns={columns}
        rowKey={(record: ToolVersion) => record.idToolVersion?.toString() || ''}
        dataSource={data}
        sticky={{
          offsetHeader: -25
        }}
      />
    </Skeleton>
  );
};
