import { useMemo } from 'react';
import { Flex, Typography } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useProjects } from '../../projects/hooks/useProjects';

import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import { ProjectBundleConfigurationSelect } from './ProjectConfigurationSelect';
import { ProjectBundleConfigurationVersionSelect } from './ProjectConfigurationVersionSelect';
import { ProjectCompareSelect } from './ProjectSelect';
import { ALL_CATEGORIES_LABEL, ProjectSoftwareAppsCompareTable } from './SoftwareAppsCompareTable';

import type { Bundle, Project } from '../../../api/engineering/domain/types';
import { useSearchParameter } from '../../navigation/hooks';

interface ProjectSoftwareCompareDrawerProps {
  defaultProject: Project | undefined;
  defaultBundle: Bundle | undefined;
  open: boolean;
  onClose: () => void;
}

const CompareContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  width: 100%;
  background: ${({ theme }) => theme.colorBgLayout};
  max-width: 962px;
`;

const CompareLabel = styled(Typography.Text)`
  width: 105px;
  font-weight: 600;
`;

const StyledFlex = styled(Flex)`
  margin-bottom: 12px !important;
`;

export const ProjectSoftwareCompareDrawer: React.FC<ProjectSoftwareCompareDrawerProps> = (props) => {
  const projects = useProjects();
  const { configurationId } = useParams();

  // Retrieve the default left side version from the opened configuration
  const [defaultVersionL] = useSearchParameter('r_version');
  const parsedConfigurationId = !!configurationId ? Number(configurationId) : undefined;
  const [projectIdAParam, setProjectIdA] = useSearchParameter('l_project', props.defaultProject?.idProject.toString());
  const projectIdA = !!projectIdAParam ? Number(projectIdAParam) : undefined;
  const [bundleIdAParam, setBundleIdAParam] = useSearchParameter('l_bundle', props.defaultBundle?.idBundle.toString());
  const bundleIdA = !!bundleIdAParam && bundleIdAParam !== 'null' ? bundleIdAParam : undefined;
  const [configIdAParam, setConfigIdAParam] = useSearchParameter('l_configuration', String(parsedConfigurationId));
  const configIdA = !!configIdAParam && configIdAParam !== 'null' ? Number(configIdAParam) : undefined;
  const [configReleaseIdAParam, setConfigReleaseIdAParam] = useSearchParameter('l__config_version', defaultVersionL);
  const configReleaseIdA = !!configReleaseIdAParam && configReleaseIdAParam !== 'null' ? Number(configReleaseIdAParam) : undefined;

  const [projectIdBParam, setProjectIdB] = useSearchParameter('r_project', props.defaultProject?.idProject.toString());
  const projectIdB = !!projectIdBParam ? Number(projectIdBParam) : undefined;
  const [bundleIdBParam, setBundleIdBParam] = useSearchParameter('r_bundle', props.defaultBundle?.idBundle.toString());
  const bundleIdB = !!bundleIdBParam && bundleIdBParam !== 'null' ? bundleIdBParam : undefined;
  const [configIdBParam, setConfigIdBParam] = useSearchParameter('r_configuration', configurationId || '');
  const configIdB = !!configIdBParam && configIdBParam !== 'null' ? Number(configIdBParam) : undefined;
  const [configReleaseIdBParam, setConfigReleaseIdBParam] = useSearchParameter('r_config_version');
  const configReleaseIdB = !!configReleaseIdBParam && configReleaseIdBParam !== 'null' ? Number(configReleaseIdBParam) : undefined;
  const [activeTabKeyParam, setActiveTabKeyParam] = useSearchParameter('compare_tab', ALL_CATEGORIES_LABEL);

  const resetIdsA = () => {
    // We need to pass null because if we reset to empty string it will default to
    // initially default value passed in the useSearchParameter hook, we need to prevent this
    setBundleIdAParam('null');
    setConfigIdAParam('null');
    setConfigReleaseIdAParam('null');
    setActiveTabKeyParam(ALL_CATEGORIES_LABEL);
  };

  const resetIdsB = () => {
    // We need to pass null because if we reset to empty string it will default to
    // initially default value passed in the useSearchParameter hook, we need to prevent this
    setBundleIdBParam('null');
    setConfigIdBParam('null');
    setConfigReleaseIdBParam('null');
    setActiveTabKeyParam(ALL_CATEGORIES_LABEL);
  };

  const handleCloseDrawer = () => {
    props.onClose();
    setActiveTabKeyParam('');
    setConfigReleaseIdBParam('');
    setConfigReleaseIdAParam('');
    setConfigIdBParam('');
    setBundleIdBParam('');
    setConfigIdAParam('');
    setBundleIdAParam('');
    setProjectIdA('');
    setProjectIdB('');
  };

  const projectItems = useMemo(() => {
    if (!projects.data) return [];

    return projects.data.map((p) => ({ value: p.idProject, label: p.name }));
  }, [projects.data]);

  return (
    // We are using styles prop here since it's overriding styled components
    <ShiftedDrawer styles={{ body: { paddingTop: 8 } }} title="Compare Releases" open={props.open} onClose={handleCloseDrawer} width={1086}>
      <StyledFlex vertical gap={8} align="start">
        <CompareContainer>
          <CompareLabel>Project</CompareLabel>

          <ProjectCompareSelect
            showSearch
            popupMatchSelectWidth={350}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            value={projectIdA || props.defaultProject?.idProject}
            options={projectItems}
            onChange={(projectId) => {
              resetIdsA();
              setProjectIdA(String(projectId));
            }}
          />
          <Typography.Text>vs</Typography.Text>
          <ProjectCompareSelect
            showSearch
            popupMatchSelectWidth={350}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            value={projectIdB || props.defaultProject?.idProject}
            options={projectItems}
            onChange={(projectId) => {
              setTimeout(() => {
                resetIdsB();
                setProjectIdB(String(projectId));
              }, 0);
            }}
          />
        </CompareContainer>
        <Flex>
          <CompareContainer>
            <CompareLabel>Configuration</CompareLabel>

            <ProjectBundleConfigurationSelect
              projectId={projectIdA?.toString() ?? ''}
              configurationId={configIdA}
              defaultConfigId={configIdA || parsedConfigurationId}
              onSelectChange={(configId, bundleId) => {
                if (!configId || !bundleId || !props.open) {
                  return;
                }

                setConfigIdAParam(String(configId));
                setBundleIdAParam(bundleId);
                setConfigReleaseIdAParam('null');
                setActiveTabKeyParam(ALL_CATEGORIES_LABEL);
              }}
            />

            <Typography.Text>vs</Typography.Text>

            <ProjectBundleConfigurationSelect
              projectId={projectIdB?.toString() ?? ''}
              configurationId={configIdB}
              defaultConfigId={configIdB || parsedConfigurationId}
              onSelectChange={(configId, bundleId) => {
                if (!configId || !bundleId || !props.open) {
                  return;
                }

                setConfigIdBParam(String(configId));
                setBundleIdBParam(bundleId);
                setConfigReleaseIdBParam('null');
                setActiveTabKeyParam(ALL_CATEGORIES_LABEL);
              }}
            />
          </CompareContainer>
          <CompareContainer>
            <CompareLabel>Version</CompareLabel>

            <ProjectBundleConfigurationVersionSelect
              projectId={projectIdA?.toString() ?? ''}
              bundleId={bundleIdA?.toString() ?? ''}
              configurationId={configIdA?.toString() ?? ''}
              configReleaseId={configReleaseIdA}
              onChange={(releaseId) => {
                if (!releaseId || !props.open) {
                  return;
                }

                setActiveTabKeyParam(ALL_CATEGORIES_LABEL);
                setConfigReleaseIdAParam(String(releaseId));
              }}
            />

            <Typography.Text>vs</Typography.Text>

            <ProjectBundleConfigurationVersionSelect
              projectId={projectIdB?.toString() ?? ''}
              bundleId={bundleIdB?.toString() ?? ''}
              configurationId={configIdB?.toString() ?? ''}
              configReleaseId={configReleaseIdB}
              shouldRemainUnselected
              onChange={(releaseId) => {
                if (!releaseId || !props.open) {
                  return;
                }

                setActiveTabKeyParam(ALL_CATEGORIES_LABEL);
                setConfigReleaseIdBParam(String(releaseId));
              }}
            />
          </CompareContainer>
        </Flex>
      </StyledFlex>
      <ProjectSoftwareAppsCompareTable
        project={props.defaultProject!}
        projectIdA={projectIdA?.toString() ?? ''}
        projectIdB={projectIdB?.toString() ?? ''}
        bundleIdA={bundleIdA ?? ''}
        bundleIdB={bundleIdB ?? ''}
        configIdA={configIdA?.toString() ?? ''}
        configIdB={configIdB?.toString() ?? ''}
        releaseIdA={configReleaseIdA?.toString() ?? ''}
        releaseIdB={configReleaseIdB?.toString() ?? ''}
        activeTabKey={activeTabKeyParam || ALL_CATEGORIES_LABEL}
        setActiveTabKey={setActiveTabKeyParam}
      />
    </ShiftedDrawer>
  );
};
