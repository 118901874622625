import { useMemo } from 'react';
import EventEmitter from 'eventemitter3';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  Configuration,
  AuthApi,
  UserApi,
  User as InfraUser,
  UserWithRoles as InfraUserWithRoles,
  RoleApi,
  Role as InfraRole,
  CommunicationApi,
  RoleWithPermissions,
  RefreshToken
} from '@pacts/userservice-api';
import { UserBackend, UserBackendError } from '../service/userBackend';
import {
  User,
  UserRole,
  UserRoleWithPermissionsCreate,
  UserRoleWithPermissions,
  UserRoleWithPermissionsUpdate,
  UserWithRoles,
  UserInfo,
  RefreshTokenMetadata,
  RefreshTokenMetadataWithUserId
} from '../domain/users';

import { useRestBackendConfig } from '../../shared/useBackendConfiguration';
import { SharedAxiosInstance } from '../../shared/sharedAxiosInstance';
import { GlobalState } from '../../../state/globalState';

class RestUserBackend implements UserBackend {
  private readonly emitter: EventEmitter = new EventEmitter();

  private readonly uApi: UserApi;

  private readonly aApi: AuthApi;

  private readonly rApi: RoleApi;

  private readonly cApi: CommunicationApi;

  constructor(
    public readonly config: Configuration,
    instance: AxiosInstance
  ) {
    this.uApi = new UserApi(config, undefined, instance);
    this.aApi = new AuthApi(config, undefined, instance);
    this.rApi = new RoleApi(config, undefined, instance);
    this.cApi = new CommunicationApi(config, undefined, instance);
  }

  heartbeat(): Promise<void> {
    return this.genericPromise(this.cApi.getHealth());
  }

  queryUsers(withRoles: boolean): Promise<UserWithRoles[]> {
    return new Promise((resolve, reject) => {
      this.uApi
        .getUsers(withRoles)
        .then((r) => {
          resolve(r.data.map<UserWithRoles>(RestUserBackend.infraUserWithRolesToDomainUser));
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  deleteUser(id: number): Promise<void> {
    return this.genericPromise(this.uApi.deleteUser(id));
  }

  queryUserRoles(userId: number): Promise<UserRole[]> {
    return new Promise<UserRole[]>((resolve, reject) => {
      this.rApi
        .getRolesOfUser(userId)
        .then((r) => {
          resolve(r.data.map(RestUserBackend.infraRoleToDomainRole));
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  updateUserRoles(userId: number, roles: UserRole[]): Promise<UserRole[]> {
    return new Promise<UserRole[]>((resolve, reject) => {
      this.rApi
        .updateRolesOfUser(userId, roles)
        .then((r) => {
          resolve(r.data.map(RestUserBackend.infraRoleToDomainRole));
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  queryRoles(): Promise<UserRoleWithPermissions[]> {
    return new Promise<UserRoleWithPermissions[]>((resolve, reject) => {
      this.rApi
        .getRoles()
        .then((r) => {
          resolve(r.data.map(RestUserBackend.infraRoleWithPermissionsToDomainRole));
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  createRole(create: UserRoleWithPermissionsCreate): Promise<UserRoleWithPermissions> {
    return new Promise<UserRoleWithPermissions>((resolve, reject) => {
      this.rApi
        .addRole(create)
        .then((r) => {
          resolve(RestUserBackend.infraRoleWithPermissionsToDomainRole(r.data));
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  updateRole(update: UserRoleWithPermissionsUpdate): Promise<UserRoleWithPermissions> {
    return new Promise<UserRoleWithPermissions>((resolve, reject) => {
      this.rApi
        .updateRole(update)
        .then((r) => {
          resolve(RestUserBackend.infraRoleWithPermissionsToDomainRole(r.data));
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  deleteRole(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.rApi
        .deleteRole(id)
        .then(() => {
          resolve();
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  async getUserInfo(): Promise<UserInfo> {
    const res = await this.aApi.oauthUserinfo();
    return res.data;
  }

  getCurrentUserSessions(): Promise<RefreshTokenMetadata[]> {
    return new Promise((resolve, reject) => {
      this.aApi
        .oauthGetRefreshTokens()
        .then((r) => {
          resolve(r.data.map(RestUserBackend.refreshTokenMetaToDomain));
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  getUserSessions(): Promise<RefreshTokenMetadataWithUserId[]> {
    return new Promise((resolve, reject) => {
      this.aApi
        .restrictedGetRefreshTokens()
        .then((r) => {
          resolve(r.data.map((sess) => ({ ...RestUserBackend.refreshTokenMetaToDomain(sess), userid: sess.userid })));
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  terminateUserSession(userId: number, tokenFamily: string): Promise<void> {
    return this.genericPromise(this.aApi.restrictedDeleteRefreshToken(userId, tokenFamily));
  }

  onError(handler: (error: UserBackendError) => any): void {
    this.emitter.on('error', handler);
  }

  private static infraUserToDomainUser(infra: InfraUser): User {
    return {
      email: infra.email,
      id: infra.id!,
      name: infra.name,
      gid: infra.gid,
      createdAt: infra.createdAt,
      createdBy: infra.createdByName,
      createdById: infra.createdBy,
      updatedAt: infra.updatedAt,
      updatedBy: infra.updatedByName,
      updatedById: infra.updatedBy,
      lastLogin: infra.lastLogin
    };
  }

  private static infraUserWithRolesToDomainUser(infra: InfraUserWithRoles): UserWithRoles {
    return {
      ...RestUserBackend.infraUserToDomainUser(infra),
      roles:
        infra.roles?.map((r) => ({
          description: r.description,
          id: r.id,
          name: r.name,
          createdAt: r.createdAt,
          createdBy: r.createdByName,
          createdById: r.createdBy,
          updatedAt: r.updatedAt,
          updatedBy: r.updatedByName,
          updatedById: r.updatedBy
        })) ?? []
    };
  }

  private static infraRoleToDomainRole(infra: InfraRole): UserRole {
    return {
      id: infra.id,
      name: infra.name,
      description: infra.description,
      createdAt: infra.createdAt,
      createdBy: infra.createdByName,
      createdById: infra.createdBy,
      updatedAt: infra.updatedAt,
      updatedBy: infra.updatedByName,
      updatedById: infra.updatedBy
    };
  }

  private static refreshTokenMetaToDomain(infra: RefreshToken): RefreshTokenMetadata {
    return {
      authFlow: infra.auth_flow,
      clientId: infra.client_id,
      counter: infra.counter,
      issuedAt: new Date(infra.issued_at),
      lastAccessTokenIssuedAt: new Date(infra.last_access_token_issued_at),
      provider: infra.provider,
      tokenFamily: infra.token_family,
      expiry: new Date(infra.expiry),
      limit: new Date(infra.limit)
    };
  }

  private static infraRoleWithPermissionsToDomainRole(infra: RoleWithPermissions): UserRoleWithPermissions {
    return {
      ...RestUserBackend.infraRoleToDomainRole(infra),
      permissions: infra.permissions
    };
  }

  private errorHandler(rejector: (error: UserBackendError) => void): (error: AxiosError) => void {
    return (error: AxiosError) => {
      const err = new UserBackendError(error.message, error.response?.status || 999, []);
      this.emitter.emit('error', err);
      rejector(err);
    };
  }

  private genericPromise<T>(promise: Promise<AxiosResponse<T>>) {
    return new Promise<T>((rs, rj) => {
      promise.then((r) => rs(r.data)).catch(this.errorHandler(rj).bind(this));
    });
  }
}

export const useRestUserBackend = (state: GlobalState) => {
  const config = useRestBackendConfig(state.userServiceBasePath);
  const backend = useMemo(() => new RestUserBackend(new Configuration(config), SharedAxiosInstance.instance()), [config]);
  return backend;
};
