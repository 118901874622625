import { Select } from 'antd';
import type { Device } from '../../../api';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  width: 100%;
` as typeof Select;

interface DeploymentDevicesSelectProps {
  loading: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  onSearch: (searchValue: string) => boolean;
  sortedDevices: Device[];
  isLastInvalidStr: boolean;
}

const DeploymentDevicesSelect: React.FC<DeploymentDevicesSelectProps> = ({ loading, onChange, value, onSearch, sortedDevices, isLastInvalidStr }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const lastValidValueRef = useRef<string[]>([]);
  const formattedValue = isLastInvalidStr ? lastValidValueRef.current : value;

  const handleSearch = (newSearchValue: string) => {
    const isValidPasteSearch = onSearch(newSearchValue);

    if (isValidPasteSearch) {
      setIsDropdownOpen(false);
      setSearchValue('');
      return;
    }

    setSearchValue(newSearchValue);
  };

  const handleOnChange = (newSearchValue: string[]) => {
    lastValidValueRef.current = newSearchValue;
    onChange(newSearchValue);
  };

  const handleOnBlur = () => {
    onChange(lastValidValueRef.current);
  };

  // We need to store the last valid value since
  // when typing useForm provides the value from the string input which is incorrect for the select
  useEffect(() => {
    if (isLastInvalidStr) {
      return;
    }

    lastValidValueRef.current = value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleDropdownVisibleChange = (open: boolean) => {
    setIsDropdownOpen(open);
    setSearchValue('');
  };

  return (
    <StyledSelect
      onDropdownVisibleChange={handleDropdownVisibleChange}
      searchValue={searchValue}
      mode="multiple"
      onBlur={handleOnBlur}
      loading={loading}
      value={formattedValue}
      open={isDropdownOpen}
      onChange={handleOnChange}
      onSearch={handleSearch}
      placeholder="Devices"
      filterOption={(inputValue, option) => {
        return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
      }}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {sortedDevices?.map((option: Device) => {
        return (
          <Select.Option title={`${option.rds} (${option.description})`} key={option.id} value={option.id}>
            {`${option.rds} (${option.description})`}
          </Select.Option>
        );
      })}
    </StyledSelect>
  );
};

export default DeploymentDevicesSelect;
