import React from 'react';
import ProjectContentWrapper from '../../projects/components/ProjectContentWrapper';
import { usePermissions } from '../../session/hooks/usePermissions';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { PacTSPermissions } from '@pacts/permissions-lib';
import ConfigurationCard from './components/ConfigurationCard/ConfigurationCard';
import useConfigurations from './hooks/useConfigurations';
import { ConfigurationsParams } from './types';
import { AddEditConfiguration } from './components/AddEditConfiguration/AddEditConfiguration';
import { useSearchParameter } from '../../navigation/hooks';
import useBundles from '../../bundles/hooks/useBundles';
import { isEmpty } from 'lodash';
import { Bundle } from '../../../api';
import { EmptyIcon } from '../../shared/components/EmptyIcon';
import { useProject } from '../../../contexts/projects/hooks/useProject';

const Configurations = () => {
  const { projectId = '' } = useParams<ConfigurationsParams>();
  const [projectType] = useSearchParameter('type');
  const project = useProject(projectId.toString());
  const permissions: PacTSPermissions = usePermissions({ projectId: projectId });
  const bundles = useBundles(projectId);
  const usedBundle = bundles.data?.find((bundle: Bundle) => bundle.name.includes('Project Software'));
  const bundleId = usedBundle?.idBundle.toString() || '';
  const configurations = useConfigurations(projectId, bundleId);

  const breadcrumbItems = [{ title: project.data?.name, url: `/projects?active=${projectId}&type=${projectType}` }, { title: 'Project software' }];

  return (
    <ProjectContentWrapper
      title="Project software configurations"
      breadcrumbItems={breadcrumbItems}
      extra={
        permissions.engineeringSvc$addProjectBundleConfiguration && !isEmpty(configurations.data) ? (
          <AddEditConfiguration bundleId={bundleId} projectId={projectId} />
        ) : null
      }
      isLoading={configurations.isLoading}
    >
      <Row gutter={[16, 16]}>
        {configurations.data
          ?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          .map((config) => (
            <Col key={config.idBundleConfiguration}>
              <ConfigurationCard
                projectId={projectId}
                bundleId={bundleId}
                id={config.idBundleConfiguration}
                name={config.name}
                description={config.description}
                lastVersionId={config.latestBundleReleaseId}
                isLoading={false}
              />
            </Col>
          ))}
      </Row>
      {isEmpty(configurations.data) && (
        <>
          <EmptyIcon description="Add a new configuration first to start creating releases." />
          <Row justify="center">
            <AddEditConfiguration bundleId={bundleId} projectId={projectId} />
          </Row>
        </>
      )}
    </ProjectContentWrapper>
  );
};

export default Configurations;
