import { Badge, Col, Form, FormInstance, Row, Typography } from 'antd';
import { ShiftedDrawer } from '../../../../shared/components/ShiftedDrawer';
import { useEffect, useState, Fragment } from 'react';
import { ReleaseNotesValidator } from '../../../../../domain';
import { MarkdownFormItem } from '../../../../shared/components/MarkdownFormItem';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../../../constants/texts';
import useEnhanceSoftwareItemVersionReleaseNotes from './hooks/useEnhanceSoftwareItemVersionReleaseNotes';
import useEnhanceConfigurationVersionReleaseNotes from './hooks/useEnhanceConfigurationVersionReleaseNotes';
import EnhanceReleaseNotesButton from './EnhanceReleaseNotesButton';
import styled from 'styled-components';
import { useStyles } from '@pacts-plugin-api';

interface EnhanceReleaseNotesProps {
  originalReleaseNotes: string;
  onOk: (enhancedReleaseNotes: string) => void;
  bundleId?: string;
  projectId?: string;
  configId?: string;
  commonSoftwareAppVersionIds?: number[];
  projectSoftwareAppVersionIds?: number[];
  toolVersionIds?: number[];
  originalForm?: FormInstance;
}

interface LabelProps {
  padding: number;
}

const StyledEnhancedReleaseNotesLabel = styled(Typography.Text)<LabelProps>`
  padding-left: ${(props) => `${props.padding}px`};
`;

const StyledMarkdownFormItem = styled(MarkdownFormItem)`
  margin-top: 0;
`;

export const EnhanceReleaseNotes = ({
  originalReleaseNotes,
  onOk,
  bundleId,
  projectId,
  configId,
  commonSoftwareAppVersionIds,
  projectSoftwareAppVersionIds,
  toolVersionIds,
  originalForm
}: EnhanceReleaseNotesProps) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const enhanceSoftwareItemVersionReleaseNotes = useEnhanceSoftwareItemVersionReleaseNotes();
  const enhanceConfigurationVersionReleaseNotes = useEnhanceConfigurationVersionReleaseNotes();
  const enhanceReleaseNotes = projectId ? enhanceConfigurationVersionReleaseNotes : enhanceSoftwareItemVersionReleaseNotes;
  const [form] = Form.useForm();
  const isEnhancingConfigurationVersionReleaseNotes =
    projectId !== undefined &&
    bundleId !== undefined &&
    configId !== undefined &&
    commonSoftwareAppVersionIds !== undefined &&
    projectSoftwareAppVersionIds !== undefined &&
    toolVersionIds !== undefined;
  const token = useStyles();
  const [wasGenerated, setWasGenerated] = useState(false);

  /**
   * API call for enhancing config/software item version release notes
   */
  const handleEnhanceReleaseNotes = () => {
    // Enhance config version release notes
    if (isEnhancingConfigurationVersionReleaseNotes) {
      enhanceConfigurationVersionReleaseNotes.mutate([
        +bundleId,
        +projectId,
        +configId,
        form.getFieldValue('releaseNotes') || null,
        commonSoftwareAppVersionIds,
        projectSoftwareAppVersionIds,
        toolVersionIds
      ]);
      setWasGenerated(true);
    } else {
      // Enhance software item version release notes
      form
        .validateFields()
        .then(() => {
          enhanceSoftwareItemVersionReleaseNotes.mutate(form.getFieldValue('releaseNotes'));
          setWasGenerated(true);
        })
        .catch(() => {});
    }
  };

  /**
   * Set original release notes fields from props and call enhance API on load
   */
  useEffect(() => {
    if (drawerVisible) {
      form.setFieldsValue({ releaseNotes: originalReleaseNotes });
      handleEnhanceReleaseNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalReleaseNotes, drawerVisible]);

  /**
   * Set enhanced release notes field once API call is done
   */
  useEffect(() => {
    if (drawerVisible) {
      const { releaseNotes } = enhanceReleaseNotes.data || {};

      if (releaseNotes) {
        form.setFieldValue('enhancedReleaseNotes', releaseNotes);
      }
    }
  }, [enhanceReleaseNotes.data, form, drawerVisible]);

  const handleReplaceReleaseNotes = () => {
    onOk(enhanceReleaseNotes.data?.releaseNotes || '');

    form.resetFields(['enhancedReleaseNotes']);
    enhanceReleaseNotes.reset();

    setDrawerVisible(false);
  };

  const handleCloseDrawer = () => {
    form.resetFields(['enhancedReleaseNotes']);
    enhanceReleaseNotes.reset();

    setDrawerVisible(false);
  };

  const openEnhanceDrawer = () => {
    if (!isEnhancingConfigurationVersionReleaseNotes) {
      originalForm
        ?.validateFields(['releaseNotes'])
        .then(() => {
          setDrawerVisible(true);
        })
        .catch(() => null);
    } else {
      setDrawerVisible(true);
    }
  };

  // if the original form prop is given, we assume the button is inside a form element
  // hence wrap it in a Form.Item to preserve consistent spacing within the form
  const ButtonWrapper = !!originalForm ? Form.Item : Fragment;

  return (
    <>
      <ButtonWrapper wrapperCol={{ span: 24 }}>
        <EnhanceReleaseNotesButton
          text={!isEnhancingConfigurationVersionReleaseNotes ? 'Enhance release notes' : 'Generate release notes'}
          isEnhanceConfigurationVersion={isEnhancingConfigurationVersionReleaseNotes}
          onClick={openEnhanceDrawer}
          showBeta
        />
      </ButtonWrapper>
      <ShiftedDrawer
        title={
          <Badge count="Beta" color={token.orange6} offset={[30, 8]}>
            Release notes: AI enhancement
          </Badge>
        }
        destroyOnClose
        loading={false}
        okText="Replace original text"
        open={drawerVisible}
        isFormDrawer
        onOk={handleReplaceReleaseNotes}
        onCancel={handleCloseDrawer}
        isOkDisabled={enhanceReleaseNotes.status !== 'success'}
      >
        <Form form={form}>
          <StyledMarkdownFormItem
            label="Original release notes"
            field={['releaseNotes']}
            optional={isEnhancingConfigurationVersionReleaseNotes}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            rules={isEnhancingConfigurationVersionReleaseNotes ? [] : [ReleaseNotesValidator.rule()]}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
            readonly={enhanceReleaseNotes.isLoading}
          />
          <MarkdownFormItem
            label={
              <StyledEnhancedReleaseNotesLabel padding={isEnhancingConfigurationVersionReleaseNotes ? 6 : 16}>
                Enhanced release notes
              </StyledEnhancedReleaseNotesLabel>
            }
            field={['enhancedReleaseNotes']}
            placeholder="Click 'Generate release notes' button to enhance the release notes."
            isLoading={enhanceReleaseNotes.isLoading}
            isLoadingText="Release notes enhancement loading.."
            readonly
            noInfo
            descriptionMinRows={10}
            descriptionMaxRows={10}
            optional
            shouldUpdate={() => false}
          />
          <EnhanceReleaseNotesButton
            text={`${!isEnhancingConfigurationVersionReleaseNotes ? 'Enhance' : wasGenerated ? 'Regenerate' : 'Generate'} release notes`}
            isEnhanceConfigurationVersion={isEnhancingConfigurationVersionReleaseNotes}
            onClick={handleEnhanceReleaseNotes}
            disabled={enhanceReleaseNotes.isLoading}
          />
          <Row>
            <Col>
              <Typography.Text type="danger">* Replacing the original text cannot be undone.</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Text type="danger">** AI-generated content might contain inaccuracies.</Typography.Text>
            </Col>
          </Row>
        </Form>
      </ShiftedDrawer>
    </>
  );
};

export default EnhanceReleaseNotes;
